import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, FormText, Input, Label } from 'reactstrap';
import { updatePerson } from '../../../../redux/actions/chat';

const personaInit = {
    values: {
        name: '',
        email: '',
        phone: '',
        color: '',
    },
    errors: {
        name: false,
        email: false,
        phone: false,
        color: false,
    },
    ready: false,
};

const PersonaEdit = ({ editable = true }) => {
    const { t } = useTranslation('chat');

    /** Redux */
    const person = useSelector((state) => get(state, 'room.room.person'));

    const { roomId } = useSelector((state) => {
        return {
            _id: get(state, 'room.room._id'),
            roomId: get(state, 'room.room.roomId'),
        };
    });
    const reduxDispatch = useDispatch();

    /** State */
    const personaReducer = (state, action) => {
        switch (action.type) {
            case 'READY':
                return { ...state, ready: true };
            case 'UPDATE_FIELD':
                return { ...state, values: { ...state.values, ...action.payload } };
            case 'SET_ERRORS':
                return { ...state, errors: { ...state.errors, ...action.payload } };
            case 'CLEAN_FORM':
                return personaInit;
            default:
                return state;
        }
    };

    const [formState, dispatch] = useReducer(personaReducer, personaInit);
    const {
        values: { name, email, phone },
        errors,
    } = formState;

    const [inEdit, setInEdit] = useState(false);

    /** Fn */

    const handleFieldChage = (e) => {
        const { name, value } = e.target;
        dispatch({
            type: 'UPDATE_FIELD',
            payload: { [name]: value },
        });
        dispatch({
            type: 'SET_ERRORS',
            payload: { [name]: validate(name, value) },
        });
    };

    const validate = (field, value) => {
        switch (field) {
            case 'name':
                if (!value || value.length < 0) {
                    return true;
                }
                break;
            default:
                break;
        }
        return false;
    };

    const saveInfo = useCallback(async () => {
        try {
            const { values } = formState;
            if (values['name'] === '') values['name'] = undefined;
            reduxDispatch(
                updatePerson({
                    roomId,
                    person: values,
                })
            );
            setInEdit(false);
        } catch (error) {
            Sentry.captureException(error);
        }
    }, [formState, roomId, reduxDispatch]);

    /** Effects */

    useEffect(() => {
        if (person) {
            dispatch({
                type: 'UPDATE_FIELD',
                payload: Object.keys(personaInit.values).reduce(
                    (payload, field) => ({ ...payload, [field]: person[field] }),
                    {}
                ),
            });
        }
    }, [person]);

    /** Renders */

    return (
        <div>
            <FormGroup>
                <Label>{t("Ім'я")}:</Label>
                {inEdit ? (
                    <Input value={name} invalid={errors['name']} name="name" onChange={handleFieldChage} />
                ) : (
                    <span className="ml-2 fw-semi-bold">{name}</span>
                )}
            </FormGroup>
            <FormGroup>
                <Label>{t('Телефон')}:</Label>
                {inEdit ? (
                    <Input value={phone} invalid={errors['phone']} name="phone" onChange={handleFieldChage} />
                ) : (
                    <span className="ml-2 fw-semi-bold">{phone}</span>
                )}
            </FormGroup>
            <FormGroup>
                <Label>{t('Email')}:</Label>
                {inEdit ? (
                    <>
                        <Input
                            value={email}
                            invalid={errors['email']}
                            name="email"
                            onChange={handleFieldChage}
                        />
                        <FormText>{t('Необхідно для створення тікету')}</FormText>
                    </>
                ) : (
                    <>
                        <span className="ml-2 fw-semi-bold">{email}</span>
                        <FormText>{t('Email необхідно для створення тікету')}</FormText>
                    </>
                )}
            </FormGroup>
            {inEdit ? (
                <div className="w-100 text-center mt-2">
                    <Button
                        size="sm"
                        color="danger"
                        className="mr-1 editBtn"
                        onClick={() => setInEdit(false)}
                    >
                        {t('Відміна')}
                    </Button>
                    <Button size="sm" color="success" className="ml-1 editBtn" onClick={saveInfo}>
                        {t('Зберегти')}
                    </Button>
                </div>
            ) : !editable ? null : (
                <div className="w-100 text-right mb-2">
                    <Button size="sm" color="info" onClick={() => setInEdit(true)} className="editBtn">
                        {t('Редагувати')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default PersonaEdit;
