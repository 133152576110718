import axios from 'axios';
import { get } from 'lodash';

export const SHOW_SEND_SMS_DIALOG = 'SHOW_SEND_SMS_DIALOG';
export const HIDE_SEND_SMS_DIALOG = 'HIDE_SEND_SMS_DIALOG';
export const SET_SMS_SERVICES = 'SET_SMS_SERVICES';

export function showSendSmsDialog() {
    return {
        type: SHOW_SEND_SMS_DIALOG
    };
}

export function hideSendSmsDialog() {
    return {
        type: HIDE_SEND_SMS_DIALOG
    };
}

export function setSmsServices(payload) {
    return {
        type: SET_SMS_SERVICES,
        payload
    };
}

export function sendSms({phone, message}) {
    return (dispatch, getState) => {
        const accountId = get(getState(), 'chat.user.accountId', null);
        return axios.patch(`/entity/ClientAccount/${accountId}/sendSms`,
            { to: phone, message }
        );
    };
}

export function fetchSmsServices() {
    return (dispatch) => {
        axios
            .get('/entity/SmsService', {
                params: {
                    where: { disabled: { $or: [false, null] } }
                }
            })
            .then((res) => {
                dispatch(setSmsServices(res.data.rows));
            })
            .catch((err) => {
                dispatch(setSmsServices([]));
            });
    };
}