import Axios from 'axios';
import React, { Fragment, useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Container } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import {
    finalizeRegister,
    receiveRegister,
    setInProgress,
    setShowInitModal,
} from '../../redux/actions/register';
import { receiveToken } from '../../redux/actions/user';
import { generatePassword } from '../../utils/auth';
import AuthView from './AuthView';
import CardView from './CardView';
import ProfileView from './ProfileView';
import s from './RegistrationWizard.module.scss';

const initialState = {
    values: {
        email: '',
        password: '',
        confirmPassword: '',
        promocode: '',
        name: '',
        alias: '',
        domain: '',
        widgetColor: '#51718d',
        country: 'UA',
    },
    errors: {
        email: false,
        password: false,
        confirmPassword: false,
        promocode: false,
        name: false,
        alias: false,
        domain: false,
        widgetColor: false,
        country: false,
    },
    ready: true,
    step: 1,
};

const regReducer = (state, action) => {
    switch (action.type) {
        case 'READY':
            return { ...state, ready: true };
        case 'UPDATE_FIELD':
            return { ...state, values: { ...state.values, ...action.payload } };
        case 'SET_ERRORS':
            return { ...state, errors: { ...state.errors, ...action.payload } };
        case 'CLEAN_ERRORS':
            return { ...state, errors: { ...initialState.errors } };
        case 'CLEAN_FORM':
            return initialState;
        case 'SET_STEP':
            return { ...state, step: action.payload };
        default:
            return state;
    }
};

const RegistrationWizard = () => {
    const { t } = useTranslation('registration');
    const { state: locationState } = useLocation();

    /** Redux */

    const dispatch = useDispatch();
    const { onboardingInProgress, isAuthenticated } = useSelector((state) => {
        return {
            onboardingInProgress: state.register.onboardingInProgress,
            isAuthenticated: state.auth.isAuthenticated,
        };
    });

    /** States */
    const [steps, setSteps] = useState(() => {
        const tier = new URLSearchParams(window.location.search).get('tier');

        if (tier > 1) {
            return [1, 2, 3];
        }

        return [1, 2];
    });

    const [tierId, setTierId] = useState(new URLSearchParams(window.location.search).get('tier') ?? 1);
    const [refererCode] = useState(new URLSearchParams(window.location.search).get('referer'));
    const [referalLink] = useState(new URLSearchParams(window.location.search).get('link'));

    const [state, formDispatch] = useReducer(regReducer, initialState, () => {
        if (locationState) {
            const { email } = locationState;
            return {
                ...initialState,
                values: { ...initialState.values, email, password: generatePassword() },
                step: 2,
            };
        }

        return initialState;
    });
    const { values, errors, step } = state;

    /** Fn */

    const setStep = (step) => {
        formDispatch({
            type: 'SET_STEP',
            payload: step,
        });
    };

    const validate = (field, value, form) => {
        switch (field) {
            case 'email':
                if (!value || value.length === 0) {
                    return t('Вкажіть email адресу');
                }
                if (
                    !String(value)
                        .toLowerCase()
                        .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )
                ) {
                    return t('Введіть корректну email адресу');
                }
                break;
            case 'confirmPassword':
                if (form.password !== value) {
                    return t('Паролі не співпадають');
                }
                break;
            case 'password':
                if (form.confirmPassword !== '' && form.confirmPassword !== value) {
                    return t('Паролі не співпадають');
                }
                break;
            default:
                break;
        }
        return false;
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        formDispatch({
            type: 'CLEAN_ERRORS',
        });
        formDispatch({
            type: 'UPDATE_FIELD',
            payload: { [name]: value },
        });
        formDispatch({
            type: 'SET_ERRORS',
            payload: { [name]: validate(name, value, values) },
        });
    };

    const prev = () => setStep(step - 1);

    const next = (data) => {
        if (data) {
            formDispatch({
                type: 'UPDATE_FIELD',
                payload: data,
            });
        }
        dispatch(setInProgress());
        setStep(step + 1);
    };

    const finalize = useCallback(() => {
        if (isAuthenticated) {
            dispatch(finalizeRegister());
            dispatch(setShowInitModal(true));
        }
    }, [isAuthenticated, dispatch]);

    const register = (data) => {
        const { ops, payPeriod } = data;
        const finalData = { ...values, ops, payPeriod, tierId, trial: !tierId };
        if (finalData.promocode === '') {
            delete finalData.promocode;
        }
        if (refererCode && refererCode.length > 0) {
            finalData['referer'] = refererCode;
            finalData['referalLink'] = referalLink;
        }
        regClient(finalData);
    };

    const cardAdded = (data) => {
        dispatch(finalizeRegister());
        dispatch(setShowInitModal(true));
    };

    const regClient = useCallback(
        async (payload) => {
            try {
                const {
                    data: { token },
                } = await Axios.post('/auth/register', payload);
                dispatch(receiveRegister());
                dispatch(receiveToken(token));
                toast.success(t('Реєстрація пройшла успішно!'));
                window.dataLayer.push({ event: 'registr_ok' });
            } catch (error) {
                if (error.response) {
                    if (error.response.data.msg === 'Validation error') {
                        formDispatch({
                            type: 'SET_ERRORS',
                            payload: error.response.data.fields.reduce(
                                (payload, value) => ({ ...payload, [value]: true }),
                                {}
                            ),
                        });
                    }
                    if (error.response.data.msg === 'Validation error: Validation isEmail on email failed') {
                        formDispatch({
                            type: 'SET_ERRORS',
                            payload: { email: t('Введіть корректну адресу пошти') },
                        });
                    }
                    if (error.response.data.msg === 'Alias exist') {
                        formDispatch({
                            type: 'SET_ERRORS',
                            payload: { alias: t('Псевдонім зайнято') },
                        });
                    }
                    if (error.response.data.msg === 'User exist') {
                        formDispatch({
                            type: 'SET_ERRORS',
                            payload: { global: t('Користувач з такою email адресою вже зареестрований') },
                        });
                    }
                }
                toast.error(t('Помилка при створені аккаунта'));
            }
        },
        [dispatch, t]
    );

    /** Side effects */

    useEffect(() => {
        if (isAuthenticated && tierId > 1) {
            setStep(3);
        }

        if (isAuthenticated && (!tierId || tierId === 1)) {
            dispatch(finalizeRegister());
            dispatch(setShowInitModal(true));
        }
    }, [isAuthenticated, tierId, dispatch]);

    useEffect(() => {
        if (tierId) {
            setSteps(() => {
                if (tierId > 1) {
                    return [1, 2, 3];
                }

                return [1, 2];
            });
        }
    }, [tierId]);

    /** Renders */
    const _renderTitle = () => {
        switch (step) {
            case 1:
                return <h3 className="mt-0 mb-4">{t('Створити акаунт')}</h3>;
            case 2:
                return <h3 className="mt-0 mb-4">{t('Дані компанії та тариф')}</h3>;
            case 3:
                return <h3 className="mt-0 mb-4">{t('Оплата підписки')}</h3>;
            default:
                return <h3 className="mt-0 mb-4">{t('Створити акаунт')}</h3>;
        }
    };

    return !onboardingInProgress && isAuthenticated ? (
        <Redirect to={{ from: { pathname: '/app' } }} />
    ) : (
        <div className={s.regPage}>
            <Container>
                <Widget className={'widget-auth mx-auto ' + s.regWidget} title={_renderTitle()}>
                    {errors.global && (
                        <Alert className="alert-sm" color="danger">
                            {errors.global}
                        </Alert>
                    )}
                    {step === 1 && (
                        <AuthView
                            prev={prev}
                            next={next}
                            form={values}
                            errors={errors}
                            onFieldChange={handleFieldChange}
                            dispatch={dispatch}
                        />
                    )}
                    {step === 2 && (
                        <ProfileView
                            prev={prev}
                            next={register}
                            tier={tierId}
                            form={values}
                            dispatch={formDispatch}
                            onFieldChange={handleFieldChange}
                            errors={errors}
                            setTierId={setTierId}
                        />
                    )}
                    {step === 3 && (
                        <CardView prev={finalize} next={cardAdded} form={values} errors={errors} />
                    )}

                    {step === 1 && (
                        <>
                            <p className="widget-auth-info">{t('Вже маєте акаунт? Увійдіть')}</p>
                            <Link className="d-block text-center" to="login">
                                {t('Увійти до акаунту')}
                            </Link>
                        </>
                    )}

                    <div className={s.wizardControlsContainer}>
                        {steps.map((stepNumber, index) => {
                            return (
                                <Fragment key={`step_${index}`}>
                                    <div
                                        className={
                                            s.wizardControl + (step === stepNumber ? ' ' + s.active : '')
                                        }
                                    >
                                        {stepNumber}
                                    </div>
                                    {steps.length !== index + 1 && <div className={s.wizardDivider}></div>}
                                </Fragment>
                            );
                        })}
                    </div>
                </Widget>
            </Container>
        </div>
    );
};

export default RegistrationWizard;
