import * as Sentry from "@sentry/react";
import axios from 'axios';
import { helpTexts } from '../../data/helpTexts';
import { fetchSmsServices } from "../sms/actions";

export const SET_DOMAINS = 'SET_DOMAINS';
export const SET_LOADED = 'SET_LOADED';
export const SET_HELP = 'SET_HELP';
export const SET_FOCUS = 'SET_FOCUS';
export const SET_SMS_PROVIDERS = 'SET_SMS_PROVIDERS';
export const SET_LANGUAGES = 'SET_LANGUAGES';

export const SET_TIER = 'set_tier';
export const RESET_SETTINGS = 'reset_settings';

function setDomains(payload) {
    return {
        type: SET_DOMAINS,
        payload,
    };
}

function setLoaded(payload) {
    return {
        type: SET_LOADED,
        payload,
    };
}

function setHelp(payload) {
    return {
        type: SET_HELP,
        payload,
    };
}

function setLanguages(payload) {
    return {
      type: SET_LANGUAGES,
      payload
    };
  }
  
export function setTier(payload) {
    return {
        type: SET_TIER,
        payload,
    };
}

export function setSmsProviders(payload) {
    return {
        type: SET_SMS_PROVIDERS,
        payload,
    };
}

export function resetSettings(payload) {
    return {
        type: RESET_SETTINGS
    };
}

/** Thunks */

export function setupSettings() {
    return (dispatch, getState) => {
        if (!getState().settings.loaded) {
            dispatch(setLoaded(true));
            dispatch(fetchDomains());
            dispatch(fetchHelp());
            dispatch(fetchSubscribtion());
            dispatch(fetchSmsProviders());
            dispatch(fetchSmsServices());
            dispatch(fetchLanguages());
            // other settings setup
        }
    };
}

export function fetchDomains() {
    return (dispatch) => {
        axios
            .get('/entity/Domain', { params: { 
                where: {disabled: {$or: [false, null]}}
            } })
            .then((res) => {
                dispatch(setDomains(res.data.rows));
            })
            .catch((err) => {
                dispatch(setDomains([]));
            });
    };
}

export function fetchSmsProviders() {
    return async (dispatch) => {
        try {
            const {data} = await axios.get('/entity/ClientAccount/one');
            const providers = await axios.patch(`/entity/ClientAccount/${data.id}/getSmsProviders`);
            dispatch(setSmsProviders({smsProviders: providers.data}));
        } catch (error) {
            Sentry.captureException(error);
        }
    };
}

export function fetchHelp() {
    return (dispatch) => {
        Promise.resolve(helpTexts)
            .then((res) => {
                dispatch(
                    setHelp(
                        helpTexts
                            ? helpTexts.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.text }), {})
                            : {}
                    )
                );
            })
            .catch((err) => {
                dispatch(setHelp({}));
            });
        // uncomment to fetch data from backend
        // axios.get("/entity/Help", {params: {where: '{}'}}).then(res => {
        //   dispatch(setHelp(res.data && res.data.rows ? _.keyBy(res.data.rows, (item) => item.key) : {}));
        // }).catch(err => {
        //   dispatch(setHelp({}));
        // })
    };
}

export function fetchSubscribtion() {
    return async (dispatch) => {
        try {
            const {
                data: { currentMaxOps, tierId },
            } = await axios.get('/entity/ClientAccount/one');
            dispatch(setTier({ currentMaxOps, tierId }));
        } catch (error) {
            Sentry.captureException(error);
        }
    };
}

export function fetchLanguages() {
    return (dispatch) => {
      axios.get(process.env.REACT_APP_BACKEND + "/translations/languages").then(res => {
        dispatch(setLanguages(res.data));
      }).catch(err => {
        dispatch(setLanguages([]));
      })
    };
  }
  
export function setFocus(payload) {
    return {
        type: SET_FOCUS,
        payload,
    };
}
