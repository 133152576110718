import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const OnlineStatus = (props) => {
  const { t } = useTranslation('chat');

  const wasOnline = () => {
    // let calendarDate = moment(this.props.user.lastSeen).calendar();
    // let firstLetter = calendarDate[0].toLowerCase();
    // let substring = calendarDate.substr(1);

    // return firstLetter + substring;
    return moment(props.user.lastSeen).format('HH:mm DD.MM.YYYY');
  }

  const { user } = props;

  return (
    <p className="text-muted mb-0">
      {user.isOnline ? 
        <span className="text-info">{t('Онлайн')}</span>
      : <span>{`${t('Остання активність')} ${wasOnline()}`}</span>}
    </p>
  )
}

export default OnlineStatus;