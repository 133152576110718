import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';


import MainChart from './charts/MainChart';

const StatsChart = ({isReceiving, mainChart, dispatch}) => {

  return (
    <div>
      {/* <h1 className="page-title">Analytics</h1> */}
          <Row>
            <Col xs={12}>
                {mainChart && <MainChart />}
            </Col>
          </Row>
    </div>
  );
}

function mapStateToProps(state) {
    return {
        isReceiving: state.statistics.isReceiving,
        mainChart: state.statistics.mainChart
    }
}

export default connect(mapStateToProps)(StatsChart);
