import Axios from "axios";
import { APPEND_TICKET_MESSAGE, INCREMENT_TICKET_COUNTER, INVITE_TICKET_OPERATOR, MARK_MESSAGE_READED, NEW_TICKET, RESET_TICKET_COUNTER, SET_TICKETS_PAGE, SET_TICKETS_SIZE, SET_TICKETS_TOTAL_PAGES, SET_TICKET_LIST, TICKET_UPDATE } from "./types";

/**
 * Action creators
 */

export function setTicketList(payload) {
    return {
        type: SET_TICKET_LIST,
        payload
    }
}

export function setTicketSize(payload) {
    return {
        type: SET_TICKETS_SIZE,
        payload
    }
}

export function setTicketPages(payload) {
    return {
        type: SET_TICKETS_TOTAL_PAGES,
        payload
    }
}

export function setTicketPage(payload) {
    return {
        type: SET_TICKETS_PAGE,
        payload
    }
}

export function addNewTicket(payload) {
    return {
        type: NEW_TICKET,
        payload
    }
}

export function incrementCounter() {
    return {
        type: INCREMENT_TICKET_COUNTER
    }
}

export function resetTicketCounter() {
    return {
        type: RESET_TICKET_COUNTER
    }
}

export function appendMessage(message) {
    return {
        type: APPEND_TICKET_MESSAGE,
        payload: message
    }
}

export function readMessage(message) {
    return {
        type: MARK_MESSAGE_READED,
        payload: message
    }
}

export function updateTicket(ticket) {
    return {
        type: TICKET_UPDATE,
        payload: ticket
    }
}

export function sendTicketInvitiation(user) {
    return {
        type: INVITE_TICKET_OPERATOR,
        payload: user
    }
}

/**
 * Thunk actions
 */

export function fetchTickets(params) {
    return async (dispatch) => {
        const { data } = await Axios.get(`/entity/Ticket`, {
            params
        });
        dispatch(setTicketList(data.rows));
        dispatch(setTicketSize(data.count));
        dispatch(setTicketPages(Math.ceil(data.count / params.per_page)));
    }
}