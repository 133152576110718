import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, FormGroup, Input } from 'reactstrap';

const TicketForwarder = ({ onSave }) => {
    const { t } = useTranslation('tickets');
    const [email, setEmail] = useState('');
    const [inEdit, setinEdit] = useState(false);

    const save = () => {
        if (typeof onSave === 'function') onSave(email);
        setinEdit(false);
        setEmail('');
    }
    return <div className="mt-2">
        {inEdit && (<FormGroup style={{ width: 250 }} className='p-0 m-0'><Input
            value={email}
            placeholder="Email адрес отримувача"
            onChange={(e) => {
                setEmail(e.target.value);
            }}
        /></FormGroup>)}
        <div>
            {inEdit && (<ButtonGroup className="mt-2" style={{width: '100%'}}>
                <Button color="info" onClick={() => setinEdit(false)}>
                    {t('Відмінти')}
                </Button>
                <Button color="success" onClick={save}>
                    {t('Відправити')}
                </Button>
            </ButtonGroup>)}
            {!inEdit && (<Button color="info" block onClick={() => setinEdit(true)}>
                {t('Переслати лист')}
            </Button>)}
        </div>
    </div>;
};

export default TicketForwarder;
