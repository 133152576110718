import { get } from 'lodash';
import { useSelector } from 'react-redux';

export const Roles = {
    admin: 1,
    manager: 2,
    root: 3
}

export const Tiers = {
    free: 1,
    tiger: 2,
    swarm: 3
}

const TierGuard = ({ allowedTiers = [], allowedRoles = [], children }) => {
    const tier = useSelector((state) => get(state, 'settings.tierId', Tiers.free));
    const role = useSelector((state) => get(state, 'chat.user.roleId', Roles.manager));

    if (allowedTiers.length > 0 && !allowedTiers.includes(tier)) return null;
    if (allowedRoles.length > 0 && !allowedRoles.includes(role)) return null;

    return children;
}

export default TierGuard