import React from 'react';

const MessageCircle = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.83352 9.16605C5.83352 8.70605 6.20685 8.33272 6.66685 8.33272C7.12685 8.33272 7.50019 8.70605 7.50019 9.16605C7.50019 9.62605 7.12685 9.99939 6.66685 9.99939C6.20685 9.99939 5.83352 9.62605 5.83352 9.16605ZM10.0002 8.33272C9.54019 8.33272 9.16685 8.70605 9.16685 9.16605C9.16685 9.62605 9.54019 9.99939 10.0002 9.99939C10.4602 9.99939 10.8335 9.62605 10.8335 9.16605C10.8335 8.70605 10.4602 8.33272 10.0002 8.33272ZM13.3335 8.33272C12.8735 8.33272 12.5002 8.70605 12.5002 9.16605C12.5002 9.62605 12.8735 9.99939 13.3335 9.99939C13.7935 9.99939 14.1669 9.62605 14.1669 9.16605C14.1669 8.70605 13.7935 8.33272 13.3335 8.33272ZM16.5822 10.2451C16.1597 12.9568 13.9739 15.206 11.2664 15.7135C9.95885 15.9601 8.62719 15.8193 7.41885 15.3076C7.07635 15.1626 6.72219 15.0893 6.37469 15.0893C6.21635 15.0893 6.05969 15.1043 5.90552 15.1351L3.56219 15.6035L4.03135 13.256C4.12969 12.7685 4.06969 12.2468 3.85885 11.7476C3.34719 10.5393 3.20719 9.20847 3.45302 7.90014C3.96052 5.19264 6.20885 3.0068 8.92135 2.5843C11.0797 2.24847 13.1905 2.92847 14.7139 4.4518C16.238 5.97597 16.9189 8.08764 16.5822 10.2451ZM15.893 3.27347C13.9889 1.37014 11.3555 0.520971 8.66469 0.936804C5.26719 1.4668 2.45052 4.20347 1.81469 7.59264C1.50802 9.2243 1.68469 10.886 2.32385 12.3968C2.40469 12.5893 2.42969 12.7685 2.39802 12.9293L1.68302 16.5026C1.62802 16.776 1.71385 17.0585 1.91135 17.2551C2.06885 17.4135 2.28135 17.4993 2.50052 17.4993C2.55469 17.4993 2.60885 17.4943 2.66385 17.4835L6.23302 16.7693C6.43802 16.7301 6.63635 16.7876 6.76885 16.8426C8.28135 17.4818 9.94302 17.6576 11.573 17.3518C14.963 16.716 17.6997 13.8993 18.2297 10.5018C18.648 7.81264 17.7972 5.17764 15.893 3.27347Z" fill="currentColor"/>
        <mask id="mask112" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="18" height="18">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.83352 9.16605C5.83352 8.70605 6.20685 8.33272 6.66685 8.33272C7.12685 8.33272 7.50019 8.70605 7.50019 9.16605C7.50019 9.62605 7.12685 9.99939 6.66685 9.99939C6.20685 9.99939 5.83352 9.62605 5.83352 9.16605ZM10.0002 8.33272C9.54019 8.33272 9.16685 8.70605 9.16685 9.16605C9.16685 9.62605 9.54019 9.99939 10.0002 9.99939C10.4602 9.99939 10.8335 9.62605 10.8335 9.16605C10.8335 8.70605 10.4602 8.33272 10.0002 8.33272ZM13.3335 8.33272C12.8735 8.33272 12.5002 8.70605 12.5002 9.16605C12.5002 9.62605 12.8735 9.99939 13.3335 9.99939C13.7935 9.99939 14.1669 9.62605 14.1669 9.16605C14.1669 8.70605 13.7935 8.33272 13.3335 8.33272ZM16.5822 10.2451C16.1597 12.9568 13.9739 15.206 11.2664 15.7135C9.95885 15.9601 8.62719 15.8193 7.41885 15.3076C7.07635 15.1626 6.72219 15.0893 6.37469 15.0893C6.21635 15.0893 6.05969 15.1043 5.90552 15.1351L3.56219 15.6035L4.03135 13.256C4.12969 12.7685 4.06969 12.2468 3.85885 11.7476C3.34719 10.5393 3.20719 9.20847 3.45302 7.90014C3.96052 5.19264 6.20885 3.0068 8.92135 2.5843C11.0797 2.24847 13.1905 2.92847 14.7139 4.4518C16.238 5.97597 16.9189 8.08764 16.5822 10.2451ZM15.893 3.27347C13.9889 1.37014 11.3555 0.520971 8.66469 0.936804C5.26719 1.4668 2.45052 4.20347 1.81469 7.59264C1.50802 9.2243 1.68469 10.886 2.32385 12.3968C2.40469 12.5893 2.42969 12.7685 2.39802 12.9293L1.68302 16.5026C1.62802 16.776 1.71385 17.0585 1.91135 17.2551C2.06885 17.4135 2.28135 17.4993 2.50052 17.4993C2.55469 17.4993 2.60885 17.4943 2.66385 17.4835L6.23302 16.7693C6.43802 16.7301 6.63635 16.7876 6.76885 16.8426C8.28135 17.4818 9.94302 17.6576 11.573 17.3518C14.963 16.716 17.6997 13.8993 18.2297 10.5018C18.648 7.81264 17.7972 5.17764 15.893 3.27347Z" fill="white"/>
        </mask>
        <g mask="url(#mask112)">
        <rect width="20" height="20" fill="currentColor"/>
        </g>
    </svg>
)

export default MessageCircle