import React, { useEffect, useState } from 'react';
import {
  Button,
  ListGroup,
  ListGroupItem, Popover,
  PopoverBody,
  PopoverHeader
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { statusColor, statusColorCode, statusName, ticketStatusOptions } from '../data/TicketStatuses';

const TicketStatus = ({ status, ticketId, onStatusChange }) => {
    const { t } = useTranslation('tickets');
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [timer, setTimer] = useState();

    const toggle = () => setPopoverOpen(!popoverOpen);
    const getStatusColor = () => {
        return 'fw-semi-bold text-' + statusColor(status);
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
      return () => {
        clearTimeout(timer)
      }
    }, [timer])

    return (
        <>
            <Button
                color="link"
                id={`statusText_${ticketId}`}
                onClick={handleClick}
                className={getStatusColor()}
            >
                {statusName(status, t)}
            </Button>
            {typeof onStatusChange === 'function' && (
                <Popover
                    trigger="legacy"
                    placement="bottom"
                    target={`statusText_${ticketId}`}
                    isOpen={popoverOpen}
                    toggle={toggle}
                >
                    <PopoverHeader>{t('Змінити статус')}</PopoverHeader>
                    <PopoverBody style={{ padding: 0 }}>
                        <ListGroup flush>
                            {ticketStatusOptions(t).map(({ label, value }, index) => {
                                if (status === value) return null;

                                return (
                                    <ListGroupItem
                                        tag="button"
                                        key={`statusText_${ticketId}_${index}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onStatusChange(value);
                                            setTimer(setTimeout(() => {
                                                setPopoverOpen(false);
                                            }, 100));
                                        }}
                                        action
                                        style={{ backgroundColor: statusColorCode(value), color: '#FFF' }}
                                    >
                                        {label}
                                    </ListGroupItem>
                                );
                            })}
                        </ListGroup>
                    </PopoverBody>
                </Popover>
            )}
        </>
    );
};

export default TicketStatus;
