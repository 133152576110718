import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Avatar from '../Avatar';
import { addUser, openUsersList, sendInvitation } from '../../../../redux/actions/chat';
import s from './ChatInfo.module.scss';
import OnlineStatus from '../OnlineStatus/OnlineStatus';

const OperatorsList = (props) => {
  const { t } = useTranslation('chat');
  const { user: { operatorId }, uids, operators } = props;

  const [ops] = useState(() => {
    return props.activeOperators.filter(user => {
      if (user._id === operatorId) return false;
      if (uids.map(uid => {
        if (typeof uid === 'string') {
          return uid;
        } else {
          return uid._id;
        }
      }).indexOf(user._id) !== -1) return false;

      return true;
    });
  })
  

  const inviteOperator = (user) => {
    if (operators) {
      props.dispatch(addUser({ user, roomId: props.roomId }));
    } else {
      props.dispatch(sendInvitation({ user, roomId: props.roomId }));
    }   

    props.close();
  }

  const closeModal = () => {
    props.close()
    props.dispatch(openUsersList())
  }

  const _renderOps = () => {
    if (ops.length === 0) return <div>{t('Вільних операторів немає')}</div>;

    return ops.map(user => {
      return (
        <li key={user._id} onClick={() => inviteOperator(user)}>
          <Avatar
            classProp="mr-2"
            user={user}
            size={40}
            showStatus={false}
          />
          <div>
            <p className="mb-0">{user.name}</p>
            <small>
              <OnlineStatus user={{ isOnline: user.online, lastSeen: user.lastSeen }} />
            </small>
          </div>
        </li>
      );
    });
  }

  return (
    <div className={`${s.groupList} animated fadeIn`}>
      <div className={s.groupListModalWrapper}>
        <div className={s.backdrop}></div>
        <section className={`${s.groupListModal} chat-section`}>
          <header className={s.groupListHeader}>
            <h5>{t('Додати оператора')}</h5>
            <span className="text-muted" onClick={closeModal}>
              <i className={`la la-times ${s.laLg}`}></i>
            </span>
          </header>

          <ul className={s.groupList}>
            {_renderOps()}
          </ul>
        </section>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    activeOperators: state.chat.activeOperators,
    user: state.chat.user,
  }
}

export default connect(mapStateToProps)(OperatorsList);