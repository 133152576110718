import Axios from "axios";

export const loadEntities = async ({
  entity,
  page = 0,
  filters = {},
  per_page = 10,
  onResult = null,
  backend = '',
  additionalParams = {}
}) => {
  if (!entity) {
    throw new Error('Enitity name not specified');
  }
  const result = await Axios.get(`${backend}/entity/${entity}`, {
    params: {
      where: { clientId: localStorage.getItem('clientId'), ...filters },
      page, per_page, order: JSON.stringify([['id', 'desc']]), ...additionalParams
    }
  });
  if (onResult) {
    onResult(result);
  }

  return result;
}

export const loadEntity = async ({entity, id, onResult = null, backend = ''}) => {
  if (!entity) {
    throw new Error('Enitity name not specified');
  }
  if (!id) {
    throw new Error('Enitity id not specified');
  }
  const result = await Axios.get(`${backend}/entity/${entity}/${id}`);
  if (onResult) {
    onResult(result);
  }
  return result;
}

export const patchEntity = async ({ entity, id, data = {}, onResult, backend = '' }) => {
  if (!entity) {
    throw new Error('Enitity name not specified');
  }
  if (!id) {
    throw new Error('Enitity id not specified');
  }
  const result = await Axios.patch(`${backend}/entity/${entity}/${id}`, data);
  if (onResult) {
    onResult(result);
  }
  return result;
}

export const postEntity = async ({ entity, data = {}, onResult, backend = '' }) => {
  if (!entity) {
    throw new Error('Enitity name not specified');
  }
  const result = await Axios.post(
    `${backend}/entity/${entity}`,
    { clientId: localStorage.getItem('clientId'), ...data }
  );
  if (onResult) {
    onResult(result);
  }
  return result;
}

export const deleteEntity = async ({ entity, id, onResult, backend = '' }) => {
  if (!entity) {
    throw new Error('Enitity name not specified');
  }
  if (!id) {
    throw new Error('Enitity id not specified');
  }
  const result = await Axios.delete(
    `${backend}/entity/${entity}/${id}`
  );
  if (onResult) {
    onResult(result);
  }
  return result;
}
