import chroma from 'chroma-js';
import PropTypes from 'prop-types';
import React from 'react';
import { STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavItem,
    NavLink
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import MenuIcon from '../../images/sidebar/Fill/MenuIcon';
import Avatar from '../../pages/chat/components/Avatar/Avatar';
import { toggleMute, toggleSocketConnection } from '../../redux/actions/chat';
import {
    changeActiveSidebarItem,
    closeSidebar,
    openSidebar,
    toggleSidebar
} from '../../redux/actions/navigation';
import { getArchiveNotifications, getPersonalNotifications } from '../../redux/actions/personal-notifications';
import { logoutUser } from '../../redux/actions/user';
import { NavbarTypes } from '../../redux/reducers/layout';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import TierGuard, { Tiers } from '../TierGuard/TierGuard';
import s from './Header.module.scss';

class Header extends React.Component {
    timer;
    static propTypes = {
        sidebarOpened: PropTypes.bool.isRequired,
        sidebarStatic: PropTypes.bool.isRequired,
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        notifications: PropTypes.number,
        t: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.switchSidebar = this.switchSidebar.bind(this);
        this.toggleNotifications = this.toggleNotifications.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.handleMuteClick = this.handleMuteClick.bind(this);
        this.handleConnectClick = this.handleConnectClick.bind(this);

        this.state = {
            menuOpen: false,
            notificationsOpen: false,
            notificationsTabSelected: 1,
            focus: false,
            showNewMessage: false,
            hideMessage: true,
            run: true,
            notifications: props.notSeen
        };
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (!this.state.notificationsOpen) {
                this.props.dispatch(getPersonalNotifications());
                this.props.dispatch(getArchiveNotifications());
            }
        }, 300000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notSeen !== this.props.notSeen) {
          this.setState({...this.state, notifications: this.props.notSeen})
        }
    }

    handleJoyrideCallback = (CallBackProps) => {
        const { status } = CallBackProps;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            this.setState({ run: false });
        }
    };

    start = () => {
        this.setState({
            run: true,
        });
    };

    toggleFocus = () => {
        this.setState({ focus: !this.state.focus });
    };

    toggleNotifications() {
        this.setState({
            notificationsOpen: !this.state.notificationsOpen,
        });
    }

    doLogout() {
        this.props.dispatch(logoutUser());
    }

    // collapse/uncolappse
    switchSidebar() {
        if (this.props.sidebarOpened) {
            this.props.dispatch(closeSidebar());
            this.props.dispatch(changeActiveSidebarItem(null));
        } else {
            const paths = this.props.location.pathname.split('/');
            paths.pop();
            this.props.dispatch(openSidebar());
            this.props.dispatch(changeActiveSidebarItem(paths.join('/')));
        }
    }

    // static/non-static
    toggleSidebar() {
        this.props.dispatch(toggleSidebar());
        if (this.props.sidebarStatic) {
            localStorage.setItem('staticSidebar', 'false');
            this.props.dispatch(changeActiveSidebarItem(null));
        } else {
            localStorage.setItem('staticSidebar', 'true');
            const paths = this.props.location.pathname.split('/');
            paths.pop();
            this.props.dispatch(changeActiveSidebarItem(paths.join('/')));
        }
    }

    toggleMenu() {
        this.setState({
            menuOpen: !this.state.menuOpen,
        });
    }

    handleMuteClick() {
        this.props.dispatch(toggleMute());
    }

    handleConnectClick() {
        this.props.dispatch(toggleSocketConnection());
    }

    render() {
        const { navbarType, navbarColor, openUsersList, user, muted, audio, socketConnected } = this.props;
        return (
            <Navbar
                className={`${s.root} d-print-none ${
                    navbarType === NavbarTypes.FLOATING ? s.navbarFloatingType : ''
                }`}
                style={{ backgroundColor: navbarColor, zIndex: !openUsersList ? 100 : 0 }}
            >
                <Nav className="ml-3 bg-white">
                    <NavItem>                        
                        <NavLink className="fs-lg d-xl-none" onClick={this.switchSidebar}>
                            <span className={`d-md-none d-sm-down-block`}>
                                <span className={s.headerSvgFlipColor}>
                                    <MenuIcon maskId={1000} />
                                </span>
                            </span>
                            <span className={`ml-3 d-sm-down-none ${s.headerSvgFlipColor}`}>
                                <MenuIcon maskId={999} />
                            </span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="d-sm-down-none">
                        <Breadcrumbs />
                    </NavItem>
                </Nav>

                <NavLink className={`${s.navbarBrand} d-md-none align-middle ${s.headerSvgFlipColor}`}>
                    Shark Chat
                </NavLink>

                <Nav className="ml-auto bg-white" style={{
                        flex: 1,
                        justifyContent: 'flex-end'
                }}>
                    <NavItem className="d-sm-down-none">
                        <NavLink className="px-2">
                            <span className={s.headerSvgFlipColor}>
                                {!audio ? <i className="la la-volume-mute la-lg text-danger"></i> : null}
                            </span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="d-sm-down-none">
                        <NavLink className="px-2" onClick={this.handleMuteClick}>
                            <span className={s.headerSvgFlipColor}>
                                {muted ? (
                                    <i className="la la-bell-slash la-lg text-danger"></i>
                                ) : (
                                    <i className="la la-bell la-lg"></i>
                                )}
                            </span>
                        </NavLink>
                    </NavItem>
                    <TierGuard allowedTiers={[Tiers.swarm, Tiers.tiger]}>
                        <NavItem className="d-sm-down-none">
                            <NavLink className="px-2" onClick={this.handleConnectClick}>
                                <span className={`${s.headerSvgFlipColor} ${s.wifiIcon}`}>
                                    {socketConnected ? (
                                        <i className="la la-wifi la-lg"></i>
                                    ) : (
                                        <span className={s.wifiSlashed}>
                                            <i className="la la-wifi la-lg text-danger"></i>
                                            <i className="la la-slash la-flip-horizontal la-lg text-danger"></i>
                                            <i className={"la la-slash la-flip-horizontal la-lg " + s.doubleSlash}></i>
                                        </span>
                                    )}
                                </span>
                            </NavLink>
                        </NavItem>
                    </TierGuard>
                    {/* <Dropdown
                        nav
                        isOpen={this.state.notificationsOpen}
                        toggle={this.toggleNotifications}
                        id="basic-nav-dropdown"
                        className={s.notificationsMenu}
                    >
                        <DropdownToggle nav>
                            <i
                                className={`la la-cog ${
                                    chroma(navbarColor).luminance() < 0.4 ? 'text-white' : ''
                                }`}
                            />
                        </DropdownToggle>
                        <DropdownMenu
                            right
                            className={`${s.notificationsWrapper} py-0 animated animated-fast slideInDown`}
                        >
                            <Notifications opened={this.state.notificationsOpen} />
                        </DropdownMenu>
                    </Dropdown> */}
                    <Dropdown
                        nav
                        isOpen={this.state.menuOpen}
                        toggle={this.toggleMenu}
                        className="tutorial-dropdown pr-4"
                    >
                        <DropdownToggle
                            nav caret
                            className={`${chroma(navbarColor).luminance() < 0.4 ? 'text-white' : ''}`}
                        >
                            <span className="mr-2 d-sm-down-none">
                                <Avatar user={user} />
                            </span>
                            <span
                                className={`small d-sm-down-none ${this.props.sidebarStatic ? s.adminEmail : ''} ${
                                    chroma(navbarColor).luminance() < 0.4 ? 'text-white' : ''
                                }`}
                            >
                                {user.name || user.email || ''}
                            </span>
                            {this.state.notifications > 0 && <span className="ml-1 circle bg-primary text-white fw-bold">{this.state.notifications}</span>}
                        </DropdownToggle>
                        
                        <DropdownMenu right className={`super-colors`}>
                            <DropdownItem href="/profile">
                                <i className="la la-user" /> {this.props.t('Мій профіль')}
                            </DropdownItem>
                            {/* <DropdownItem divider /> */}
                            {/* <DropdownItem href="/#/app/extra/calendar">Calendar</DropdownItem> */}
                            {/* <DropdownItem href="/#/app/inbox">Inbox &nbsp;&nbsp;<Badge color="danger" pill className="animated bounceIn">9</Badge></DropdownItem> */}
                            <DropdownItem divider />
                            <DropdownItem onClick={this.doLogout}>
                                <i className="la la-sign-out" /> {this.props.t('Вихід')}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Nav>
            </Navbar>
        );
    }
}

function mapStateToProps(state) {
    return {
        sidebarOpened: state.navigation.sidebarOpened,
        sidebarStatic: state.navigation.sidebarStatic,
        navbarType: state.layout.navbarType,
        navbarColor: state.layout.navbarColor,
        user: state.chat.user,
        muted: state.chat.muted,
        audio: state.chat.audioPermission,
        socketConnected: state.chat.socketConnected
    };
}

export default withRouter(connect(mapStateToProps)(withTranslation('common')(Header)));
