import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import isScreen from '../../core/screenHelper';
import Email from '../../images/sidebar/Outline/Email';
import FileTextIcon from '../../images/sidebar/Outline/FileText';
import KeypadIcon from '../../images/sidebar/Outline/Keypad';
import FireIcon from '../../images/sidebar/Outline/List';
import MessageCircleIcon from '../../images/sidebar/Outline/MessageCircle';
import PersonIcon from '../../images/sidebar/Outline/Person';
import PieChartIcon from '../../images/sidebar/Outline/PieChart';
import ShoppingBagIcon from '../../images/sidebar/Outline/ShoppingBag';
import { changeActiveSidebarItem, closeSidebar, openSidebar } from '../../redux/actions/navigation';
import { setTicketPage } from '../../redux/tickets/actions';
import TierGuard, { Roles, Tiers } from '../TierGuard/TierGuard';
import FeedbackFormModal from './FeedbackFormModal';
import LinksGroup from './LinksGroup/LinksGroup';
import s from './Sidebar.module.scss';


const Sidebar = ({ sidebarStatic = true, sidebarOpened = false, dispatch, activeItem = '', location, tier, chatBadge, newTickets }) => {
  const { t } = useTranslation('sidebar');
  const [feedbackForm, setFeedbackForm] = useState(false);

  const onMouseEnter = () => {
    if (!sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      const paths = location.pathname.split('/');
      paths.pop();
      dispatch(openSidebar());
      dispatch(changeActiveSidebarItem(paths.join('/')));
    }
  }

  const onMouseLeave = () => {
    if (!sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      dispatch(closeSidebar());
      dispatch(changeActiveSidebarItem(null));
    }
  }


  const statisticsMenu = () => {
    if (!tier || tier === Tiers.free) {
      return [{
        header: t("Всі виклики"),
        link: "/statistics/all"
      }, {
        header: t("Оператори"),
        link: "/statistics/operators"
      }, {
        header: t("Користувачі"),
        link: "/statistics/clients"
      }]
    }
    return [{
      header: t("Всі виклики"),
      link: "/statistics/all"
    }, {
      header: t("Оператори"),
      link: "/statistics/operators"
    }, {
      header: t("Користувачі"),
      link: "/statistics/clients"
    }, {
      header: t("Історія"),
      link: "/history"
    }, {
      header: t("Відгуки"),
      link: "/reviews"
    }];
  }

  const settingsMenu = () => {
    const menuItems = [
      {
        header: t("Оплата тарифу"),
        link: "/payments/services",
        className: 'payments-menu-link'
      },      
      {
        header: t("Домени"),
        link: "/settings/domains"
      },
      {
        header: t("Профіль компанії"),
        link: "/settings/account"
      },
      {
        header: t("Оператори"),
        link: "/settings/operators"
      },
      {
        header: t("Швидкі відповіді"),
        link: "/settings/replies"
      },
      {
        header: t("Налаштування смс"),
        link: "/settings/sms-services"
      },
      {
        header: t("Переклади"),
        link: "/settings/translations"
      }
    ];
    if (tier && (tier === Tiers.swarm || tier === Tiers.tiger)) {
      menuItems.push({
        header: t("Стартові діалоги"),
        link: "/settings/proactive"
      });
      menuItems.push({
        header: t("API токени"),
        link: "/settings/api-token"
      });
    }
    return menuItems;
  }

  const handleFeedbackClick = (e) => {
    e.preventDefault();
    setFeedbackForm(true);
  }

  return (
    <div className={`${(!sidebarOpened && !sidebarStatic) ? s.sidebarClose : ''} ${s.sidebarWrapper}`}>
      <nav
        onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
        className={s.root}
      >
        <header className={s.logo}>
          <div className={`${s.holder} d-none d-xl-block`}>
            <img src="/images/shark-chat-w.svg" alt="" />
          </div>
        </header>

        <ul className={s.nav}>
          <LinksGroup
            onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={activeItem}
            header={t("Статистика")}
            isHeader
            iconElement={<PieChartIcon />}
            iconName="flaticon-controls"
            link="/statistics/all"
            index="statistics"
            childrenLinks={statisticsMenu()}
          />

          <LinksGroup
            link="/chat"
            isHeader
            iconElement={<MessageCircleIcon />}
            iconName="flaticon-users"
            header={t("Чат")}
            index="chat"
            badge={chatBadge > 0 ? String(chatBadge) : ''}
          />
          <TierGuard allowedTiers={[Tiers.swarm, Tiers.tiger]}>
            <LinksGroup
              onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
              activeItem={activeItem}
              header={t("Тікети")}
              link="/tickets"
              isHeader
              index="tickets"
              iconName="flaticon-checked-1"
              iconElement={<FireIcon />}
              badge={newTickets > 0 ? String(newTickets) : ''}
              onMenuItemClick={() => dispatch(setTicketPage(0))}
            />
          </TierGuard>
          <LinksGroup
            link="/contacts"
            isHeader
            iconElement={<Email />}
            iconName="flaticon-users"
            header={t("Контакти")}
            index="contacts"
          />

          <LinksGroup
            onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={activeItem}
            header={t("Файли")}
            link="/files"
            isHeader
            index="files"
            iconName="flaticon-folder"
            iconElement={<ShoppingBagIcon />}
          />
          <TierGuard allowedRoles={[Roles.admin, Roles.root]} allowedTiers={[Tiers.swarm, Tiers.tiger]}>
            <LinksGroup
              onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
              activeItem={activeItem}
              header={t("Бот")}
              link="/bot"
              isHeader
              labelColor="danger"
              iconElement={<PersonIcon />}
              iconName="flaticon-user"
              index="bot"
              label={t("Сценарії")}
              childrenLinks={[
                {
                  header: t("Сценарії"),
                  link: "/bot"
                },
                {
                  header: t("Сценарій F.A.Q."),
                  link: "/bot/faq"
                }
              ]}
            />
          </TierGuard>
          <TierGuard allowedRoles={[Roles.admin, Roles.root]} allowedTiers={[Tiers.swarm, Tiers.tiger]}>
            <LinksGroup
              onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
              activeItem={activeItem}
              header={t("Довідка")}
              link="/kb"
              isHeader
              labelColor="success"
              iconElement={<FileTextIcon />}
              iconName="flaticon-file"
              index="kb"
              childrenLinks={[
                {
                  header: t("Документи"),
                  link: "/kb"
                },
                {
                  header: t("Налаштування"),
                  link: "/kb/settings"
                }
              ]}
            />
          </TierGuard>

          <TierGuard allowedRoles={[Roles.admin, Roles.root]}>
            <LinksGroup
              onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
              activeItem={activeItem}
              header={t("Налаштування")}
              link="/settings/account"
              isHeader
              index="settings"
              iconElement={<KeypadIcon />}
              iconName="flaticon-settings-4"
              childrenLinks={settingsMenu()}
            />
          </TierGuard>
        </ul>
        <div className={`text-center mt-auto small ${s.versionFeedback}`}>
          {process.env.REACT_APP_CI_COMMIT_TAG}<br />
          <a href="/#" onClick={handleFeedbackClick}>{t('Повідомити про помилку')}</a>
        </div>
      </nav>
      <FeedbackFormModal open={feedbackForm} setOpen={setFeedbackForm} />
    </div>
  );
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    alertsList: store.alerts.alertsList,
    activeItem: store.navigation.activeItem,
    navbarType: store.navigation.navbarType,
    sidebarColor: store.layout.sidebarColor,
    chatBadge: store.chat.notificationCount,
    newTickets: store.tickets.newTicketsCount,
    tier: store.settings.tierId
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
