import Axios from 'axios';
import React from 'react';
import SocialButton from '../../../components/SocialLogin/SocialButton'

import { useDispatch } from 'react-redux';
import { receiveToken } from '../../../redux/actions/user';
import { useTranslation } from 'react-i18next';
import { generatePassword } from '../../../utils/auth';



const SocialLoginButton = ({ setError, next, type}) => {
    const { t } = useTranslation('registration');


    /** Redux */

    const dispatch = useDispatch();


    /** Fn */

    const handleGoogleLogin = async (user) => {
        if (user._profile && user._profile.email) {
            try {
                /** Pre-registration token check, if user exist just login */
                const {
                    data: { token },
                } = await Axios.post('/auth/google', user);
                return dispatch(receiveToken(token));
            } catch (error) { }
            next({ email: user._profile.email, password: generatePassword() });
        } else {
            setError(t('Помилка при авторизації через Google. Спробуйте інший спосіб.'));
        }
    };

    const handleGoogleLoginFailure = ({ message }) => {
        if (!message) return;
        if (message.startsWith('[google][load]')) return;
        setError(t('Помилка при авторизації через Google. Спробуйте інший спосіб.'));
    };

    const handleFbLogin = async (user) => {
        console.log(user);
        if (user._profile && user._profile.email) {
            try {
                /** Pre-registration token check, if user exist just login */
                const {
                    data: { token },
                } = await Axios.post('/auth/fb', user);
                return dispatch(receiveToken(token));
            } catch (error) { }
            next({ email: user._profile.email, password: generatePassword() });
        } else {
            setError(t('Помилка при авторизації через Facebook. Спробуйте інший спосіб.'));
        }
    };

    const handleFbLoginFailure = (err) => {
        setError(t('Помилка при авторизації через Facebook. Спробуйте інший спосіб.'));
    };

    if (type === 'google') {
        return (
            <SocialButton
                provider="google"
                type="google"
                appId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onLoginSuccess={handleGoogleLogin}
                onLoginFailure={handleGoogleLoginFailure}
                size={'30px'}
                iconSize={'20px'}
                style={{ fontSize: '0.875rem' }}
            ></SocialButton>
        );
    }

    return (
        <SocialButton            
            provider="facebook"
            type="facebook"
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            onLoginSuccess={handleFbLogin}
            onLoginFailure={handleFbLoginFailure}
            size={'30px'}
            iconSize={'20px'}
            style={{ fontSize: '0.875rem' }}
        ></SocialButton>
    );
};

export default SocialLoginButton;
