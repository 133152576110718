export const NEW_TICKET = 'new_ticket';
export const SET_TICKET_LIST = 'set_ticket_list';
export const SET_TICKET_NOTIFICATION = 'set_ticket_list';
export const INCREMENT_TICKET_COUNTER = 'increment_ticket_counter';
export const RESET_TICKET_COUNTER = 'reset_ticket_counter';
export const FETCH_TICKETS = 'fetch_tickets';
export const SET_TICKETS_SIZE = 'set_tickets_size';
export const SET_TICKETS_TOTAL_PAGES = 'set_tickets_pages';
export const SET_TICKETS_PAGE = 'set_tickets_page';
export const APPEND_TICKET_MESSAGE = 'append_ticket_message';
export const MARK_MESSAGE_READED = 'mark_message_readed';
export const TICKET_UPDATE = 'ticket_update';
export const INVITE_TICKET_OPERATOR = 'invite_ticket_operator';