import { REGISTER_FAILURE, REGISTER_FINALIZE, REGISTER_REQUEST, REGISTER_SUCCESS, SET_IN_PROGRESS, SET_SHOW_INIT_MODAL } from '../actions/register';

export default function register(state = {
    isFetching: false,
    errorMessage: '',
    onboardingInProgress: false,
    showInitModal: false
}, action) {
    switch (action.type) {
        case REGISTER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case REGISTER_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: '',
            });
        case REGISTER_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.payload,
            });
        case REGISTER_FINALIZE:
            return Object.assign({}, state, {
                onboardingInProgress: false,
            });
        case SET_SHOW_INIT_MODAL:
            return Object.assign({}, state, {
                showInitModal: action.payload
            });
        case SET_IN_PROGRESS:
            return Object.assign({}, state, {
                onboardingInProgress: true
            });
        default:
            return state;
    }
}
