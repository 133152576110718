import Axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Col, FormFeedback, FormGroup, FormText, Input, Row } from 'reactstrap';
import SocialLoginButton from '../login/components/SocialLoginButton';


const AuthView = ({ next, form, dispatch, errors, onFieldChange }) => {
    const { t } = useTranslation('registration');
    const [error, setError] = useState(null);

    const { email, password, confirmPassword, promocode } = form;

    const checkPassword = () => {
        if (!isPasswordValid()) {
            if (!password) {
                setError(t('Вкажіть пароль'));
            } else {
                setError(t('Паролі не співпадають'));
            }
            return false;
        }
        return true;
    };

    const checkEmail = async () => {
        if (!isEmailValid()) {
            setError(t('Вкажіть email'));
            return false;
        }

        try {
            return await Axios.get('/auth/validate', { params: { email } }).then((res) => res.data);
        } catch (error) {
            setError(t('Email занято'));
        }

        return false;
    };

    const isPasswordValid = () => {
        return password && password === form.confirmPassword;
    };

    const isEmailValid = () => {
        return email && email.trim() !== '';
    };

    const handleNext = async () => {
        if (!(await checkEmail())) return;
        if (!checkPassword()) return;

        setError(null);
        next();
    };

    const handleSocialNext = (data) => {
        next({...data, promocode});
    }

    return (
        <div>
            <Row>
                <Col md={6}>
                    <p className="widget-auth-info mb-4">{t('Заповніть всі поля')}</p>
                    {error && (
                        <Alert className="alert-sm" color="danger">
                            {error}
                        </Alert>
                    )}
                    <FormGroup className="position-relative">
                        <Input
                            placeholder={t('Контактний email')}
                            invalid={errors['email'] !== false}
                            value={email}
                            type="text"
                            name="email"
                            onChange={onFieldChange}
                        />
                        <FormText color="muted">{t('Вкажіть контактний Email компанії')}</FormText>
                        <FormFeedback tooltip>{errors['email']}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Input
                            placeholder={t('Пароль')}
                            invalid={errors['password'] !== false}
                            value={password}
                            type="password"
                            name="password"
                            onChange={onFieldChange}
                        />
                        <FormFeedback tooltip>{errors['password']}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Input
                            placeholder={t('Підтвердити пароль')}
                            invalid={errors['confirmPassword'] !== false}
                            value={confirmPassword}
                            type="password"
                            name="confirmPassword"
                            onChange={onFieldChange}
                        />
                        <FormFeedback tooltip>{errors['confirmPassword']}</FormFeedback>
                    </FormGroup>
                    

                    <Button
                        type="button"
                        color="info"
                        className="auth-btn mb-3"
                        size="sm"
                        onClick={() => {
                            handleNext();
                        }}
                    >
                        {t('Далі')}
                    </Button>
                </Col>
                <Col md={6}>
                    <p className="widget-auth-info">{t('або авторизуйтеся через')}</p>
                    <div className="social-buttons">
                        <SocialLoginButton setError={setError} type={'google'} next={handleSocialNext} />
                        <SocialLoginButton setError={setError} type={'facebook'} next={handleSocialNext} />                        
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default withRouter(AuthView);
