import jwt from 'jsonwebtoken';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import LayoutComponent from '../components/Layout';
/* eslint-disable */
import ErrorPage from '../pages/error';
/* eslint-enable */
import Login from '../pages/login/Login';

import { getArchiveNotifications, getPersonalNotifications } from '../redux/actions/personal-notifications';
import { getClientNotifications } from '../redux/actions/client-notifications';
import { openSocket } from '../redux/actions/chat';
import { setupSettings } from '../redux/actions/settings';
import { openCrmSocket } from '../redux/middlewares/CrmSocketMiddleware';

import { logoutUser } from '../redux/actions/user';
import 'react-dates/lib/css/_datepicker.css';
import "react-datetime/css/react-datetime.css";
import '../styles/theme.scss';
import WindowStateHandler from './WindowStateHandler';
import RegistrationWizard from '../pages/register';
import GreetingModal from './GreetingModal/GreetingModal';
import SendSmsModal from './SendSmsModal/SendSmsModal';

import OnboardPage from '../pages/onboard/OnboardPage';
import ResetPage from '../pages/reset/ResetPage';

function isAuthenticated(token) {
    if (!token) return;
    const date = new Date().getTime() / 1000;
    const data = jwt.decode(token);
    return date < data.exp;
}

const PrivateRoute = ({ dispatch, component, ...rest }) => {
    if (!isAuthenticated(localStorage.getItem('token'))) {
        dispatch(logoutUser());
        return <Redirect to="/login" />;
    } else {
        
        return (
            // eslint-disable-line
            <Route {...rest} render={(props) => React.createElement(component, props)} />
        );
    }
};

const CloseButton = ({ closeToast }) => (
    <i onClick={closeToast} className="la la-close notifications-close" />
);

const App = ({isAuthenticated, dispatch}) => {
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(openSocket());
            dispatch(openCrmSocket());
            dispatch(setupSettings());
            dispatch(getPersonalNotifications());
            dispatch(getArchiveNotifications());
            dispatch(getClientNotifications());     
        }
    }, [isAuthenticated, dispatch])
    return (
        <div>            
            <ToastContainer autoClose={5000} hideProgressBar closeButton={<CloseButton />} />
            <WindowStateHandler></WindowStateHandler>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact render={() => <Redirect to="/statistics/all" />} />
                    <Route path="/register" exact component={RegistrationWizard} />
                    <Route path="/login" exact component={Login} />                    
                    <Route path="/reset" exact component={ResetPage} />              
                    <Route path="/onboard/:otp" exact component={OnboardPage} />                    
                    <PrivateRoute path="/" dispatch={dispatch} component={LayoutComponent} />
                    <Redirect from="*" to="/statistics/all" />
                    <Route path="/error" exact component={ErrorPage} />
                </Switch>
                <GreetingModal></GreetingModal>
                <SendSmsModal></SendSmsModal>
            </BrowserRouter>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
