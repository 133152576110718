import { useEffect, useRef, useState } from "react";

export function useInSight(ref) {
    const observer = useRef();
    /** Local state */
    const [inSight, setInSight] = useState(false);
    const [element, setElement] = useState();
    
    /** Local functions */
    const unsubscribe = () => {
        if (observer.current) {
            observer.current.disconnect();
        }
    };

    /** Effects */
    useEffect(() => {
        setElement(ref.current);
    }, [ref]);

    useEffect(() => {
        if (!element) return;
        unsubscribe();
        const ob = (observer.current = new IntersectionObserver((entries) =>
            entries.forEach((entry) => {
                setInSight(entry.isIntersecting);
            })
        ));
        ob.observe(element);
        return () => {
            unsubscribe();
        };
    }, [element]);

    return inSight;
}
