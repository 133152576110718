import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Commentator from '../../../components/Commentator/Commentator';
import PopoverQuestion from '../../../components/PopoverQuestion/PopoverQuestion';
import { selectStyles } from '../data/AgentStyles';
import { selectStatusStylesInner, Statuses, statusName, ticketStatusOptions } from '../data/TicketStatuses';
import LabelsView from './LabelsView';
import TicketMerger from './TicketMerger/TicketMerger';
import TicketForwarder from './TicketForwarder';

const TicketInfo = ({ ticket, agents, ticketsPageDisapatch, user, isJR }) => {
    const { t } = useTranslation('tickets');
    const [statusObj, setStatusObj] = useState(() => ({
        value: ticket.status,
        label: statusName(ticket.status, t),
    }));
    const [assigned, setAssigned] = useState(() => agents.find((a) => a.id === ticket.assignToId));
    const [showBlockConfirmation, setBlockConfirmation] = useState(false);

    /** Funcs */
    const changeStatus = async (selected) => {
        if (isJR) return;
        await Axios.patch(`/entity/Ticket/${ticket.id}`, { status: selected.value });
        setStatusObj(selected);
    };
    const changeLabels = async (labels) => {
        if (isJR) return;
        await Axios.patch(`/entity/Ticket/${ticket.id}`, { label: labels });
    };

    const assignTicketTo = useCallback(
        async (selected) => {
            try {
                if (isJR) return;
                await Axios.patch(`/entity/Ticket/${ticket.id}`, {
                    assignToId: selected ? selected.id : null,
                });
                setAssigned(selected);
                toast.success(t('Відповідального змінено'));
            } catch (error) {
                toast.error(t('Помилка при встановлені відповідального'));
            }
        },
        [ticket, isJR, t]
    );

    const blockContact = useCallback(async () => {        
        if (isJR) return;
        try {
            setBlockConfirmation(false);
            await Axios.patch(`/entity/Ticket/${ticket.id}/blockSender`);
            toast.warn(t('Відправника заблоковано'));
        } catch (error) {
            toast.error(t('Помилка при блокування'));
        }
    }, [ticket, isJR, t]);

    const unBlockContact = useCallback(async () => {     
        if (isJR) return;   
        try {
            setBlockConfirmation(false);
            await Axios.patch(`/entity/Ticket/${ticket.id}/unblockSender`);            
            toast.success(t('Відправника разблоковано'));
        } catch (error) {
            toast.error(t('Помилка при розблокування'));
        }
    }, [ticket, isJR, t]);

    const forwardMessage = useCallback(async (email) => {     
        if (isJR) return;   
        try {
            setBlockConfirmation(false);
            await Axios.patch(`/entity/Ticket/${ticket.id}/forwardTo`, {email});            
            toast.success(t('Повідомлення переслано'));
        } catch (error) {
            toast.error(t('Помилка при пересиланні'));
        }
    }, [ticket, isJR, t]);

    const assignToSelf = useCallback(() => {
        if (isJR) return;
        assignTicketTo(user);
    }, [assignTicketTo, user, isJR]);

    /** Effects */
    useEffect(() => {
        setStatusObj({
            value: ticket.status,
            label: statusName(ticket.status, t),
        });
    }, [ticket, t]);

    /** Renders */

    const _renderBlocker = () => {
        if (statusObj.value !== Statuses.REJECTED) {
            return (
                <>
                    <Button
                        color="danger"
                        id="blockContact"
                        block
                        className="mt-2"
                        onClick={() => setBlockConfirmation(true)}
                    >
                        {t('Заблокувати адресата')}
                    </Button>

                    <PopoverQuestion
                        target="blockContact"
                        onClose={setBlockConfirmation}
                        isOpen={showBlockConfirmation}
                        onOk={blockContact}
                        text={t('Заблокувати адресата?')}
                    />
                </>
            );
        }

        if (statusObj.value === Statuses.REJECTED) {
            return (
                <>
                    <Button
                        color="success"
                        id="unBlockContact"
                        block
                        className="mt-2"
                        onClick={() => setBlockConfirmation(true)}
                    >
                        {t('Розблокувати адресата')}
                    </Button>

                    <PopoverQuestion
                        target="unBlockContact"
                        onClose={setBlockConfirmation}
                        isOpen={showBlockConfirmation}
                        onOk={unBlockContact}
                        text={t('Розблокувати адресата?')}
                    />
                </>
            );
        }
        return null;
    };

    return (
        <div>
            <div
                className="d-lg-none chat-mobile-navigation"
                onClick={() => ticketsPageDisapatch({ type: 'TOGGLE_PANEL' })}
            >
                <i className="la la-angle-left la-lg"></i> {t('До листування')}
                <hr />
            </div>

            <FormGroup className="tickets-jr-step-4">
                <Label>{t('Відповідальний')}</Label>
                <Select
                    styles={selectStyles}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    options={agents}
                    placeholder={t('Оператор')}
                    isClearable
                    onChange={assignTicketTo}
                    value={assigned}
                />
                <Button size="sm" color="link" onClick={assignToSelf}>
                    <i className="la-check la" />
                    &nbsp;{t('назначити собі')}
                </Button>
            </FormGroup>
            <hr />
            <FormGroup className="tickets-jr-step-5">
                <Label>{t('Статус')}</Label>
                <Select
                    styles={selectStatusStylesInner}
                    value={statusObj}
                    onChange={changeStatus}
                    options={ticketStatusOptions(t)}
                    placeholder={t('Статус')}
                />
            </FormGroup>
            <hr />
            <FormGroup className="tickets-jr-step-6">
                <Label>{t('Теги')}</Label>
                <LabelsView
                    labels={ticket.label}
                    showTitle={false}
                    editable={true}
                    labelsChange={changeLabels}
                />
            </FormGroup>
            <hr />
            <div className="tickets-jr-step-7">
                <Label>{t('Коментарі')}</Label>
                <Commentator isJR={isJR} entityType="ticket" entityId={String(ticket.id)} />
            </div>
            <hr />
            <FormGroup className="tickets-jr-step-8">
                <Label>{t('Дії')}</Label>
                <TicketMerger isJR={isJR} ticket={ticket} block />
                {_renderBlocker()}
                <TicketForwarder onSave={forwardMessage}/>
            </FormGroup>
        </div>
    );
};

export default TicketInfo;
