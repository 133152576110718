import React from 'react';
import {
  Container
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import s from './ErrorPage.module.scss';

const ErrorPage = () => {
  const { t } = useTranslation('common');

  return (
    <div className={s.errorPage}>
      <Container>
        <div className={`${s.errorContainer} mx-auto`}>
          <h1 className={s.errorCode}>404</h1>
          <p className={s.errorInfo}>
            {t('Opps, it seems that this page does not exist.')}
          </p>                      
        </div>
      </Container>
    </div>
  );
}

export default ErrorPage;
