import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ButtonToolbar,
    Popover, PopoverBody, PopoverHeader
} from "reactstrap";


const DeleteButton = ({ rowId, deleteItem, moreContent, popovers, togglePopover, isDeleting, idToDelete, additionalText }) => {
    const { t } = useTranslation('common');
    return (<ButtonToolbar className="justify-content-end">
      {moreContent}
      <Button id={'popoverDelete_' + rowId} color="danger" size="sm" onClick={() => togglePopover(String(rowId))}>
          {isDeleting && idToDelete === rowId ? <i className="la la-circle-notch la-spin" style={{ fontSize: 10 }} /> :
              <span>
                  <span className="d-none d-md-inline-block">{t('Видалити')}</span>
                  <span className="d-md-none"><i className='la la-remove' /></span>
              </span>
          }
      </Button>
      <Popover className="popover-danger" target={'popoverDelete_' + rowId} placement="top" isOpen={popovers[String(rowId)] ? true : false}
          // toggle={() => { togglePopover(String(row.id)) }}
      >
          <PopoverHeader className="px-5">{t('Ви впевнені?')}</PopoverHeader>
          <PopoverBody>
            {additionalText && <div className="d-block text-center mb-2">{additionalText}</div>}
            <div className="px-5 d-flex justify-content-center">
              <ButtonToolbar>
                  <Button color="success" size="xs" onClick={() => { deleteItem(rowId) }}>
                      {t('Так')}
                  </Button>
                  <Button color="danger" size="xs" onClick={() => { togglePopover(rowId) }}>
                      {t('Ні')}
                  </Button>
              </ButtonToolbar>
            </div>
          </PopoverBody>
      </Popover>
  </ButtonToolbar>)
};

export default DeleteButton;
