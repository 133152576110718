import {
    CLEAR_TYPING,
    FETCH_ROOM_MESSAGES,
    LEAVE_ROOM,
    READ_NEW,
    RECIVE_ROOM_MESSAGE,
    REMOVE_ROOM_MESSAGE,
    RESET_ACTIVE_ROOM,
    RESET_PAGINATION,
    SET_ACTIVE_ROOM,
    SET_LAST_SEEN_TIME,
    SET_ROOM_MESSAGES,
    UPDATE_ROOM,
    UPDATE_ROOM_MESSAGE,
    UPDATE_ROOM_MESSAGES,
    UPDATE_TYPING,
} from './types';

import { omit } from 'lodash';

const initialState = {
    roomId: undefined,
    room: {
        roomId: undefined,
        // clientId: undefined,
        // created: undefined,
        // people: [],
        // channelId: undefined,
        // channelContext: {
        //     chatId: undefined,
        // },
        // updated: undefined,
        // boted: false,
        // name: undefined,
        // photo: undefined,
        // person: undefined,
        // roomData: {
        //     phone: undefined,
        //     email: undefined,
        //     ip: undefined,
        //     city: undefined,
        //     country: undefined,
        //     browser: undefined,
        //     os: undefined,
        //     device: undefined,
        //     pageUrl: undefined,
        //     pageTitle: undefined,
        //     domain: undefined,
        // },
        // live: false,
        // operators: false,
        // personal: false,
        // hasUnreaded: false,
        // hasOffline: false,
        // isGroup: false,
        // receivedChatId: undefined,
        // lastSeenTimeOp: undefined,
        // lastSeenTimeClient: undefined,
        // active: true,
    },
    messages: {},
    pagination: {
        skip: 0,
        limit: 50,
    },
    typing: undefined,
    hasNew: false,
    initializationDone: false,
    loading: false
}

export default function activeRoom(
    state = initialState,
    action
) {
    switch (action.type) {
        case SET_ACTIVE_ROOM:
            return {
                ...state,
                room: action.payload,
                roomId: action.payload.roomId
            };
        case UPDATE_ROOM:
            if (action.payload.roomId !== state.roomId) return state;
            return {
                ...state,
                room: { ...state.room, ...action.payload },
            };

        case SET_ROOM_MESSAGES:
            if (action.payload.roomId !== state.roomId) return state;
            return {
                ...state,
                messages: action.payload.messages,
            };
        case UPDATE_ROOM_MESSAGES:
            if (action.payload.roomId !== state.roomId) return state;
            return {
                ...state,
                messages:
                    action.payload.extra && action.payload.extra.append
                        ? { ...action.payload.messages, ...state.messages }
                        : action.payload.messages,
                room: { ...state.room, gotOlder: action.payload.gotOlder },
                loading: false
            };
        case RECIVE_ROOM_MESSAGE:
            if (action.payload.roomId !== state.roomId) return state;

            return {
                ...state,
                hasNew: true,
                messages: {
                    ...state.messages,
                    [action.payload._id]: action.payload,
                },
            };        
        case UPDATE_ROOM_MESSAGE:
            const { message } = action.payload;
            if (!message) return state;

            if (message.roomId !== state.roomId) return state;

            const newMessages = {
                ...state.messages,
                [message._id]: message,
            };

            return {
                ...state,
                messages: newMessages
            };
        case REMOVE_ROOM_MESSAGE:
            if (action.payload.roomId !== state.roomId) return state;
            return {
                ...state,
                messages: omit(state.messages, action.payload.messageId),
            };
        case UPDATE_TYPING:
            if (action.payload.roomId !== state.roomId) return state;
            return {
                ...state,
                typing: action.payload,
            };
        case CLEAR_TYPING:
            if (action.payload.roomId !== state.roomId) return state;
            return {
                ...state,
                typing: undefined,
            };
        case RESET_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
            };
        case READ_NEW:
            return { ...state, hasNew: false };
        case LEAVE_ROOM:
            return { ...state, roomId: undefined, room: initialState.room, messages: {} };
        case FETCH_ROOM_MESSAGES:
            return { ...state, loading: true }
        case SET_LAST_SEEN_TIME:
            if (action.payload.roomId !== state.roomId) return state;
            return {
                ...state,
                room: { ...state.room, lastSeenTimeClient: action.payload.time },
            };
        case RESET_ACTIVE_ROOM:
            return initialState;
        default:
            return state;
    }
}
