import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import isScreen from '../../core/screenHelper';
import { MobileChatStates } from '../../redux/reducers/chat';
import s from './Chat.module.scss';
import ChatDialog from './components/ChatDialog';
import ChatList from './components/ChatList';
import ChatSidePanel from './components/ChatSidePanel';
import { showAutomations, updatePerson } from '../../redux/actions/chat';
import { stopJR } from '../../redux/actions/joyride';

const Chat = (props) => {
  const { t, i18n } = useTranslation('chat');
  const { mobileState } = props;

  const dispatch = useDispatch();
  const jr = useSelector((state) => get(state, 'joyride.scenarios', {}));
  const [jrReset, setJrReset] = useState(0);

  const handleJREvent = (data) => {
    const { action, index, status } = data;
    if (action === ACTIONS.CLOSE || action === ACTIONS.SKIP || (
      action === ACTIONS.NEXT && status === STATUS.FINISHED
    )) {
      dispatch(stopJR('chat'));
      setJrReset(jrReset === 0 ? 1 : 0);
      return;
    }
    if (action === ACTIONS.UPDATE && index === 4) {
      dispatch(showAutomations());
    }
  }

  useEffect(() => {
    const handleContextClick = event => {
      if (!event.target.className) return;

      if (event.target.className.indexOf('phone-pop-button') !== -1) {
        dispatch(updatePerson({
          roomId: event.target.getAttribute('data-room'),
          person: { phone: event.target.getAttribute('data-body') }
        }))
      }
      if (event.target.className.indexOf('email-pop-button') !== -1) {
        dispatch(updatePerson({
          roomId: event.target.getAttribute('data-room'),
          person: { email: event.target.getAttribute('data-body') }
        }))
      }
    };

    document.body.addEventListener('click', handleContextClick);
    return () => {
      document.body.removeEventListener('click', handleContextClick)
    };
  }, [dispatch]);

  return (
    <div>
      <Joyride
        key={jrReset}
        steps={jr && jr['chat'] ? jr['chat'].steps[i18n.language ? i18n.language : 'uk'] : []}
        run={jr && jr['chat'] && jr['chat'].state !== 'stopped'}
        callback={handleJREvent}
        showSkipButton={true}
        continuous={true}
        locale={{ back: t('Назад'), close: t('Закрити'), last: t('Завершити'), next: t('Далі'), open: t('Відкрити'), skip: t('Пропустити') }}
        styles={{
          options: { primaryColor: '#FD5F00', buttonNext: { outline: "none" } }
        }}
      />
      <div className={`chat-page-wrapper ${s.chatPage} ${mobileState === MobileChatStates.LIST ? 'list-state' : ''} ${mobileState === MobileChatStates.CHAT ? 'chat-state' : ''} ${mobileState === MobileChatStates.INFO ? 'info-state' : ''} ${isScreen('sm') || isScreen('xs') ? s.mobile : ''}`}>
        <ChatList />
        <ChatDialog />
        <ChatSidePanel />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    mobileState: state.chat.mobileState
  }
}

export default connect(mapStateToProps)(Chat);