import socketIOClient from 'socket.io-client';
import config from '../../config';
import { setPaymentStatus } from '../payments/actions';

export const CONNECT_TO_CARD_SOCKET = 'connect_card_socket';
export const DISCONNECT_CARD_SOCKET = 'disconnect_card_socket';

export function initCardSocket(payload) {
    return {
        type: CONNECT_TO_CARD_SOCKET,
        payload
    };
}

export function disconnectCardSocket() {
    return {
        type: DISCONNECT_CARD_SOCKET
    };
}


let socket;

const CardSocketMiddleware = (store) => {

    const setCardSocket = (transactionId) => {
        if (socket) {
            return;
        }

        const nspOperator = `/s/payments/${transactionId}`;
        socket = socketIOClient(`${config.baseURLApi}${nspOperator}`, {
            transports: ['websocket'],
        });

        socket.on('connect', () => {});

        socket.on('payment_success', () => {
            store.dispatch(setPaymentStatus(true));
        });

        socket.on('payment_failure', () => {
            store.dispatch(setPaymentStatus(false));
        });
    };

    return (next) => (action) => {
        switch (action.type) {
            case CONNECT_TO_CARD_SOCKET:
                setCardSocket(action.payload);
                break;
            case DISCONNECT_CARD_SOCKET:
                if (!socket) return;
                socket.disconnect();
                break;
            default:
                break;
        }
        return next(action);
    };
};

export default CardSocketMiddleware;
