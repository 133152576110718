import React from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setShowInitModal } from '../../redux/actions/register';

const GreetingModal = () => {
  const { t } = useTranslation('common')
  const history = useHistory();
  const dispatch = useDispatch();
  const showModal = useSelector((state) => get(state, 'register.showInitModal', false));

  if (!showModal) {
    return null;
  }

  const hideModal = () => {
    dispatch(setShowInitModal(false));
  }

  const goToPage = (page) => {
    history.push(page);
    hideModal();
  }

  return (
    <Modal width={1200} isOpen={showModal} centered={true} footer={null}
      toggle={() => hideModal()} onCancel={() => hideModal()}>
      <div className="p-4 text-center">
        <Button close onClick={() => hideModal()} />
        <div className="mt-3 mb-4"><img src="/images/shark-chat.svg" alt="" width="120" /></div>
        <h3>{t('Дякуємо за реєстрацію!')}</h3>
        <p className="mb-4">{t('Ознайомтеся з новими можливостями')}</p>
        <div className="mb-5 h5 btn btn-danger cursor-pointer" onClick={() => goToPage('/settings/domains')}>{t('Налаштування віджету')}</div>
        <div className="mb-3 h5 text-primary cursor-pointer" onClick={() => goToPage('/chat')}>{t('Спілкування в чаті')}</div>
        <div className="mb-3 h5 text-primary cursor-pointer" onClick={() => goToPage('/tickets')}>{t('Система тікетів')}</div>
        <div className="mb-3 h5 text-primary cursor-pointer" onClick={() => goToPage('/statistics/all')}>{t('Статистика викликів')}</div>
        <div className="mb-5 h5 text-primary cursor-pointer" onClick={() => goToPage('/settings/account')}>{t('Налаштування профілю')}</div>
      </div>
    </Modal>
  );
}

export default GreetingModal;