import * as Sentry from "@sentry/react";
import Axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useForm } from '../../utils/useForm';

const FeedbackFormModal = ({ open, setOpen }) => {
    const { t } = useTranslation('common');
    const validate = (field, value) => {
        switch (field) {
            case 'message':
                if (!value || value.length === 0) {
                    return true;
                }
                break;            
            default:
                break;
        }
        return false;
    };

    const { formState, dispatch, handleFieldChange } = useForm({
        fields: {
            email: '',
            message: ''
        },
        validate
    });

    const {
        values: { message },
    } = formState;

    const sendMessage = async (e) => {
        e.preventDefault();

        const hasErrors =
            Object.entries(formState.values)
                .map(([name, value]) => validate(name, value))
                .filter((i) => i).length > 0;

        if (hasErrors) return toast.warn(t('Заповніть корректно форму'));

        try {
            await Axios.post('/user/feedback', formState.values);            
            toast.success(t('Повідомлення відправлено!'));
            dispatch({
                type: 'CLEAN_FORM'
            })
            setOpen();
        } catch (error) {
            Sentry.captureException(error);
            toast.error(t('Помилка при відправці повідомлення!'));
        }
    }

    return (
        <Modal
            centered
            isOpen={open}
            toggle={() => setOpen()}
        >
            <ModalHeader toggle={() => setOpen()} id="news-close-modal-label">
                {t('Повідомити про помилку')}
            </ModalHeader>
            <ModalBody className="bg-white" >
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{t('Повідомлення')}</Label>
                                <Input
                                    value={message}
                                    name="message"
                                    type="textarea"
                                    onChange={handleFieldChange}
                                />
                                <FormText color="muted">{t('Введіть повідомлення')}.</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="info" onClick={() => setOpen()} data-dismiss="modal">
                    {t('Скасувати')}
                </Button>
                <Button
                    onClick={sendMessage}
                    color="success"
                    data-dismiss="modal"
                >
                    {t('Відправити')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default FeedbackFormModal
