import Axios from 'axios';
import Favico from 'favico.js';
import { get } from 'lodash';
import config from '../../config';
import { ADD_NOTIFICATION, INCREMENT_NOTIFICATION_COUNT, setActiveChat, setAudioPermission, SET_NOTIFICATION_COUNT } from '../actions/chat';

const applicationServerKey = process.env.REACT_APP_VAPID_PUB;

const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
const registerNotificationsSW = async () => {
    if ('serviceWorker' in navigator) {
        return await navigator.serviceWorker.register('/notificationsSW.js');
    } else {
        throw new Error('Service workers are not supported.');
    }
};

const askPermission = async () =>
    new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    });

const subscribeUser = async (serviceWorkerRegistration) => {
    return await serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
    });
};

// const notificationAudioRequest = () => {
//     const audio = new Audio('/1sec.mp3');
//     audio.play().catch((err) => {
//         toast(<ToastQuestion text="Дозволити відтворювати звуки для повідомлень"/>);
//     });
// };

const sw = navigator.serviceWorker;

const favicon = new Favico({
    animation: 'slide'
});

let registration;

const PushMiddleware = (store) => {
    const ping = () => {
        try {
            const audio = new Audio('/me-too-603.mp3');
            audio.play().catch((err) => {
                store.dispatch(setAudioPermission(false));
            });
        } catch (error) { }
    };

    const subscribeRoutine = async (withPush = false) => {
        registration = await registerNotificationsSW();

        /**
         * Subscribe operator to offline push
         */
        if (withPush) {
            const {
                chat: { user },
            } = store.getState();
            const subscrition = await subscribeUser(registration);
            await Axios.post(`${config.chatBackend}/operator`, {
                operatorId: user.operatorId,
                pushSubscription: JSON.stringify(subscrition),
            });
        }

        await sw.ready;
        sw.addEventListener('message', handleMessage);
    };

    if (isSupported && typeof Notification !== 'undefined') {
        if (Notification.permission === 'granted') {
            subscribeRoutine().catch((err) => {});
        } else {
            askPermission()
                .then(function (permissionResult) {
                    if (permissionResult !== 'granted') {
                        throw new Error('Chat functionality requires permission for notifications');
                    } else {
                        subscribeRoutine(true).catch((err) => {});
                    }
                })
                .catch((error) => {});
        }
    }

    const handleMessage = async ({ data }) => {
        if (data.messageType !== 'notification') {
            return;
        }

        const notification = data.notification;
        if (data.notificationTag === 'new-chat' || data.notificationTag === 'invitation') {
            await store.dispatch(setActiveChat(notification));
        }

        if (data.notificationTag === 'new-message') {
            await store.dispatch(setActiveChat(notification));
        }

        if (data.notificationTag === 'new-ticket') {
            if (window) {
                return window.location.href = `/tickets/${notification.id}`;
            }
        }

        if (window) {
            window.location.href = '/chat';
        }
    };

    const receivePushNotification = async (event) => {
        const {
            chat: { muted },
        } = store.getState();
        if (muted) {
            return;
        }

        ping();
        const { image, tag, data, text, title, type } = event;

        const options = {
            data,
            body: text,
            vibrate: [200, 100, 200],
            tag: tag,
            image: image,
            actions: [{ action: 'Деталі', title: 'Переглянути' }],
            icon: '/apple-touch-icon.png',
            renotify: true,
            type,
            requireInteraction: true
        };

        if (registration && registration.showNotification) {
            await registration.showNotification(title, options);
        }
    };

    // notificationAudioRequest();

    const regInteraction = (e) => {
        store.dispatch(setAudioPermission(true));

        document.body.removeEventListener('keydown', regInteraction);
        document.body.removeEventListener('click', regInteraction);
        document.body.removeEventListener('touchstart', regInteraction);
    }

    if (document) {
        document.body.addEventListener('keydown', regInteraction);
        document.body.addEventListener('click', regInteraction);
        document.body.addEventListener('touchstart', regInteraction);
    }

    const setFavbadge = (count) => {
        favicon.badge(count);
    }

    return (next) => (action) => {
        switch (action.type) {
            case ADD_NOTIFICATION:                
                receivePushNotification(action.payload);
                break;
            case INCREMENT_NOTIFICATION_COUNT:
                setFavbadge(get(store.getState(), 'chat.notificationCount', 0) + 1);
                break;
            case SET_NOTIFICATION_COUNT:
                favicon.reset();
                break;
            default:
                break;
        }
        return next(action);
    };
};

export default PushMiddleware;
