import * as Sentry from '@sentry/react';
import Axios from 'axios';
import { get, isEqual } from 'lodash';
import moment from 'moment';
import 'moment/locale/uk';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import { Item, Menu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Badge, Button } from 'reactstrap';
import uuid from 'uuid';
import DocPreviewModal from '../../../../components/DocRenderer/DocPreviewModal';
import {
    changeMobileState,
    deleteMessage,
    newMessageRequest,
    setChatPagination,
    updateLastSeenOp,
    updateMessage,
} from '../../../../redux/actions/chat';
import { fetchMessages, fetchRoomInfo, readNew, resetPagination } from '../../../../redux/active-room/actions';
// import { showSendSmsDialog } from '../../../../redux/sms/actions';
import { MobileChatStates } from '../../../../redux/reducers/chat';
import { sendSms } from '../../../../redux/sms/actions';
import { useInSight } from '../../../../utils/useInSight';
import OnlineStatus from '../OnlineStatus';
import s from './ChatDialog.module.scss';
import { ChatDrop } from './ChatDrop';
import ChatMessage from './ChatMessage';
import ChatTyping from './ChatTyping';
import NewMessage from './NewMessage';

export const MENU_ID = 'MESSAGE_MENU';

const ChatDialog = () => {
    const { t } = useTranslation('chat');
    let chatDialogBodyRef = useRef();

    const ref = useRef();
    const inSight = useInSight(ref);

    /** Redux */
    const dispatch = useDispatch();

    const user = useSelector((state) => get(state, 'chat.user', {}));
    const operators = useSelector((state) => get(state, 'chat.users', []));
    const socketConnected = useSelector((state) => get(state, 'chat.socketConnected'));
    const canSendSms = useSelector((state) => get(state, 'sms.canSendSms'));

    const { operatorId } = user;

    const activeRoomId = useSelector((state) => get(state, 'room.roomId'));
    const loading = useSelector((state) => get(state, 'room.loading'));
    const pagination = useSelector((state) => get(state, 'room.pagination'));
    const messages = useSelector((state) => {
        return Object.values(get(state, 'room.messages', {})).reverse()
    }, isEqual);
    const hasNew = useSelector((state) => get(state, 'room.hasNew', false));

    const chat = useSelector(
        (state) => {
            const room = get(state, 'room.room');
            const {
                roomId,
                clientId,
                channelId,
                roomData,
                person,
                lastSeenTimeClient,
                name,
                created,
                live,
                hasOffline,
                gotOlder,
                personal,
                people,
                isGroup
            } = room;
            return {
                roomId,
                clientId,
                channelId,
                roomData,
                person,
                lastSeenTimeClient,
                name,
                created,
                live,
                hasOffline,
                gotOlder,
                personal,
                people,
                isGroup
            };
        },
        (left, right) => {
            // console.log(right);
            // console.log(`Will chat init redraw: ${!isEqual(left, right)}`);
            return isEqual(left, right);
        }
    );

    const {
        roomId,
        clientId,
        channelId,
        roomData,
        person,
        lastSeenTimeClient,
        name,
        created,
        live,
        hasOffline,
        gotOlder,
        personal,
        people
    } = chat;

    /** States */

    const [messageInEdit, setInEdit] = useState();
    const [imageFullscreen, setImageFullscreen] = useState();
    const [wordPreview, setWordPreview] = useState();
    const [showMoreButton, setShowMoreButton] = useState(false)
    const [inHistory, setInHistory] = useState(false);

    /** Fn */

    const onDrop = useCallback(
        async ({ files }) => {
            if (files.length > 0) {
                try {
                    const formData = new FormData();
                    formData.append('clientId', clientId);
                    formData.append('roomId', roomId);
                    for (const file of files) {
                        formData.append('chatFile', file);
                    }
                    const { data } = await Axios.post(`/file`, formData);
                    if (data) {
                        dispatch(
                            newMessageRequest({
                                dialogId: roomId,
                                message: '',
                                channelId,
                                attachments: data,
                            })
                        );
                    }
                } catch (error) {
                    console.error(error.message);
                }
            }
        },
        [dispatch, clientId, roomId, channelId]
    );

    const onDropMessage = useCallback(
        async ({ message, domain }) => {
            dispatch(
                newMessageRequest({
                    dialogId: roomId,
                    message: message,
                    domain,
                    channelId,
                })
            );
        },
        [dispatch, roomId, channelId]
    );

    const createTicket = useCallback(
        async (message) => {
            try {
                await Axios.post('/tickets', {
                    message,
                    email: person.email,
                    topic: `Звернення в чат від ${moment().format('DD.MM.YYYY')}`,
                });
                toast.success(t('Тікет створено!'));
            } catch (error) {
                Sentry.captureException(error);
                toast.error(t('Помилка при створенні тікету. Служба підтримки попереджена.'));
            }
        },
        [person, t]
    );


    const _scroll = useCallback(() => {
        // if (chatDialogBodyRef.current) {
        //     chatDialogBodyRef.current.scrollTop =
        //         chatDialogBodyRef.current.scrollHeight;

        // }

        setTimeout(function () {
            requestAnimationFrame(() => {
                let log = document.getElementById('messagesList');
                if (log) {
                    log.scrollTop = log.scrollHeight;
                }
            })
        })

    }, []);

    const handleItemClick = ({ event, props }) => {
        const { message } = props;
        switch (event.currentTarget.id) {
            case 'editMessage':
                setInEdit(message._id);
                break;
            case 'deleteMessage':
                dispatch(deleteMessage(message._id));
                break;
            case 'copyMessage':
                navigator.clipboard.writeText(message.text);
                break;
            case 'createTicket':
                createTicket(message.text);
                break;
            case 'sendSms':
                sendAsSms(message.text);
                break;
            default:
                break;
        }
    };

    const sendAsSms = (message) => {
        dispatch(sendSms({phone: person.phone, message}))
        .then(res => {
            toast.success(t('Повідомлення надіслано'));
        })
        .catch(err => {
            toast.error(t('Помилка при надсиланні повідомлення'));
            Sentry.captureException(err);
        });
    }

    const onEditComplete = (message) => {
        setInEdit(undefined);
        if (message) {
            dispatch(updateMessage(message));
        }
    };

    const findUser = (id) => {
        return operators.find((u) => u.id === id);
    };

    const showAvatar = (dialogPart, message, index) => {
        return (
            index === 0 ||
            dialogPart[index - 1].from !== message.from ||
            (dialogPart[index - 1].messageContext &&
                message.messageContext &&
                dialogPart[index - 1].messageContext.personId !== message.messageContext.personId)
        );
    };

    const showPrevMessages = () => {
        const newPagination = {
            skip: pagination.skip + 50,
            limit: pagination.limit,
        };
        setInHistory(true);
        dispatch(fetchMessages({ roomId, pagination: newPagination, extra: { append: true } }));
        dispatch(setChatPagination(newPagination));
        dispatch(resetPagination(newPagination));

    };


    /** Effects */

    useEffect(() => {
        if (inSight) {
            dispatch(readNew());
            dispatch(updateLastSeenOp({ roomId: roomId, time: new Date() }));
            setShowMoreButton(false);
            setInHistory(false);
        } else {
            const lastMessage = messages[0];
            if (lastMessage) {
                if (lastMessage.from !== operatorId) setShowMoreButton(true);
            }
        }
    }, [inSight, dispatch, messages, operatorId, roomId])

    useEffect(() => {
        if (activeRoomId && socketConnected) {
            setTimeout(() => {
                dispatch(fetchMessages({ roomId: activeRoomId }));
                dispatch(fetchRoomInfo(activeRoomId));
            }, 100);
        }
    }, [activeRoomId, dispatch, socketConnected]);

    useEffect(() => {
        _scroll();
    }, [activeRoomId, _scroll]);

    useEffect(() => {
        /** User scroll back history - do nothing */
        if (inHistory) return;
        if (!loading) {
            if (messages.length === 0) return;

            /** Scroll on own message */

            // !TODO check if message just sended and only then scroll down
            // const lastMessage = messages[messages.length - 1];
            // if (lastMessage) {
            //     if (lastMessage.from === operatorId) _scroll();
            // }

            /** Scroll if in sight*/
            if (inSight) _scroll();
        }
    }, [_scroll, messages, loading, inHistory, inSight]);

    /** Renders */

    const title = () => {
        if (personal) return people.find((p) => p._id !== operatorId)?.name ?? t('Персольний чат');
        return name ? name : `${t('Чат від')} ${moment(created).format('DD-MM')}`;
    };

    if (!activeRoomId) {
        return (
            <div className={`d-flex flex-column chat-dialog-section chat-jr-step-3`}>
                <div
                    className="d-lg-none chat-mobile-navigation px-0"
                    onClick={() => dispatch(changeMobileState(MobileChatStates.LIST))}
                >
                    <i className="la la-angle-left la-lg"></i>
                    {t('До чатів')}
                </div>
                <div key={uuid()} className={s.noActiveChats}>
                    {t('Оберіть чат')}
                </div>
            </div>
        );
    }

    return (
        <div className={`d-flex flex-column chat-dialog-section chat-jr-step-3`}>
            <div
                className="d-lg-none chat-mobile-navigation px-0"
                onClick={() => dispatch(changeMobileState(MobileChatStates.LIST))}
            >
                <i className="la la-angle-left la-lg"></i>
                {t('До чатів')}
            </div>

            {roomId && (
                <header className={s.chatDialogHeader}>
                    <div>
                        <h5 className="fw-normal mb-0">
                            {title()}&nbsp;
                            {roomData && roomData.domain && (
                                <Badge pill color="success">
                                    {roomData.domain}
                                </Badge>
                            )}
                        </h5>
                        <OnlineStatus user={{ isOnline: live }} />
                    </div>
                    {/* <i
                        className={`${s.infoIcon} la la-ellipsis-v d-none d-xl-block`}
                        onClick={() => dispatch(changeMobileState(MobileChatStates.INFO))}
                    ></i> */}
                    <i
                        className={`${s.infoIcon} la la-ellipsis-v d-xl-none`}
                        onClick={() => dispatch(changeMobileState(MobileChatStates.INFO))}
                    ></i>
                </header>
            )}

            <ChatDrop onDrop={onDrop} onDropMessage={onDropMessage}>
                <div className={s.chatDialogBody} ref={chatDialogBodyRef} id="messagesList">
                    <ChatTyping scrolUp={_scroll} />
                    <div ref={ref}></div>
                    {messages.map((message, j) => (
                        <ChatMessage
                            user={message.from === user.id ? user : findUser(message.userId)}
                            owner={message.from === operatorId && message.kind !== 'action'}
                            size={40}
                            showStatus={false}
                            key={message._id}
                            message={message}
                            showAvatar={showAvatar(messages, message, j)}
                            inEdit={message._id === messageInEdit}
                            onEditComplete={onEditComplete}
                            setImageFullscreen={setImageFullscreen}
                            setWordPreview={setWordPreview}
                            userData={user}
                            operators={operators}
                            seen={new Date(lastSeenTimeClient) >= new Date(message.created)}
                            last={j + 1 === messages.length}
                            hasOffline={hasOffline}
                        />
                    ))}
                    {gotOlder && (
                        <div className="cursor-pointer text-center mt-3 mb-3" onClick={showPrevMessages}>
                            {t('Показати попередні')}
                        </div>
                    )}
                </div>
            </ChatDrop>

            {(showMoreButton && !messageInEdit) && (
                <div className="text-center" style={{ position: 'relative' }}>
                    <Button color="info" className={s.newMessagesBtn} onClick={_scroll}>
                        {hasNew && (<><Badge color={'danger'}>new</Badge>&nbsp;&nbsp;</>)}{t('Останні')}
                    </Button>
                </div>
            )}

            {roomId && <NewMessage chat={chat} />}

            <Menu id={MENU_ID} className={s.ContextMenu}>
                <Item id="copyMessage" onClick={handleItemClick}>
                    {t('Копіювати')}
                </Item>
                <Item id="editMessage" onClick={handleItemClick}>
                    {t('Редагувати')}
                </Item>
                <Item id="deleteMessage" onClick={handleItemClick}>
                    {t('Видалити')}
                </Item>
                {person && person.email && (
                    <Item id="createTicket" onClick={handleItemClick}>
                        {t('Сворити тікет')}
                    </Item>
                )}
                {canSendSms && person && person.phone && person.phone !== '' && (
                    <Item id="sendSms" onClick={handleItemClick}>
                        {t('Надіслати по СМС')}
                    </Item>
                )}
            </Menu>

            {imageFullscreen && <Lightbox image={imageFullscreen} onClose={() => setImageFullscreen()} />}
            {wordPreview && <DocPreviewModal docUrl={wordPreview} setWordPreview={setWordPreview} />}
        </div>
    );
};

export default ChatDialog;
