import React from 'react'
import {get} from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import s from './ChatDialog.module.scss';

const ChatTyping = ({ scrolUp }) => {
    const { t } = useTranslation('chat');

    const typing = useSelector((state) => get(state, 'room.typing'));

    // useEffect(() => {
    //     if (typing) {
    //         if (typeof scrolUp === 'function') scrolUp();
    //     }
    // }, [typing, scrolUp]);

    if (!typing || typing.text === '') return null;

    return (
        <div className={s.typing}>
            {t('Користувач набирає повідомлення')}:
            <br />
            {typing.text}
        </div>
    );
}

export default ChatTyping
