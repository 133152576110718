import socketIOClient from 'socket.io-client';
import config from '../../config';
import { addNotification, setChatUser, setUsers } from '../actions/chat';
import { appendMessage, incrementCounter, updateTicket } from '../tickets/actions';
import { FETCH_TICKETS, INVITE_TICKET_OPERATOR, MARK_MESSAGE_READED } from '../tickets/types';

export const CONNECT_TO_CRM_SOCKET = 'connect_crm_socket';
export const ADD_REGULAR_NOTIFICATION = 'add_regular_notification';
export const DISCONNECT_CRM_SOCKET = 'disconnect_crm_socket';

export function openCrmSocket() {
    return {
        type: CONNECT_TO_CRM_SOCKET,
    };
}

export let socket;

const CrmSocketMiddleware = (store) => {
    const ping = () => {
        try {
            const audio = new Audio('/quite-impressed-565.mp3');
            audio.play().catch((err) => {});
        } catch (error) {}
    };

    const setCrmSocket = () => {
        if (socket) {
            return;
        }
        const {
            chat: {
                user: { operatorId, clientId, id: userId, domains },
            },
        } = store.getState();

        const nspOperator = `/s/op/${clientId}`;
        socket = socketIOClient(`${config.baseURLApi}${nspOperator}`, {
            query: {
                nsp: nspOperator,
                operatorId,
            },
            transports: ['websocket'],
        });

        socket.on('connect', () => {});

        socket.on('new_ticket', (data) => {
            if (domains && !domains.includes(data.domainName)) return;

            ping();
            data.type = 'newTicket';
            store.dispatch(
                addNotification({
                    text: `Прийшов новий запит. Користувач: ${data.email}`,
                    tag: 'new-ticket',
                    title: `Новий тікет (${data.domainName})`,
                    type: 'newTicket',
                    data,
                })
            );
        });

        socket.on('ticket_update', (ticket) => {
            store.dispatch(updateTicket(ticket));
        });

        socket.on('new_ticket_message', (data) => {
            if (data.userId !== userId) {
                store.dispatch(incrementCounter());
            }

            store.dispatch(appendMessage(data));
        });

        socket.on('ticket_invite', (data) => {
            ping();
            
            store.dispatch(
                addNotification({
                    text: `Вас запрошено до розмови`,
                    tag: 'new-ticket',
                    title: `Запрошення до заявки ${data.id} (${data.domainName})`,
                    data,
                })
            );
        });

        socket.on('user_list', (data) => {
            store.dispatch(setUsers(data));
            const current = data.find(u => u.id === userId);
            if (current) {
                store.dispatch(setChatUser(current))
            }
        });        
    };

    return (next) => (action) => {
        switch (action.type) {
            case FETCH_TICKETS:
                socket.emit('entity', action.payload);
                break;
            case MARK_MESSAGE_READED:
                socket.emit('mark_message_readed', action.payload);
                break;
            case CONNECT_TO_CRM_SOCKET:
                setCrmSocket();
                break;
            case INVITE_TICKET_OPERATOR:
                socket.emit('invite_op_to_ticket', action.payload);
                break;
            case DISCONNECT_CRM_SOCKET:
                if (!socket) return;
                socket.disconnect();
                socket = null;
                break;
            default:
                break;
        }
        return next(action);
    };
};

export default CrmSocketMiddleware;
