import Axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

const useCountries = () => {
    const [countries, setCountries] = useState([]);
    const [ready, setReady] = useState(false);

    const fetchCountries = useCallback(async () => {
        try {
            const { data } = await Axios.get('/countries');
            setCountries(data)
        } catch (error) {
            console.log(error);
        }
        setReady(true);
    }, [setCountries])

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    return [countries, ready]
}

export default useCountries
