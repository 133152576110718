export const Statuses = {
    INIT: 'init',
    INWORK: 'inWork',
    RESOLVED: 'resolved',
    POSTPONED: 'postponed',
    REJECTED: 'rejected',
    MERGED: 'merged',
};

export const ticketStatusOptions = (t) => [
    { value: Statuses.INIT, label: t('Відкрито') },
    { value: Statuses.INWORK, label: t('В роботі') },
    { value: Statuses.RESOLVED, label: t('Завершено') },
    { value: Statuses.POSTPONED, label: t('Відкладено') },
    { value: Statuses.REJECTED, label: t('Відхилено') },
    { value: Statuses.MERGED, label: t(`Об'єданано`) },
];

export const statusName = (status, t) => {
    switch (status) {
        case Statuses.INIT:
            return t('Відкрито');
        case Statuses.INWORK:
            return t('В роботі');
        case Statuses.RESOLVED:
            return t('Завершено');
        case Statuses.POSTPONED:
            return t('Відкладено');
        case Statuses.REJECTED:
            return t('Відхилено');
        case Statuses.MERGED:
            return t(`Об'єданано`);
        default:
            return '';
    }
};

export const statusColor = (status) => {
    switch (status) {
        case Statuses.INIT:
            return 'danger';
        case Statuses.INWORK:
            return 'info';
        case Statuses.RESOLVED:
            return 'success';
        case Statuses.POSTPONED:
            return 'warning';
        case Statuses.REJECTED:
            return 'warning';
        case Statuses.MERGED:
            return 'success';
        default:
            return '';
    }
};

export const statusColorCode = (status) => {
    switch (status) {
        case Statuses.INIT:
            return '#FD5F00';
        case Statuses.INWORK:
            return '#1A86D0';
        case Statuses.RESOLVED:
            return '#21AE8C';
        case Statuses.POSTPONED:
            return '#FDA700';
        case Statuses.REJECTED:
            return '#FDA700';
        case Statuses.MERGED:
            return '#21AE8C';
        default:
            return '';
    }
};

export const selectStatusStyles = {
    option: (provided, { data }) => {
        const color = statusColorCode(data.value);
        return {
            ...provided,
            fontWeight: 'normal',
            ':hover': {
                backgroundColor: color,
                color: '#fff',
            },
            ':active': {
                backgroundColor: color,
                color: '#fff',
            },
        };
    },

    singleValue: (provided, { data }) => {
        // const color = statusColorCode(data.value);
        return {
            ...provided,
            fontWeight: 'normal',
            // backgroundColor: color,
            color: '#fff',
            padding: '0.5rem 0rem',
            // borderRadius: '0.3rem',
        };
    },
    indicatorSeparator: (provided, state) => {
        return {
            ...provided,
            // backgroundColor: '#FFF',
        };
    },
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0px 9px',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        // padding: '5px',
        // color: '#FFF'
    }),
    control: (provided) => {
        return {
            ...provided,
            border: '1px solid #c1ccd3',
        };
    },
};

export const selectStatusStylesInner = {
    ...selectStatusStyles,
    control: (provided, state) => {
        const selected = state.getValue()[0];
        let color = '';
        if (state.getValue()[0]) {
            color = statusColorCode(selected.value);
        }

        return {
            ...provided,
            border: '1px solid #c1ccd3',
            height: 'calc(1.5em + 0.75rem + 2px)',
            minHeight: 'calc(1.5em + 0.75rem + 2px)',
            backgroundColor: color,
            color: '#FFF',
        };
    },
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: '5px',
        color: '#FFF',
        ':hover': {
            color: '#fff',
        },
    }),
    singleValue: (provided, { data }) => {
        return {
            ...provided,
            color: '#fff',
            padding: '0.5rem 0rem',
        };
    },
    clearIndicator: (provided) => {
        return {
            ...provided,
            padding: '5px',
            color: '#FFF',
        };
    },
};
