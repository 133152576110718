import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFocus } from '../redux/actions/settings';

const reloadInterval = 600000;

const WindowStateHandler = () => {
  const [inBackground, setInBackground] = useState(document.hidden);

  const dispatch = useDispatch();

  const onFocus = useCallback(
    () => {
      setInBackground(false);
      dispatch(setFocus(true));
    },
    [setInBackground, dispatch],
  )

  const onBlur = useCallback(
    () => {
      setInBackground(true);
      dispatch(setFocus(false));
    },
    [setInBackground, dispatch],
  )

  useEffect(() => {
    if (inBackground) {
      setTimeout(() => {
        if (document.hidden) {
          window.location.reload();
        }
      }, reloadInterval);
    }
  }, [inBackground]);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [onFocus, onBlur]);

  return <></>;
};

export default WindowStateHandler;