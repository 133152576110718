import axios from 'axios';
import { toast } from 'react-toastify';
import { receiveToken } from './user';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_FINALIZE = 'REGISTER_FINALIZE';
export const SET_SHOW_INIT_MODAL = 'SET_SHOW_INIT_MODAL';
export const SET_IN_PROGRESS = 'set_in_progress';

export function setInProgress() {
    return {
        type: SET_IN_PROGRESS,
    };
}

export function requestRegister() {
    return {
        type: REGISTER_REQUEST,
    };
}

export function receiveRegister() {
    return {
        type: REGISTER_SUCCESS
    };
}

export function registerError(payload) {
    return {
        type: REGISTER_FAILURE,
        payload,
    };
}

export function finalizeRegister() {
    return {
        type: REGISTER_FINALIZE
    };
}

export function setShowInitModal(payload) {
    return {
        type: SET_SHOW_INIT_MODAL,
        payload
    }
}

export function registerUser(payload) {
    return (dispatch) => {
        dispatch(requestRegister());
        const creds = payload.creds;
        if (creds.email.length > 0 && creds.password.length > 0 && creds.clientId.length > 0) {
            axios.post("/auth/signup", creds).then(res => {
                dispatch(receiveRegister());
                const token = res.data.token;
                dispatch(receiveToken(token));
                toast.success("Реєстрація пройшла успішно!");
            }).catch(err => {
                dispatch(registerError(err.response.data.msg));
            })
        } else {
            dispatch(registerError('Помилка при реєстрації'));
        }
    };
}

export function registerClient(payload) {
    return async (dispatch) => {
        dispatch(requestRegister());
        if (payload.email.length > 0 && payload.password.length > 0) {
            axios.post("/auth/register", payload).then(res => {
                dispatch(receiveRegister());
                
                const token = res.data.token;
                dispatch(receiveToken(token));
                toast.success("Реєстрація пройшла успішно!");
            }).catch(err => {
                dispatch(registerError(err.response.data.msg));
            })
        } else {
            dispatch(registerError('Помилка при реєстрації'));
        }
    };
}
