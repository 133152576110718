import * as Sentry from "@sentry/react";
import Axios from 'axios';
import * as moment from 'moment';
import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import uuid from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import { Statuses } from '../data/TicketStatuses';
import LabelsView from './LabelsView';
import TicketStatus from './TicketStatus';

const TicketItem = ({ ticket, onClick, agents, userId }) => {
    const { t } = useTranslation('tickets');
    const { agents: ticketAgents, label, messages, status } = ticket;

    const getLastMessage = () => {
        return messages.slice(-1)[0];
    };

    const hasUnreaded = () => {
        if (!messages || messages.length === 0) return false;

        const ticketMessage = getLastMessage();
        return ticketMessage.readedBy ? ticketMessage.readedBy.indexOf(userId) === -1 : false;
    };

    const lastMessageOut = () => {
        if (!messages || messages.length === 0) return false;
        const ticketMessage = getLastMessage();
        return ticketMessage.from === 'agent';
    };

    const changeStatus = async (status) => {
        try {
            await Axios.patch(`/entity/Ticket/${ticket.id}`, { status });
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    /**
     * Render functions
     */

    const _renderTags = () => {
        if (!label) return null;

        return (
            <Col sm={12} lg={4}>
                <LabelsView labels={label} showTitle={false} editable={false} />
            </Col>
        );
    };

    const _outputText = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const text = doc.body.textContent || '';
        return text.length > 100 ? text.slice(0, 100) + '...' : text;
    };

    const _ticketLastMessage = () => {
        if (!messages || messages.length === 0) return t('Нема текстового повідомленя');

        const ticketMessage = getLastMessage();
        if (!ticketMessage.message) return t('Нема текстового повідомленя');

        return <>{_outputText(ticketMessage.message)}</>;
    };

    const _renderReadedMark = () => {
        return hasUnreaded() ? (
            <Col sm={12} lg={4}>
                <Badge color="success">{t('Нові повідомленя')}</Badge>{' '}
            </Col>
        ) : null;
    };

    const _renderTicketInfo = () => {
        if (status === Statuses.RESOLVED) return null;

        return (
            <>
                <Row className="mt-3">
                    {_renderReadedMark()}
                    {_renderAgents()}
                    {_renderTags()}
                </Row>
            </>
        );
    };

    const _renderDirection = () => {
        return lastMessageOut() ? (
            <i className="las text-success la-chevron-up la-lg"></i>
        ) : (
            <i className="las text-danger la-chevron-down la-lg"></i>
        );
    };

    const _renderAgents = () => {
        if (!ticketAgents || ticketAgents.length === 0) return null;

        return (
            <Col sm={12} lg={4}>
                {ticketAgents.map((agent) => {
                    const found = agents.find((item) => String(item.id) === String(agent));
                    return (
                        <Badge className="mr-1" color="secondary" key={uuid()}>
                            {found ? found.name : agent}
                        </Badge>
                    );
                })}
            </Col>
        );
    };

    return (
        <div className="p-4" onClick={onClick}>
            <div className="d-flex flex-row flex-wrap justify-content-between">
                <div className="mr-2 d-flex align-items-baseline">
                    <div className="mr-2">{_renderDirection()}</div>
                    <TicketStatus
                        status={ticket.status}
                        ticketId={ticket.id}
                        onStatusChange={changeStatus}
                    ></TicketStatus>
                </div>
                <div className="ml-sm-auto d-flex">{moment(ticket.updatedAt).format('DD.MM.YYYY, HH:mm')}</div>
                <div className="d-flex flex-column ml-sm-auto mt-2 mt-sm-0 overflow-auto w-sm-100">
                    <span>{ticket.userName ? <strong>{ticket.userName}</strong> : null}</span>
                    <span>{ticket.email}</span>
                </div>
            </div>
            <hr />

            <Row className="mt-2">
                <Col xs="auto">
                    <span className={'font-weight-bold'}>{ticket.topic}</span>
                </Col>
                <Col  xs="auto" className="text-muted">{_ticketLastMessage()}</Col>
            </Row>
            {_renderTicketInfo()}
        </div>
    );
};

export default TicketItem;
