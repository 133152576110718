import React, { memo } from 'react';
import uuid from 'uuid/v4';
import s from './ChatMessage.module.scss';

const ChatMessageAttachment = memo(({ attachment, setImageFullscreen, owner, setWordPreview }) => {
    const _renderAttachment = (attachment) => {
        const { type } = attachment;
        if (type === 'image')
            return (
                <div style={{ minWidth: 282, width: '100%', height: 'auto' }}>
                    <img
                        src={attachment.url}
                        alt={attachment.filename}
                        onClick={() => setImageFullscreen(attachment.url)}
                        className="img-thumbnail"
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            );

        const docRegex = /^.*\.(docx|xlsx|doc|xls)$/i;

        if (docRegex.test(attachment.filename)) {
            return (
                <a
                    href={attachment.url}
                    style={{ padding: 6, color: owner ? '#FFF' : '#495057', cursor: 'pointer' }}
                    onClick={(e) => {
                        e.preventDefault();
                        setWordPreview(attachment.url);
                    }}
                >
                    {attachment.originalname}
                </a>
            );
        }

        return (
            <a
                href={attachment.url}
                target="_blank"
                rel="noreferrer"
                style={{ padding: 6, color: owner ? '#FFF' : '#495057' }}
            >
                {attachment.originalname}
            </a>
        );
    };

    return (
        <div key={uuid()} className={`${s.messageBody} ${s.messageAttachment}`}>
            {_renderAttachment(attachment)}
        </div>
    );
});

export default ChatMessageAttachment;