import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DocRenderer from './DocRenderer';
import { useTranslation } from 'react-i18next';

const DocPreviewModal = ({ docUrl, setWordPreview }) => {
    const { t } = useTranslation('files');

    return (
        <Modal
            size="xl"
            centered
            isOpen={typeof docUrl === 'string'}
            toggle={() => setWordPreview()}
            id="preview-close-modal"
        >
            <ModalHeader toggle={() => setWordPreview()} id="news-close-modal-label">
                {t('Перегляд файлу')}
            </ModalHeader>
            <ModalBody className="bg-white" style={{ height: 600 }}>
                <DocRenderer docUrl={docUrl} />
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={() => setWordPreview()} data-dismiss="modal">
                    {t('Скасувати')}
                </Button>
                <Button
                    color="success"
                    tag={'a'}
                    href={docUrl}
                    target="_blank"
                    rel="noreferrer"
                    data-dismiss="modal"
                >
                    {t('Завантажити файл')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DocPreviewModal;
