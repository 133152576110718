import React from 'react';
import { generatePath, Redirect, Switch, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import ListGroup from 'reactstrap/lib/ListGroup';
import { RouteWithSubRoutes } from './Layout';



function SubmenuRoute(props) {
    const { routes, location } = props;

    const params = useParams();

    const _renderLink = (route, i) => {
        const classes = ['list-group-item', 'list-group-item-action'];
        if (location.pathname === generatePath(route.path, params)) classes.push('active');
        return <Link key={`subroutmenu${i}`} className={classes.join(' ')} to={generatePath(route.path, params)}>{route.breadcrumb}</Link>;
    }

    return (
        <Row style={{ minHeight: '100vh', paddingLeft: 20 }}>
            <Col sm={3} style={{ backgroundColor: '#FFF', flex: 1, justifyContent: 'center', disply: 'flex', padding: '20px' }}>
                <ListGroup flush>
                    {routes.map(_renderLink)}
                </ListGroup>

            </Col>
            <Col sm={9}>
                <Switch>
                    {routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                    {routes.length > 0 && (<Redirect from="*" to={generatePath(routes[0].path, params)} />)}                    
                </Switch>
            </Col>
        </Row>
    )
}


export default SubmenuRoute

