import * as Sentry from '@sentry/react';
import Axios from 'axios';
import { ChonkyActions, ChonkyIconName, defineFileAction, FileHelper, FullFileBrowser } from 'chonky';
import React, {
    forwardRef, useCallback,
    useEffect, useImperativeHandle, useMemo,
    useRef,
    useState
} from 'react';
import Lightbox from 'react-awesome-lightbox';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { ChatDrop } from '../../pages/chat/components/ChatDialog/ChatDrop';
import Sharer from '../Sharer';
import { ukI18n as i18n } from './locales/uk';

const FileBrowser = forwardRef(({ fileOpenAction, selectFilesAction, embedded }, ref) => {
    const { t } = useTranslation('files');
    const history = useHistory();
    const { search } = useLocation();

    const [fileBrowser, setFileBrowser] = useState({
        files: [],
        folderChain: [{ id: 'root', name: 'Сховище', isDir: true }],
    });
    const [currentDir, setCurrentDir] = useState(() => {
        if (search) {
            const q = new URLSearchParams(search);
            return q.has('dir') ? q.get('dir').split(',') : [];
        }
        return [];
    });
    const [prevDir, setprevDir] = useState([]);
    const [imageFullscreen, setImageFullscreen] = useState();
    const [sharerOpen, setSharerOpen] = useState(false);
    const [shareLink, setShareLink] = useState();

    const inputFile = useRef(null);
    const fileBrowserRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getFileSelection: () => {
            const files = Array.from(fileBrowserRef.current.getFileSelection() ?? []);
            return files.map(fileId => fileBrowser.files.find(file => file.id === fileId));
        },
    }));

    /** Actions */
    const fetchDir = useCallback(async () => {
        const { data } = await Axios.get(`/filebrowser`, {
            params: { currentDir: currentDir.join('/') },
        });
        setFileBrowser(data);
    }, [currentDir]);

    const createFolder = useCallback(
        async (folderName) => {
            try {
                await Axios.post(
                    `/filebrowser/folder`,
                    { folderName },
                    {
                        params: { currentDir: currentDir.join('/') },
                    }
                );
                await fetchDir();
                toast.success(t('Папку створено'));
            } catch (error) {
                toast.error(t('Помилка під час створення папки'));
            }
        },
        [currentDir, fetchDir, t]
    );

    const deleteFile = useCallback(
        async (fileName) => {
            try {
                await Axios.delete(`/filebrowser/file`, {
                    params: { currentDir: currentDir.join('/'), fileName },
                });
                await fetchDir();
                toast.success(t('Файл видалено'));
            } catch (error) {
                toast.error(t('Помилка під час видалення файлу'));
            }
        },
        [currentDir, fetchDir, t]
    );

    const openFileDialog = useCallback(() => {
        inputFile.current.click();
    }, [inputFile]);

    const handleFileChange = useCallback(
        async (e) => {
            if (e.target.files.length > 0) {
                const formData = new FormData();
                for (const file of e.target.files) {
                    formData.append('browserFile', file);
                }
                await Axios.post(`/filebrowser/file`, formData, {
                    params: { currentDir: currentDir.join('/') },
                });
                await fetchDir();
            }
        },
        [fetchDir, currentDir]
    );

    const handleShare = useCallback(async (path) => {
        try {
            const { data: shortLink } = await Axios.post(`/s/get_short`, { path });
            setShareLink(shortLink);
            setSharerOpen(true);
        } catch (error) {
            Sentry.captureException(error);
        }
    }, []);

    const shareFileAction = defineFileAction({
        id: 'share_file',
        requiresSelection: true,
        hotkeys: ['ctrl+s'],
        button: {
            name: 'Share',
            toolbar: false,
            contextMenu: true,
            icon: ChonkyIconName.share,
        },
    });

    const handleFileAction = useCallback(
        (data) => {
            if (data.id === ChonkyActions.OpenFiles.id) {
                const { targetFile, files } = data.payload;
                const fileToOpen = targetFile ?? files[0];
                if (fileToOpen) {
                    if (FileHelper.isDirectory(fileToOpen)) {
                        const idx = fileBrowser.folderChain.findIndex((i) => i.id === fileToOpen.id);
                        if (idx !== -1) {
                            setCurrentDir([...fileBrowser.folderChain.slice(1, idx + 1).map((i) => i.name)]);
                        } else {
                            setCurrentDir([...currentDir, fileToOpen.name]);
                        }
                    } else {
                        if (typeof fileOpenAction === 'function') {
                            fileOpenAction(files);
                        } else {
                            if (fileToOpen.previewable) {
                                setImageFullscreen(fileToOpen.thumbnailUrl);
                            } else {
                                const newWindow = window.open(
                                    fileToOpen.url,
                                    '_blank',
                                    'noopener,noreferrer'
                                );
                                if (newWindow) newWindow.opener = null;
                            }
                        }
                    }
                    return;
                }
            } else if (data.id === ChonkyActions.DeleteFiles.id) {
                for (const file of data.state.selectedFilesForAction) {
                    deleteFile(file.name);
                }
            } else if (data.id === ChonkyActions.UploadFiles.id) {
                openFileDialog();
            } else if (data.id === ChonkyActions.MoveFiles.id) {
            } else if (data.id === ChonkyActions.ChangeSelection.id) {
                if (typeof selectFilesAction === 'function') {
                    console.log(data.state.selectedFilesForAction);
                    
                }
                // return;
            } else if (data.id === ChonkyActions.CreateFolder.id) {
                const folderName = prompt(t('Введіть назву теки:'));
                if (folderName) {
                    createFolder(folderName);
                }
            } else if (data.id === shareFileAction.id) {
                const { contextMenuTriggerFile } = data.state;
                handleShare(contextMenuTriggerFile.path);
            }
        },
        [
            currentDir,
            fileBrowser,
            createFolder,
            openFileDialog,
            deleteFile,
            fileOpenAction,
            selectFilesAction,
            handleShare,
            shareFileAction,
            t,
        ]
    );

    const onDrop = useCallback(
        async ({ files: dropped }) => {
            if (dropped.length > 0) {
                try {
                    const formData = new FormData();
                    for (const file of dropped) {
                        formData.append('browserFile', file);
                    }
                    await Axios.post(`/filebrowser/file`, formData, {
                        params: { currentDir: currentDir.join('/') },
                    });
                    await fetchDir();
                } catch (error) {
                    toast.error(t('Помилка при збережені файлів'));
                }
            }
        },
        [currentDir, fetchDir, t]
    );

    const fileActions = useMemo(
        () => [
            ChonkyActions.CreateFolder,
            ChonkyActions.DeleteFiles,
            // ChonkyActions.UploadFiles,
            shareFileAction,
        ],
        [shareFileAction]
    );

    /** Effect */

    useEffect(() => {
        if (embedded) return;

        if (prevDir.length < currentDir.length) {
            history.push({
                pathname: '/files',
                search: `?dir=${currentDir.join(',')}`,
            });
        }
        setprevDir(currentDir);
    }, [currentDir, history, prevDir, embedded]);

    useEffect(() => {
        if (embedded) return;

        return history.listen(() => {
            if (history.action === 'POP') {
                if (fileBrowserRef.current) {
                    fileBrowserRef.current.requestFileAction(ChonkyActions.OpenParentFolder);
                }
            }
        });
    }, [history, embedded]);

    useEffect(() => {
        fetchDir();
    }, [fetchDir, currentDir]);

    /**
     * Renders
     */
    return (
        <ChatDrop onDrop={onDrop} style={{ height: '100%' }}>
            <FullFileBrowser
                ref={fileBrowserRef}
                onFileAction={handleFileAction}
                files={fileBrowser.files}
                folderChain={fileBrowser.folderChain}
                i18n={i18n}
                fileActions={fileActions}
                disableDragAndDropProvider={true}
            />
            <input
                type="file"
                id="uploadFiles"
                multiple
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            {imageFullscreen && <Lightbox image={imageFullscreen} onClose={() => setImageFullscreen()} />}
            <Sharer link={shareLink} open={sharerOpen} setOpen={setSharerOpen} />
        </ChatDrop>
    );
});

export default FileBrowser;
