import React from 'react'

const MenuIcon = ({ maskId = '123' }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.29024 5H16.7094C17.1444 5 17.5002 5.35583 17.5002 5.79083V5.87583C17.5002 6.31083 17.1444 6.66667 16.7094 6.66667H3.29024C2.85524 6.66667 2.50024 6.31083 2.50024 5.87583V5.79083C2.50024 5.35583 2.85524 5 3.29024 5ZM16.7094 9.16667H3.29024C2.85524 9.16667 2.50024 9.5225 2.50024 9.9575V10.0425C2.50024 10.4775 2.85524 10.8333 3.29024 10.8333H16.7094C17.1444 10.8333 17.5002 10.4775 17.5002 10.0425V9.9575C17.5002 9.5225 17.1444 9.16667 16.7094 9.16667ZM16.7094 13.3333H3.29024C2.85524 13.3333 2.50024 13.6892 2.50024 14.1242V14.2092C2.50024 14.6442 2.85524 15 3.29024 15H16.7094C17.1444 15 17.5002 14.6442 17.5002 14.2092V14.1242C17.5002 13.6892 17.1444 13.3333 16.7094 13.3333Z" fill="currentColor"/>
        <mask id={`mask${maskId}`} mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="5" width="16" height="10">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.29024 5H16.7094C17.1444 5 17.5002 5.35583 17.5002 5.79083V5.87583C17.5002 6.31083 17.1444 6.66667 16.7094 6.66667H3.29024C2.85524 6.66667 2.50024 6.31083 2.50024 5.87583V5.79083C2.50024 5.35583 2.85524 5 3.29024 5ZM16.7094 9.16667H3.29024C2.85524 9.16667 2.50024 9.5225 2.50024 9.9575V10.0425C2.50024 10.4775 2.85524 10.8333 3.29024 10.8333H16.7094C17.1444 10.8333 17.5002 10.4775 17.5002 10.0425V9.9575C17.5002 9.5225 17.1444 9.16667 16.7094 9.16667ZM16.7094 13.3333H3.29024C2.85524 13.3333 2.50024 13.6892 2.50024 14.1242V14.2092C2.50024 14.6442 2.85524 15 3.29024 15H16.7094C17.1444 15 17.5002 14.6442 17.5002 14.2092V14.1242C17.5002 13.6892 17.1444 13.3333 16.7094 13.3333Z" fill="white"/>
        </mask>
        <g mask={`url(#mask${maskId})`}>
        <rect width="20" height="20" fill="currentColor"/>
        </g>
    </svg>    
)


export default MenuIcon