import * as Sentry from "@sentry/react";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import EntityTable from '../../components/EntityTable/EntityTable';
import Filters from '../../components/Filters/Filters';
import TopTableRow from '../../components/Filters/TopTableRow';
import Widget from '../../components/Widget/Widget';
import { deleteEntity, patchEntity, postEntity } from '../../utils/requests';
import DeleteButton from './DeleteButton';
import s from './EntityView.module.scss';


const EntityView = (
    {
        title,
        viewName,
        filtersConfig,
        entity,
        columns,
        backend,
        keyField,
        editView,
        newText,
        editText,
        editValidator,
        afterSave,
        afterDelete,
        newAction,
        fetchPath,
        customFetchParams,
        showAdd = true,
        showHelp = true,
        deleteWarningText
    },
    ref
) => {
    const { t } = useTranslation('common');
    const [filters, setFilters] = useState({});
    const [filterPanelOpen, setFilterPanelOpen] = useState(false);

    const [entityToEdit, setEntityToEdit] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [deleteExecuted, setDeleteExecuted] = useState(false);
    const [popovers, setPopovers] = useState({});

    const tableRef = useRef();
    const scrollRef = useRef();

    const defaultFormatter = (moreContent) => {
        return (cell, row, rowIndex, { popovers, isDeleting, idToDelete }) => {
            return (
                <DeleteButton
                    popovers={popovers}
                    togglePopover={togglePopover}
                    isDeleting={isDeleting}
                    idToDelete={idToDelete}
                    additionalText={deleteWarningText}
                    moreContent={moreContent ? [moreContent(cell, row, rowIndex), <Button
                        color="info"
                        size="sm"
                        onClick={() => {
                            setEntityToEdit(row);
                            setShowEditModal(true);
                        }}
                    >
                        {t('Редагувати')}
                    </Button>] : <Button
                        color="info"
                        size="sm"
                        onClick={() => {
                            setEntityToEdit(row);
                            setShowEditModal(true);
                        }}
                    >
                        {t('Редагувати')}
                    </Button>}
                    rowId={row.id ?? row._id}
                    deleteItem={deleteItem}
                ></DeleteButton>
            );
        };
    };

    const editFormatter = (moreContent) => {
        return (cell, row) => {
            return (
                <>
                    {moreContent && moreContent(cell, row)}
                    <Button
                        color="info"
                        size="sm"
                        className="ml-1"
                        onClick={() => {
                            setEntityToEdit(row);
                            setShowEditModal(true);
                        }}
                    >
                        {t('Редагувати')}
                    </Button>
                </>
            );
        };
    }

    const deleteFormatter = (moreContent) => {
        return (cell, row, rowIndex, { popovers, isDeleting, idToDelete }) => {
            return (
                <DeleteButton
                    popovers={popovers}
                    togglePopover={togglePopover}
                    isDeleting={isDeleting}
                    idToDelete={idToDelete}
                    moreContent={moreContent ? moreContent(cell, row, rowIndex) : () => { }}
                    rowId={row.id ?? row._id}
                    deleteItem={deleteItem}
                    additionalText={deleteWarningText}
                ></DeleteButton>
            );
        };
    };

    useEffect(() => {
        if (deleteExecuted) {
            tableRef.current.fetchData();
            setDeleteExecuted(false);
        }
    }, [deleteExecuted, tableRef]);

    const togglePopover = (id) => {
        let newPopovers = { ...popovers };
        if (!popovers[id]) {
            newPopovers[id] = true;
        } else {
            newPopovers[id] = !popovers[id];
        }
        setPopovers(newPopovers);
    };

    const deleteItem = async (id) => {
        setIsDeleting(true);
        setIdToDelete(id);
        const result = await deleteEntity({ entity, id, backend });
        if (result) {
            setIsDeleting(false);
            setIdToDelete(null);
            setDeleteExecuted(true);
        }
        if (afterDelete) {
            afterDelete();
        }
    };

    const saveEntity = async (entityData) => {
        if (editValidator) {
            const validated = editValidator(entityData);
            if (!validated) {
                toast.error(t('Заповніть необхідні поля'));
                return;
            } else if (validated.errors) {
                toast.error(validated.errors.join('\n'));
                return;
            }
        }

        try {
            if (entityToEdit) {
                await patchEntity({
                    entity: entity,
                    id: entityToEdit._id ? entityToEdit._id : entityToEdit.id,
                    data: entityData,
                    backend: backend,
                });
            } else {
                await postEntity({
                    entity: entity,
                    data: entityData,
                    backend: backend,
                });
            }
            tableRef.current.fetchData();
            setShowEditModal(false);
            if (afterSave) {
                afterSave();
            }
        } catch (err) {
            Sentry.captureException(err);
        }
    };

    useImperativeHandle(ref, () => {
        return {
            fetchData() {
                tableRef.current.fetchData();
            },
            hideEditor() {
                setShowEditModal(false);
            },
        };
    });

    const onPageChange = (page) => {
        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={s.root} ref={scrollRef}>
            {/* <h1 className="page-title">{title}</h1> */}
            <Filters
                key={viewName + '-filters'}
                formName={viewName}
                filtersConfig={filtersConfig}
                isOpen={filterPanelOpen}
                close={() => setFilterPanelOpen(false)}
                filterChange={setFilters}
            ></Filters>
            <Widget>
                <TopTableRow
                    title={title}
                    showAdd={showAdd}
                    add={() => {
                        newAction ? newAction() : setShowEditModal(true);
                    }}
                    showFilter={true}
                    filter={() => {
                        setFilterPanelOpen(true);
                    }}
                ></TopTableRow>
                {columns && (
                    <EntityTable
                        ref={tableRef}
                        customFetchParams={customFetchParams}
                        columns={columns
                            .map((column) => {
                                if (column.customFormatter === 'edit') {
                                    const newColumn = {
                                        ...column,
                                        formatter: editFormatter(column.formatter),
                                    };
                                    delete newColumn.customFormatter;
                                    return newColumn;
                                }
                                if (column.customFormatter === 'delete') {
                                    const newColumn = {
                                        ...column,
                                        formatExtraData: { popovers, isDeleting, idToDelete, deleteItem },
                                        formatter: deleteFormatter(column.formatter),
                                    };
                                    delete newColumn.customFormatter;
                                    return newColumn;
                                }
                                if (column.customFormatter === 'default') {
                                    const newColumn = {
                                        ...column,
                                        formatExtraData: { popovers, isDeleting, idToDelete, deleteItem },
                                        formatter: defaultFormatter(column.formatter),
                                    };
                                    delete newColumn.customFormatter;
                                    return newColumn;
                                }
                                return column;
                            })
                            .map((column) => {
                                if (column.colType === 'action') {
                                    return Object.assign({}, column, {
                                        headerStyle: { ...column.headerStyle, textAlign: 'right', minWidth: 150 },
                                        style: { textAlign: 'right' }
                                    });
                                }
                                return column;
                            })}
                        entity={entity}
                        backend={backend}
                        keyField={keyField}
                        filters={filters}
                        fetchPath={fetchPath}
                        onPageChange={onPageChange}
                    ></EntityTable>
                )}
                <Modal
                    size="lg"
                    isOpen={showEditModal}
                    toggle={() => {
                        setShowEditModal(false);
                        setEntityToEdit(null);
                    }}
                >
                    <ModalHeader
                        toggle={() => {
                            setShowEditModal(false);
                            setEntityToEdit(null);
                        }}
                    >
                        {!entityToEdit
                            ? (newText
                                ? newText
                                : t('Нова відповідь'))
                            : (editText
                                ? editText
                                : t('Редагувати'))}
                    </ModalHeader>
                    {editView &&
                        editView({
                            entity: entityToEdit,
                            cancel: () => {
                                setShowEditModal(false);
                                setEntityToEdit(null);
                            },
                            save: saveEntity,
                        })}
                </Modal>
            </Widget>
        </div>
    );
};

export default forwardRef(EntityView);
