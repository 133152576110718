import React from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import s from './JoyrideLink.module.scss';
import { startJR } from '../../redux/actions/joyride';

const JoyrideLink = ({name}) => {
    const { t } = useTranslation('joyride');

    const dispatch = useDispatch();
    const jr = useSelector((state) => get(state, 'joyride.scenarios', null));

    if (!jr || !jr[name]) {
      return <div></div>
    }

    const start = () => {
      dispatch(startJR(name));
    }


    return (
      <div className={s.jrLink} onClick={start}>{t('Інструкція')}</div>
    )
};

export default JoyrideLink;
