import React, { useState } from 'react';
import { Col, Row, Badge } from 'reactstrap';
import uuid from 'uuid/v4';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

import styles from './TicketDetails.module.scss';

const TicketMessage = ({ message, clientTitle, setWordPreview }) => {
    const { t } = useTranslation('tickets');
    const [opened, setOpened] = useState(false);

    const sender = () => {
        if (message.from === 'client') {
            if (message.fromName || message.fromEmail)
                return <>{message.fromName ? message.fromName : message.fromEmail}</>;

            return clientTitle;
        }

        return message.user ? message.user.name : t('Оператор');
    };

    const hasAttachments = () => {
        return message && message.attachments && message.attachments.length > 0;
    };

    const trimText = (html, size = 250) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const text = doc.body.textContent || '';
        return text.length > size ? <>{text.slice(0, size)} {_ellipsis()}</> : text;
    };

    const _renderAttachments = () => {
        if (!hasAttachments()) return null;

        const _renderFile = file => {
            const docRegex = /^.*\.(docx|xlsx|doc|xls)$/i
            if (docRegex.test(file.filename)) {
                return <div key={uuid()}
                    className={
                        styles.attachmentsBlock +
                        (file.type === 'image' ? ' d-inline-block' : 'd-block')
                    }
                ><a
                    href={file.url}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                        e.preventDefault();
                        setWordPreview(file.url);
                    }}
                >
                        {opened ? (<>{
                            file.type === 'image' ? (
                                <div
                                    className={styles.attachmentImg}
                                    style={{ backgroundImage: `url(${file.url})` }}
                                ></div>
                            ) : (
                                <div className={styles.attachmentTitle}>{file.filename}</div>
                            )
                        }</>) : (<>{file.filename}</>)}
                    </a> </div>
            }

            return (
                <div 
                    key={uuid()}
                    className={
                        styles.attachmentsBlock +
                        (file.type === 'image' ? ' d-inline-block' : 'd-block')
                    }
                >
                    <a href={file.url} target="_blank" rel="noreferrer">
                        {opened ? (<>{
                            file.type === 'image' ? (
                                <div
                                    className={styles.attachmentImg}
                                    style={{ backgroundImage: `url(${file.url})` }}
                                ></div>
                            ) : (
                                <div className={styles.attachmentTitle}>{file.filename}</div>
                            )
                        }</>) : (<>{file.filename}</>)}
                    </a>
                </div>
            )
        }

        // if (opened)
        //     return (
        //         <>
        //             <hr />
        //             {message.attachments.map((file) => (
        //                 <div
        //                     key={uuid()}
        //                     className={
        //                         styles.attachmentsBlock +
        //                         (file.type === 'image' ? ' d-inline-block' : 'd-block')
        //                     }
        //                 >
        //                     <a href={file.url} target="_blank" rel="noreferrer">
        //                         {file.type === 'image' ? (
        //                             <div
        //                                 className={styles.attachmentImg}
        //                                 style={{ backgroundImage: `url(${file.url})` }}
        //                             ></div>
        //                         ) : (
        //                             <div className={styles.attachmentTitle}>{file.originalname}</div>
        //                         )}
        //                     </a>
        //                 </div>
        //             ))}
        //         </>
        //     );


        return (
            <>
                <hr />
                {message.attachments.map(_renderFile)}
            </>
        );
    };

    const _renderBody = () => {
        if (opened)
            return (
                <div
                    className={'fw-normal mt-3 ' + styles.messageText}
                    dangerouslySetInnerHTML={{ __html: message.message }}
                ></div>
            );

        return <div className={'fw-normal mt-3 ' + styles.messageText}>{trimText(message.message)}</div>;
    };

    /**
     * Renders
     */
    const _ellipsis = () => {
        return (
            <Badge className="my-2 cursor-pointer" pill color="light" onClick={() => setOpened(!opened)}>
                <i className="la la-ellipsis-h la-lg" /> {t('показати повідомлення повністю')} 
            </Badge>
        );
    };

    return (
        <div className="p-4">
            <div className={styles.ticketData}>
                <Row>
                    <Col
                        sm={12}
                        lg={6}
                        className={
                            message.from === 'agent' ? 'text-info fw-semi-bold' : 'text-success fw-semi-bold'
                        }
                    >
                        {sender()}
                    </Col>
                    <Col sm={12} lg={6} className="text-right">
                        {message && message.attachments && message.attachments.length > 0 && (
                            <i className="la la-paperclip mr-3 fw-bold"></i>
                        )}
                        <div className="text-right text-muted d-inline-block">
                            {moment(message.createdAt).format('DD.MM.YYYY, HH:mm')}
                        </div>
                        <span className={styles.expandIcon} onClick={() => setOpened(!opened)}>
                            <i className={opened ? 'la la-angle-up' : 'la la-angle-down'}></i>
                        </span>
                    </Col>
                </Row>
            </div>
            {_renderBody()}
            {_renderAttachments()}
        </div>
    );
};

export default TicketMessage;
