import React, { useState } from 'react';
import Avatar from '../Avatar';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Badge from 'reactstrap/lib/Badge';
import uuid from 'uuid/v4';

import people from '../../../../images/chat/people.svg';
import { addChat, setActiveChat } from '../../../../redux/actions/chat';
import s from './ChatList.module.scss';

const ChatListItem = (props) => {
    const { t } = useTranslation('chat');
    const {
        user: { operatorId },
        search,
    } = props;
    const [opId] = useState(operatorId);

    const { chat, isActive, users } = props;

    const time = () => {
        const messageTime = moment(chat.updated);
        if (messageTime.isSame(moment(), 'day')) {
            return messageTime.format('HH:mm');
        }
        return messageTime.format('D MMM');
    };

    const changeChat = async () => {
        if (search) {
            props.dispatch(addChat(chat));
            await props.dispatch(setActiveChat(chat));
        } else {
            await props.dispatch(setActiveChat(chat));
        }
    };

    const findUser = (id) => {
        return users.find((u) => u.id === id);
    };

    const chatUsers = () => {
        return props.chat.people.slice(0, 3);
    };

    const _renderName = () => {        
        if (chat.personal) {
            // return chat.person.name;
            // !FIXME Remove this interlucor shit finally
            const inter = chat.people.find((p) => p._id !== operatorId);
            if (inter) return inter.name;
        }

        return chat.name ?? `${t(`Чат від`)} ${moment(chat.created).format('DD-MM')}`;
    };

    const _renderSourceMark = () => {
        const messengers = {
            'web': 'las la-comment',
            'instagram': 'lab la-instagram',
            'messenger': 'lab la-facebook-messenger',
            'viber': 'lab la-viber',
            'telegram': 'lab la-telegram-plane'
        }

        return <div className={`${s.cirecleIcon} ${s[chat.channelId]}`}><i className={messengers[chat.channelId] || 'las la-comment'}></i></div>
    }    

    const _text = () => {
        if (chat.lastMessage.attachments?.length) return t('Прикріплений файл');
        if (chat.lastMessage.text === '') return t('Тексту нема');
        return chat.lastMessage.text;
    }

    return (
        <li onClick={changeChat} className={`${s.chatListItem} ${isActive ? s.active : ''}`}>
            <div className={`${s.chatListItemWrapper}`}>
                {!chat.isGroup ? (
                    props.showAvatar ? (
                        <div className={s.avatarWrap}>
                            <Avatar user={chat} size={35} className="mr-3" showStatus={true} />
                            {_renderSourceMark()}
                        </div>
                    ) : null
                ) : (
                    <ul className={s.avatarsColumn}>
                        {chatUsers().map((user) => (
                            <li key={uuid()}>
                                <Avatar
                                    user={user}
                                    size={35}
                                    showStatus={false}
                                    className="mr-3"
                                    stroke={true}
                                />
                            </li>
                        ))}
                    </ul>
                )}

                <section className={`${s.chatItemMain} ml-3`}>
                    <header className="d-flex align-items-center justify-content-between mb-1">
                        <h6 className={`${s.chatTitle}`}>
                            {chat.live ? (
                                <span className={`${s.live} ${s.dot}`}></span>
                            ) : (
                                <span className={`${s.dot}`}></span>
                            )}
                            {chat.isGroup ? (
                                <img alt="group" className={`${s.groupChatIcon} mr-1`} src={people} />
                            ) : null}
                            <span className='short-string' style={{width: 140}}>{_renderName()}</span>
                        </h6>
                        
                    </header>
                    <div className={`ml-auto ${s.timestamp}`}>
                        {time()}
                        {/* {props.chat.lastMessage ? props.chat.created : ''} */}
                    </div>
                    <div className="d-flex ">
                        {chat.lastMessage && (
                            <p className={`${s.chatLastMessage}`}>
                                {chat.lastMessage.from === opId && chat.lastMessage.kind !== 'action' ? (
                                    <span className={`${s.ownerIndicator} mr-1`}> Ви:</span>
                                ) : (
                                    ''
                                )}
                                {chat.lastMessage.from === opId &&
                                chat.lastMessage.kind !== 'action' &&
                                chat.isGroup ? (
                                    <span className={`${s.ownerIndicator} mr-1`}>
                                        {findUser(chat.lastMessage.userId)}
                                    </span>
                                ) : (
                                    ''
                                )}
                                {_text()}
                            </p>
                        )}
                        {chat.unreadedCount > 0 && (
                            <Badge color="danger" className="ml-auto">
                                {chat.unreadedCount}
                            </Badge>
                        )}
                        {chat.lastMessage && chat.hasUnreaded && chat.lastMessage.from !== opId && (
                            <Badge color="info" className="ml-2">
                                {t('Нові')}
                            </Badge>
                        )}
                        {chat.hasOffline && (
                            <Badge color="warning" className="ml-2">
                                {t('Офлайн')}
                            </Badge>
                        )}
                    </div>
                </section>
            </div>
        </li>
    );
};

const mapStateToProps = (state) => {
    return {
        activeChatId: state.chat.activeChatId,
        user: state.chat.user,
        users: state.chat.users,
        sendingMessage: state.chat.sendingMessage,
    };
};

export default connect(mapStateToProps)(ChatListItem);
