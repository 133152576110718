export const helpTexts = [
  { key: '/statistics', text: { en: '', ru: '', uk: 'На цій сторінці Ви можете бачити статистику викликів чата у вигляді графіка. Також, в таблиці відображено всі виклики в чаті та час в який вони були здійснені. В разі, якщо виклик було прийнято в табличці наведено ім\'я оператора, який прийняв виклик та час, в який його було прийнято. Також Ви можете подивитись історію кожного чата' } },
  { key: '/statistics/operators', text: { en: '', ru: '', uk: 'На цій сторінці відображено статистику спілкування операторів - кількість прийнятих запитів та унікальних користувачів, з якими розмовляв оператор' } },
  { key: '/statistics/clients', text: { en: '', ru: '', uk: 'На цій сторінці Ви можете бачити статистику користувачів - скільки запитів було надіслано кожним з них, час останнього запиту. Також, Ви можете переглянути історію спілкування для кожного користувача' } },
  { key: '/statistics/all', text: { en: '', ru: '', uk: 'На цій сторінці Ви можете бачити статистику викликів чата у вигляді графіка. Також, в таблиці відображено всі виклики в чаті та час в який вони були здійснені. В разі, якщо виклик було прийнято в табличці наведено ім\'я оператора, який прийняв виклик та час, в який його було прийнято. Також Ви можете подивитись історію кожного чата' } },
  { key: '/chat', text: { en: '', ru: '', uk: 'Головна сторінка для спілкування в чаті. Тут Ви можете бачити список активних чатів, переписку з користувачем та доступну інформацію про чат. Для того щоби почати спілкування, оберіть потрібний чат в списку, так напишіть повідомлення в полі знизу, натисніть "Надіслати". Зверніть увагу на вкладку "Автоматизація" біля інформації про користувача - вона допоможе Вам відповідати оперативніше, завдяки використанню готових фраз.' } },
  { key: '/history', text: { en: '', ru: '', uk: 'На цій сторінці відображено інформацію про всі чати, їхні канали, останню активність а також посилання на перегляд історії діалогу' } },
  { key: '/history/view/:id', text: { en: '', ru: '', uk: 'На цій сторінці відображено історію діалогу з користувачем, є можливість подивитися всю переписку або повідомлення в певні дати' } },
  { key: '/files', text: { en: '', ru: '', uk: 'Тут Ви можете управляти файлами пов\'язаними з чатом - завантажувати нові, переглядати або видаляти існуючі, сортувати в папки.' } },
  { key: '/bot', text: { en: '', ru: '', uk: 'Список діалогів для чат-бота. В колонці "Команда" вказано початковий запит-команду, яка стартує діалог. Ви можете створити новий ділог або редагувати існуючі.' } },
  { key: '/bot/faq', text: { en: '', ru: '', uk: 'На цій сторінці відображено список сценаріїв відповідей на часті запитання. Для зручності користування потрібно групирувати схожі запитання в одну категорію.' } },
  { key: '/bot/:id', text: { en: '', ru: '', uk: 'На цій сторінці Ви можете редагувати діалог чат-бота, додавати та змінювати тригери та відповіді' } },
  { key: '/tickets/:id', text: { en: '', ru: '', uk: 'Актуальна інформація стосовно тікета: статус, учасники, дата, переписка з користувачем. Використовуйте поле внизу сторінки для того щоб надати відповідь користувачеві. Для надсилання файлів скористайтеся кнопкою "Прикріпити файл". Кожне повідомлення в перписці Ви можете розглянути детальніше, якщо натиснете стрілочку в його правому верхньому куті' } },
  { key: '/tickets', text: { en: '', ru: '', uk: 'Система тікетів дозволяє зручно управляти запитами користувачів та відповідями на них. В цьому списку відображено тікети з помітками часу, статусу та операторів, задіяних у вирішенні питання. Оберіть тікет зі списку щоб побачити деталі' } },
  { key: '/settings/replies', text: { en: '', ru: '', uk: 'На цій сторінці відображено список швидких відповідей на питання користувачів, якими можуть користуватися у спілкуванні оператори чата.' } },
  { key: '/settings/operators/:id', text: { en: '', ru: '', uk: 'На цій сторінці Ви можете редагувати дані оператора' } },
  { key: '/settings/operators', text: { en: '', ru: '', uk: 'Список операторів чату. Ви можете дізнатись деталі про оператора або змінити їх, натиснувши на кнопку "Редагувати"' } },
  { key: '/settings/domains', text: { en: '', ru: '', uk: 'В цьому списку відображено Ваші доменні імена. Для того щоб переглянути або змінити дані, специфічні для домену (налаштування віджета, джерел, пошти) скористайтеся кнопкою "Перегляд"' } },
  { key: '/settings/domain/:id', text: { en: '', ru: '', uk: 'Тут Ви можете бачити та керувати налаштуваннями специфічними для домена - код віджета, налаштування віджета, дані для каналів та пошти' } },
  { key: '/kb/settings', text: { en: '', ru: '', uk: 'Налаштування для розділу Довідка' } },
  { key: '/kb', text: { en: '', ru: '', uk: 'В цьому списку Ви можете налаштувати інфорацію для статей розділу Довідка' } },
  { key: '/kb/:id', text: { en: '', ru: '', uk: 'На цій сторінці Ви можете налаштувати інфорацію для статі розділу Довідка' } },
  { key: '/settings/account', text: { en: '', ru: '', uk: 'На цій сторінці Ви можете редагувати налаштування аккаунту Вашої компанії' } },
  { key: '/reviews', text: { en: '', ru: '', uk: 'В цьому списку Ви можете бачити як користувачі оцінити спілкування з операторами в чаті за результатами опитування' } },
];
