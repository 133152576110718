import Axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

const useTiers = () => {
    const [tiers, setTiers] = useState(new Map());
    const [ready, setReady] = useState(false);

    const fetchTiers = useCallback(async () => {
        try {
            const { data } = await Axios.get('/tiers');
            setTiers(new Map(data.map(i => [i.id, i])))
        } catch (error) {
            console.log(error);
        }
        setReady(true);
    }, [setTiers])

    useEffect(() => {
        fetchTiers();
    }, [fetchTiers]);

    const getCost = useCallback(async (id, order) => {
        try {
            const { data } = await Axios.post(`/tiers/${id}`, order);
            return data;
        } catch (error) {
            console.log(error);
        }
        return false
    }, [])

    return [tiers, ready, getCost]
}

export default useTiers
