import axios from 'axios';

export const RECEIVED_DATA_SUCCESS = 'RECEIVED_DATA_SUCCESS';
export const RECEIVING_DATA = 'RECEIVING_DATA';

export function receiveDataRequest(start, end, periodUnit, tickData = {}) {
    return (dispatch) => {
        dispatch(receivingData());
        axios.get('/statistics/chart', 
            {params: {
                clientId: localStorage.getItem('clientId'),
                start, end, periodUnit, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, ...tickData
            }}).then(res => {                
                dispatch(receiveDataSuccess(res.data));
        })
    };
}

export function receiveDataSuccess(payload) {
    return {
        type: RECEIVED_DATA_SUCCESS,
        payload
    }
}

export function receivingData() {
    return {
        type: RECEIVING_DATA
    }
}




