import { CLEAR_SEARCH_RESULT, SEARCH_ACTION, SET_SEARCH_RESULT } from "./types";

/**
 * Action creators
 */

export function clearSearchResults() {
    return {
        type: CLEAR_SEARCH_RESULT
    };
}

export function searchChatAction(payload) {
    return {
        type: SEARCH_ACTION,
        payload,
    };
}

export function setSearchResults(payload) {
    return {
        type: SET_SEARCH_RESULT,
        payload,
    };
}
