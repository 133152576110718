import React from 'react';

const DocRenderer = ({docUrl}) => {
    return (
        <iframe
            title="Doc renderer"
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURI(docUrl)}`}
            width="100%"
            height="100%"
            frameBorder="0"
        >
            This is an embedded{' '}
            <a target="_blank" rel="noreferrer" href="http://office.com">
                Microsoft Office
            </a>{' '}
            document, powered by{' '}
            <a target="_blank" rel="noreferrer" href="http://office.com/webapps">
                Office Online
            </a>
            .
        </iframe>
    );
};

export default DocRenderer;
