import Axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
    Button, ButtonDropdown, ButtonToolbar, DropdownItem, DropdownMenu, DropdownToggle, Popover,
    PopoverBody,
    PopoverHeader
} from 'reactstrap';
import TierGuard, { Tiers } from '../../../../../components/TierGuard/TierGuard';
import { sendPoll } from '../../../../../redux/actions/chat';
import { leaveActiveRoo } from '../../../../../redux/active-room/actions';
import s from '../ChatDialog.module.scss';

const EndDialogBtns = ({ chat, dispatch }) => {
    const [pollPopoverOpen, setPollPopoverOpen] = useState(false);
    const [dropOpened, setDropOpened] = useState(false)
    const { t } = useTranslation('chat');

    const handleLeaveChat = () => {
        dispatch(leaveActiveRoo(chat));
    };

    const handleSendPoll = async () => {
        setPollPopoverOpen(false);
        let pollTemplate;
        const { data } = await Axios.get('/entity/PollTemplate');
        if (data && data.rows && data.rows.length > 0) {
            pollTemplate = data.rows[0];
        } else {
            const created = await Axios.post('/entity/PollTemplate', {
                name: 'Опитування про якість сервісу',
                text: 'Будь ласка, оцініть якість спілкування з менеджером',
            });
            pollTemplate = created.data;
        }
        if (pollTemplate) {
            const poll = await Axios.post('/entity/Poll', {
                pollTemplateId: pollTemplate.id,
                roomId: chat.roomId,
                userId: Number(localStorage.getItem('uid')),
                chatName: chat.name,
            });
            if (poll && poll.data) {
                dispatch(sendPoll({ pollTemplate, roomId: chat.roomId, pollId: poll.data.id }));
            } else {
                toast.error(t('Помилка при створенні опитування'));
            }
        }
        dispatch(leaveActiveRoo(chat));
    };

    if (chat.personal && chat.isGroup) return null;

    return (
        <>
            <ButtonDropdown
                id="popoverSendPoll"
                type="button"
                isOpen={dropOpened}
                toggle={() => setDropOpened(!dropOpened)}
                className="mr-auto d-md-block">
                <Button
                    color="info"
                    outline
                    size="sm"
                    className={`${s.leave} px-4 mr-auto`}
                    onClick={handleLeaveChat}
                >
                    <i className="la la-times"></i> &nbsp; 
                    <span className="d-none d-sm-inline">&nbsp;{t('Вийти')}</span>
                </Button>
                <TierGuard allowedTiers={[Tiers.swarm, Tiers.tiger]}>
                    <DropdownToggle size="sm" caret color="info" outline />
                    <DropdownMenu>
                        <DropdownItem  onClick={() => setPollPopoverOpen(true)}>
                            {t('Завершити з опитуванням')}
                        </DropdownItem>
                    </DropdownMenu>

                    <Popover
                        className="popover-danger"
                        target="popoverSendPoll"
                        hideArrow={false}
                        placement="top"
                        isOpen={pollPopoverOpen}
                        toggle={() => {
                            setPollPopoverOpen(false);
                        }}
                    >
                        <PopoverHeader className="px-5">
                            {t(
                                'Ви впевнені що хочете надіслати опитування та вийти з чату?'
                            )}
                        </PopoverHeader>
                        <PopoverBody className="px-5 d-flex justify-content-center">
                            <ButtonToolbar>
                                <Button
                                    color="success"
                                    size="xs"
                                    onClick={() => {
                                        handleSendPoll();
                                    }}
                                >
                                    {t('Так')}
                                </Button>
                                <Button
                                    color="danger"
                                    size="xs"
                                    onClick={() => {
                                        setPollPopoverOpen(false);
                                    }}
                                >
                                    {t('Ні')}
                                </Button>
                            </ButtonToolbar>
                        </PopoverBody>
                    </Popover>
                </TierGuard>
            </ButtonDropdown>
        </>
    )
}

export default EndDialogBtns