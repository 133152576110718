import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Alert } from 'reactstrap';

import s from './ClientNotifications.module.scss';
import { getClientNotifications } from '../../../redux/actions/client-notifications';
import { useHistory } from 'react-router';

const ClientNotifications = () => {
    const history = useHistory()

    const dispatch = useDispatch();
    const notifications = useSelector((state) => get(state, 'clientNotifications.list', []));

    const [closed, setClosed] = useState([]);

    const refreshNotifications = useCallback(() => {
        dispatch(getClientNotifications());
    }, [dispatch]);

    useEffect(() => {
        const id = setInterval(() => {
            refreshNotifications();
        }, 1000 * 60 * 5);
        return () => {
            return clearInterval(id);
        };
    }, [refreshNotifications]);

    const getIconClass = (type) => {
        switch (type) {
            case 'info':
                return 'fa fa-info-circle fa-lg';
            case 'danger':
                return 'fa fa-exclamation-triangle fa-lg';
            default:
                return 'fa fa-check fa-lg';
        }
    };

    const getAlertColor = (type) => {
        switch (type) {
            case 'info':
                return 'primary';
            case 'danger':
                return 'danger';
            default:
                return '';
        }
    };

    const handleClick = (banner) => {
      if (banner?.action?.route) history.push(banner.action.route);
    };

    return (
        <div className={s.notificationContainer + ' top-notification'}>
            {notifications.map((item) => (
                <Alert
                    onClick={() => handleClick(item)}
                    color={getAlertColor(item.type)}
                    key={item.id}
                    className={[s.notification, item.action && 'cursor-pointer'].join(' ')}
                    isOpen={closed.indexOf(item.id) === -1}
                    toggle={() => setClosed([...closed, item.id])}
                >
                    {
                        <div className="d-flex">
                            <div className={[s.notificationIcon + ' thumb-sm mr-1']}>
                                {item.image && item.image.url ? (
                                    <img
                                        className={'rounded-circle ' + s.notificationImage}
                                        src={item.image.url}
                                        alt="..."
                                    />
                                ) : (
                                    <i className={getIconClass(item.type)} />
                                )}
                            </div>
                            <div>
                                <b>{item.title}</b>
                                <br />
                                <span dangerouslySetInnerHTML={{ __html: item.text }}></span>
                            </div>
                        </div>
                    }
                </Alert>
            ))}
        </div>
    );
};

export default ClientNotifications;
