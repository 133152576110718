import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const ChatHistoryBreadcrumb = ({ name }) => {
    return <span>{name}</span>;
};

const mapStateToProps = (state, props) => {
    return {
        name: get(state, 'chat.history.chat.name'),
    };
};

export default connect(mapStateToProps)(ChatHistoryBreadcrumb);
