import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import s from './ChatInfo.module.scss';

const GuestLink = ({ close, link }) => {
  const { t } = useTranslation('chat');

  const closeModal = () => {
    close();
  }

  const handleCopyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link);
      toast.success(t('Посилання скопійовано'));
    }
  };

  return (
    <div className={`${s.groupList} animated fadeIn`}>
      <div className={s.groupListModalWrapper}>
        <div className={s.backdrop}></div>
        <section className={`${s.groupListModal} ${s.guestLinkModal} chat-section`}>
          <header className={s.groupListHeader}>
            <h5>{t('Посилання для гостя')}</h5>
            <span className="text-muted" onClick={closeModal}>
              <i className={`la la-times ${s.laLg}`}></i>
            </span>
          </header>
          <div className="py-3 d-flex">
            <div className={s.guestLink}>{link}</div>
            <i className={`la la-copy ml-3 ${s.guestLinkCopy}`} onClick={handleCopyLink}></i>
          </div>
        </section>
      </div>
    </div>
  )
}

export default GuestLink;