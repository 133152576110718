import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { EditorState, ContentState, getDefaultKeyBinding, convertToRaw } from "draft-js";
import Editor from '@draft-js-plugins/editor';
import "draft-js/dist/Draft.css";
import '@draft-js-plugins/mention/lib/plugin.css';
import createMentionPlugin, {
    defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import editorStyles from './ChatEditor.module.scss';
import { get } from 'lodash';
import { useSelector } from "react-redux";

// const mentions = [
//     {
//         name: 'Matthew Russell',
//     },
//     {
//         name: 'Julian Krispel-Samsel',
//     },
//     {
//         name: 'Jyoti Puri',
//     },
//     {
//         name: 'Max Stoiber',
//     },
//     {
//         name: 'Nik Graf',
//     },
//     {
//         name: 'Pascal Brandt',
//     },
// ];

const Commands = {
    SEND: 'send-command'
}

const ChatEditor = forwardRef((props, externalRef) => {
    const {
        className,
        onSend,
        placeholder,
        onChange
    } = props;

    const ref = useRef(null);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [open, setOpen] = useState(false);
    const [mentionAttempt, setMentionAttempt] = useState(false);

    const mentions = useSelector(state => get(state, 'chat.users', []));

    const [suggestions, setSuggestions] = useState(mentions);

    useImperativeHandle(externalRef, () => ({
        focus: () => {
            ref.current.focus();
        },
        clean: () => {
            setEditorState(EditorState.moveFocusToEnd(EditorState.push(editorState, ContentState.createFromText(''))));
        },
        getMentions: () => {
            if (!mentionAttempt) return [];

            const contentState = editorState.getCurrentContent();
            const raw = convertToRaw(contentState);
            let mentionedUsers = [];
            for (let key in raw.entityMap) {
                const ent = raw.entityMap[key];
                if (ent.type === "mention") {
                    mentionedUsers.push(ent.data.mention);
                }
            }

            return mentionedUsers;
        },
        getMessageData: () => {
            const text = editorState.getCurrentContent().getPlainText();
            let mentionedUsers = [];
            
            if (mentionAttempt) {
                const contentState = editorState.getCurrentContent();
                const raw = convertToRaw(contentState);
                
                for (let key in raw.entityMap) {
                    const ent = raw.entityMap[key];
                    if (ent.type === "mention") {
                        mentionedUsers.push(ent.data.mention);
                    }
                }
            }
            
            return {text, mentionedUsers};
        },
        setMessage: (text) => {            
            setEditorState(EditorState.createWithContent(ContentState.createFromText(text)));
        }
    }));

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            mentionPrefix: '@',
        });
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);

    const onSearchChange = useCallback(({ value }) => {
        setSuggestions(defaultSuggestionsFilter(value, mentions));
    }, [mentions]);

    const _handleKeyCommand = (command) => {
        if (command === Commands.SEND) {
            if (typeof onSend === 'function') {
                onSend();
            }
            return 'handled';
        }
        return 'not-handled';
    }

    const _keyMapFn = useCallback(
        (e) => {
            if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.metaKey) {
                return Commands.SEND;
            }
            return getDefaultKeyBinding(e);
        },
        [],
    )

    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange(editorState.getCurrentContent().getPlainText());
        }
    }, [editorState, onChange])

    return (
        <div
            className={`${editorStyles.editor} ${className}`}
            onClick={() => {
                ref.current.focus();
            }}
        >
            <Editor
                editorKey={'editor'}
                editorState={editorState}
                onChange={setEditorState}
                plugins={plugins}
                ref={ref}
                placeholder={placeholder}
                spellCheck
                handleKeyCommand={_handleKeyCommand}
                keyBindingFn={_keyMapFn}
            />
            <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
                onAddMention={() => setMentionAttempt(true)}
            />
        </div>
    );
});


export default ChatEditor;