import { ChonkyActions, FileHelper } from "chonky";

export const ukI18n = {
    locale: 'uk',
    formatters: {
        formatFileModDate: (intl, file) => {
            const safeModDate = FileHelper.getModDate(file);
            if (safeModDate) {
                return `${intl.formatDate(safeModDate)}, ${intl.formatTime(
                    safeModDate
                )}`;
            } else {
                return null;
            }
        },
        formatFileSize: (intl, file) => {
            if (!file || typeof file.size !== 'number') return null;
            return `Розмір: ${file.size}`;
        },
    },
    messages: {
        'chonky.toolbar.searchPlaceholder': 'Пошук',
        'chonky.toolbar.visibleFileCount': `{fileCount, plural,
            one {# файл}
            few {# файли}
            many {# файлів}
            other {# файлів}
        }`,
        'chonky.toolbar.selectedFileCount': `{fileCount, plural,
            =0 {}
            one {# виділений}
            other {# виділено}
        }`,
        'chonky.toolbar.hiddenFileCount': `{fileCount, plural,
            =0 {}
            one {# прихований}
            other {# приховано}
        }`,
        'chonky.fileList.nothingToShow': 'Тут порожньо!',
        'chonky.contextMenu.browserMenuShortcut': 'Меню браузера: {shortcut}',
        'chonky.actions.share_file.button.name': 'Поділитись',
        // File action translation strings. These depend on which actions you have
        // enabled in Chonky.
        [`chonky.actionGroups.Actions`]: 'Дії',
        [`chonky.actionGroups.Options`]: 'Опції',
        [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]: 'Відкрити батьківську папку',
        [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]: 'Нова папка',
        [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]: 'Створити нову папку',
        [`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]: 'Видалити файли',
        [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]: 'Відкрити виділення',
        [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]: 'Виділити все',
        [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]: 'Скинути виділення',
        [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]: 'Показати список',
        [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]: 'Показати іконки',
        [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]: 'Сорт. по імені',
        [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]: 'Сорт. по розміру',
        [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]: 'Сорт. по даті',
        [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]: 'Приховані файли',
        [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]: 'Папки на початку',
        [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.name`]: 'Завантажити файли',
        [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.tooltip`]: 'Обери файли для завантаження'
    },
};