import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import s from './TierSelector.module.scss';

const TierSelector = ({ tierId, setTierId, tiers }) => {
    const { t } = useTranslation('registration');
    const items = [...tiers.values()];

    const _handleCLick = (id) => setTierId?.(id)

    const _displayHelp = (e, id) => {
        e.stopPropagation();
    }

    const _renderText = alias => {
        switch (alias) {
            case 'free':
                return t('SPURDOG -  цей тариф з обмеженим набором функцій підійде підприємцям-початківцям. І один у полі - воїн! Зато - безкоштовно');
            case 'tiger':
                return t('TIGER SHARK - ваш проект починає зростати. Поступово розширюєтесь і підключаєте додаткових операторів. Всі функції доступні!');
            case 'swarm':
                return t('SHARK SWARM - у вас вже є злагоджена команда мінімум з 5 операторів. Цей тариф буде для вас. Нових колег завжди можна  долучити.');
            default:
                break;
        }
    }

    const slides = items.map((item) => {
        return (
            <div
                className={`d-flex justify-content-center align-items-center flex-column p-2 ${s.tierCard} ${item.id === tierId ? s.active : ''}`}
                style={{ height: '100%', flex: 1 }}
                onClick={() => _handleCLick(item.id)}
                key={item.alias}
            >
                <div className="img-responsive text-center d-flex">
                    <img
                        width="80"
                        height="80"
                        alt="Tier"
                        src={`/images/${item.alias}.png`}
                    />
                </div>
                <div className={`text-uppercase text-center d-flex mt-2 ${s.title}`}>
                    <div>{item.name}</div>
                </div>
                <i className={`la la-info-circle ${s.infoIcon}`} id={`infoIcon${item.id}`}
                onClick={(e) => _displayHelp(e, item.id)} 
                />
                <UncontrolledPopover placement={'bottom'} trigger="legacy" target={`infoIcon${item.id}`}>
                    <PopoverBody>
                        <div className={s.infotext}>
                            {_renderText(item.alias)}
                        </div>
                    </PopoverBody>
                </UncontrolledPopover>
            </div>

        );
    });

    return (
        <div className="d-flex justify-content-space-around align-items-center mb-4">
            {slides}
        </div>

    );
};

export default TierSelector;
