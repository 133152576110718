import React from "react";
import SocialLogin from "react-social-login";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin, type, ...props } = this.props;
    return (
      type === 'facebook' ? <FacebookLoginButton onClick={triggerLogin} {...props}>
        {children}
      </FacebookLoginButton> :
      <GoogleLoginButton onClick={triggerLogin} {...props} >
         {children}
      </GoogleLoginButton>
    );
  }
}

export default SocialLogin(SocialButton);