import * as Sentry from '@sentry/react';
import Axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
    Button,
    ButtonDropdown, Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Row
} from 'reactstrap';
import FileBrowser from '../../../../components/FileBrowser/FileBrowser';
import isScreen from '../../../../core/screenHelper';
import { newMessageRequest, showAutomations } from '../../../../redux/actions/chat';
import { sendSms } from '../../../../redux/sms/actions';
import ChatEditor from '../ChatEditor/ChatEditor';
import s from './ChatDialog.module.scss';
import { ChatDrop } from './ChatDrop';
import EndDialogBtns from './components/EndDialogBtns';



// !FIXME Uncommend if we need drafts in chat
// import { useDraft } from '../../../../utils/useDraft';

const NewMessage = (props) => {
    const { t } = useTranslation('chat');

    const { dispatch, chat, canSendSms } = props;

    const editorRef = useRef(null);
    const fileBrowserRef = React.useRef(null);

    /** State */
    const [newMessage, setNewMessage] = useState('');
    const [sendOpen, setSendOpen] = useState(false);

    // !FIXME Uncommend if we need drafts in chat
    // const [newMessage, setNewMessage] = useDraft(`chatMessage_${roomId}`);

    const [showAttachDialog, setShowAttachDialog] = useState(false);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [filesNeedLoading, setFilesNeedLoading] = useState(false);
  

    /** Fn */

    const loadFiles = useCallback(
        async (files) => {
            const formData = new FormData();
            formData.append('clientId', localStorage.getItem('clientId'));
            formData.append('roomId', chat.roomId);
            for (const file of files) {
                formData.append('chatFile', file);
            }

            const result = await Axios.post(`/file`, formData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });

            if (result && result.data) {
                return result.data;
            } else {
                return [];
            }
        },
        [chat]
    );

    const addSelectedFiles = async () => {
        try {
            const selectedFiles = fileBrowserRef.current.getFileSelection() ?? [];
            const {
                data: { rows },
            } = await Axios.get(`/entity/LocalFile`, {
                params: {
                    where: { path: selectedFiles.filter((file) => !file.isDir).map((file) => file.path) },
                },
            });
            setAttachedFiles(rows);
            setFilesNeedLoading(false);
        } catch (error) {
            toast.error(t('Помилка додаванні файлів'));
        }
        setShowAttachDialog(false);
    };

    const sendNewMessage = useCallback(async () => {
        let mentions = [];
        if (editorRef.current) {
            mentions = editorRef.current.getMentions().map((m) => m.operatorId);
        }

        if (attachedFiles.length > 0) {
            let filesdata = attachedFiles;
            if (filesNeedLoading) {
                filesdata = await loadFiles(attachedFiles);
            }
            dispatch(
                newMessageRequest({
                    dialogId: chat.roomId,
                    message: newMessage,
                    channelId: chat.channelId,
                    attachments: filesdata,
                    mentions,
                })
            );
        } else {
            dispatch(
                newMessageRequest({
                    dialogId: chat.roomId,
                    message: newMessage,
                    channelId: chat.channelId,
                    mentions,
                })
            );
        }
        setAttachedFiles([]);
        if (editorRef.current) editorRef.current.clean();
    }, [attachedFiles, setAttachedFiles, filesNeedLoading, loadFiles, newMessage, chat, dispatch]);

    const removeAttached = (index) => {
        setAttachedFiles([...attachedFiles.slice(0, index), ...attachedFiles.slice(index + 1)]);
    };

    // const handleKeyDown = (e) => {
    //     if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.metaKey) {
    //         sendNewMessage();
    //         e.stopPropagation();
    //         e.preventDefault();
    //     }
    // };

    

    const sendFileAction = async (selected) => {
        try {
            const {
                data: { rows },
            } = await Axios.get(`/entity/LocalFile`, {
                params: { where: { path: selected.map((file) => file.path) } },
            });
            setAttachedFiles(rows);
            setFilesNeedLoading(false);
        } catch (error) {
            toast.error(t('Помилка при додаванні файлів'));
        }
        setShowAttachDialog(false);
    };

    const sendAsSms = () => {
        dispatch(sendSms({ phone: chat.person.phone, message: newMessage }))
            .then((res) => {
                toast.success(t('Повідомлення надіслано'));
            })
            .catch((err) => {
                toast.error(t('Помилка при надсиланні повідомлення'));
                Sentry.captureException(err);
            });
    };

    return (
        <div className={`chat-section mb-0 ${s.newMessageWrapper}`}>
            <ChatDrop
                onDropMessage={({ message }) => {
                    setNewMessage(message);
                    if (editorRef.current) {
                        editorRef.current.setMessage(message);
                    }
                }}
                style={{ overflow: 'unset' }}
            >
                <form className={s.newMessage}>
                    <Row>
                        <Col lg={12}>
                            <ChatEditor
                                className={`mr-1 ${isScreen('sm') || isScreen('xs') ? s.mobile : ''}`}
                                rows={isScreen('sm') || isScreen('xs') ? 1 : 2}
                                onSend={sendNewMessage}
                                placeholder={t('Напишіть повідомлення')}
                                onChange={(text) => {
                                    setNewMessage(text.replace(/^\n/g, ''));
                                }}
                                ref={editorRef}
                            ></ChatEditor>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex align-items-center justify-content-end pt-2">
                            <EndDialogBtns chat={chat} dispatch={dispatch}  />
                            <Button
                                color="default"
                                size="sm"
                                className={s.attachment}
                                outline
                                onClick={() => setShowAttachDialog(true)}
                            >
                                <i className="la la-paperclip"></i>
                            </Button>
                            <Button
                                color="default"
                                size="sm"
                                className={s.replyTemplate}
                                outline
                                onClick={() => {
                                    dispatch(showAutomations());
                                }}
                            >
                                <i className="la la-reply"></i>
                            </Button>
                            <div className={`d-flex flex-column ${s.newMessageBtn}`}>
                                {canSendSms && chat?.person?.phone ? (
                                    <ButtonDropdown
                                        type="button"
                                        isOpen={sendOpen}
                                        toggle={() => setSendOpen(!sendOpen)}
                                    >
                                        <Button
                                            type="button"
                                            id="caret"
                                            color="danger"
                                            className={`px-3`}
                                            onClick={sendNewMessage}
                                        >
                                            {props.sendingMessage ? (
                                                <i className="la la-spin la-circle-notch"></i>
                                            ) : (
                                                <span>{t('Надіслати')}</span>
                                            )}
                                        </Button>
                                        <DropdownToggle caret color="danger" />
                                        <DropdownMenu>
                                            <DropdownItem onClick={sendAsSms}>
                                                {t('Надіслати по СМС')}
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                ) : (
                                    <Button
                                        color="danger"
                                        className={`px-4`}
                                        type="button"
                                        onClick={sendNewMessage}
                                        block
                                    >
                                        {props.sendingMessage ? (
                                            <i className="la la-spin la-circle-notch"></i>
                                        ) : (
                                            <span>{t('Надіслати')}</span>
                                        )}
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </form>
            </ChatDrop>

            {attachedFiles.length > 0 && (
                <div className={s.attachmentsBlock}>
                    <div>
                        <i className="la la-paperclip"></i>
                    </div>
                    <div className={s.attachmentsList}>
                        {attachedFiles.map((file, fileIndex) => {
                            return (
                                <div className={s.attachedFile} key={'attachfile-' + fileIndex}>
                                    <span>{file.name ? file.name : file.filename}</span>
                                    <Button className={s.rmButton} onClick={() => removeAttached(fileIndex)}>
                                        <i className="la la-close"></i>
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            <Modal
                size="lg"
                centered
                isOpen={showAttachDialog}
                toggle={() => setShowAttachDialog(false)}
                id="news-close-modal"
            >
                <ModalHeader toggle={() => setShowAttachDialog(false)} id="news-close-modal-label">
                    {t('Додати файл')}
                </ModalHeader>
                <ModalBody className="bg-white" style={{ height: 600 }}>
                    <FileBrowser
                        ref={fileBrowserRef}
                        fileOpenAction={sendFileAction}
                        embedded
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={() => setShowAttachDialog(false)} data-dismiss="modal">
                        {t('Скасувати')}
                    </Button>{' '}
                    <Button color="danger" onClick={addSelectedFiles}>
                        {t('Додати')}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        sendingMessage: state.chat.sendingMessage,
        canSendSms: state.sms.canSendSms,
    };
};

export default connect(mapStateToProps)(NewMessage);
