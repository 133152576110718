import HighchartsReact from 'highcharts-react-official';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { Col, CustomInput, Row } from 'reactstrap';
import Widget from '../../../components/Widget';
import { receiveDataRequest } from '../../../redux/actions/statistics';


const MainChart = ({ dispatch, mainChart, isReceiving }) => {
    const { t } = useTranslation('statistics');

    const periodsPresets = {
        TODAY: {
            preset: {
                startDate: moment(),
                endDate: moment(),
            },
            name: t('Сьогодні'),
        },
        LAST_MONTH: {
            preset: {
                startDate: moment().startOf('month'),
                endDate: moment().endOf('month'),
            },
            name: t('Останній місяць'),
        },
        DAYS7: {
            preset: {
                startDate: moment().subtract(7, 'days'),
                endDate: moment(),
            },
            name: t('Останні 7 днів'),
        },
        DAYS30: {
            preset: {
                startDate: moment().subtract(7, 'days'),
                endDate: moment(),
            },
            name: t('Останні 30 днів'),
        },
        YEAR: {
            preset: {
                startDate: moment().subtract(1, 'year'),
                endDate: moment(),
            },
            name: t('Останній рік'),
        },
    };

    const [dates, setDates] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });
    const [focusedInput, setfocused] = useState(null);

    useEffect(() => {
        if (moment(dates.startDate).isSame(dates.endDate)) {
            dispatch(
                receiveDataRequest(
                    moment(dates.startDate).startOf('d').format('X'),
                    moment(dates.startDate).endOf('d').format('X'),
                    'hour'
                )
            );
        } else {
            dispatch(
                receiveDataRequest(
                    moment(dates.startDate).startOf('d').format('X'),
                    moment(dates.endDate).endOf('d').format('X'),
                    'day',
                    {
                        tickStart: `1.${moment(dates.startDate).get('month') + 1}.${moment(
                            dates.startDate
                        ).get('year')}`,
                    }
                )
            );
        }
    }, [dates, dispatch]);

    const ticks = ['Dec 19'];

    const handlePresetChange = (e) => {
        setDates(periodsPresets[e.target.value].preset);
    };

    const chartData = () => {
        let data = mainChart.data
            ? mainChart.data.map((arr) => {
                  return arr.map((item) => {
                      return item[1];
                  });
              })
            : [];
        return {
            ...ticks,
            ...chartOptions,
            series: [
                {
                    name: t('Всього викликів'),
                    data: data[0],
                    color: '#1A86D230',
                    type: 'areaspline',
                    fillOpacity: 0.2,
                    lineWidth: 0,
                },
                {
                    type: 'spline',
                    name: t('Прийнято викликів'),
                    data: data[1],
                    color: '#3300fd',
                },
                {
                    type: 'spline',
                    name: t('Неприйняті виклики'),
                    data: data[2],
                    color: '#FD5F00',
                },
            ],
        };
    };

    const chartOptions = {
        credits: {
            enabled: false,
        },
        chart: {
            height: 350,
            backgroundColor: 'rgba(0,0,0,0)',
        },
        title: false,
        exporting: {
            enabled: false,
        },
        legend: {
            verticalAlign: 'top',
            itemStyle: {
                color: '#000000',
            },
            itemHoverStyle: {
                color: '#020202',
            },
        },
        yAxis: {
            title: false,
            labels: {
                style: {
                    color: '#000000',
                },
            },
            allowDecimals: false,
        },
        xAxis: {
            type: 'datetime',
            labels: {
                overflow: 'justify',
                style: {
                    color: '#000000',
                },
            },
        },
        annotations: {
            visible: false,
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                    symbol: 'circle',
                },
                pointInterval: moment(dates.startDate).isSame(dates.endDate) ? 3600000 : 3600000 * 24, // every day or every hour
                pointStart: mainChart.startPoint,
                tooltip: {
                    pointFormatter() {
                        return `<span style="color: ${this.color === '#1A86D230' ? '#1A86D2' : this.color}">${
                            this.series?.name
                        }: ${this.y}</span>`;
                    },
                },
            },
        },
        time: {
            useUTC: false,
        },
    };

    const _renderOptions = () => {
        return Object.entries(periodsPresets).map(([key, value], idx) => {
            return (
                <option key={`stat_opt_${idx}`} value={key}>
                    {value.name}
                </option>
            );
        });
    };

    const _renderTitle = () => {
        return (
            <Row>
                <Col xs={12} sm={3}>
                    <CustomInput
                        defaultValue="LAST_MONTH"
                        className="mb-2"
                        id="datePresets"
                        type="select"
                        onChange={handlePresetChange}
                        name="customSelect"
                    >
                        {_renderOptions()}
                    </CustomInput>
                </Col>
                <Col xs={12} sm={'auto'}>
                    <DateRangePicker
                        startDate={dates.startDate}
                        startDateId="startStatPeriod"
                        endDate={dates.endDate}
                        endDateId="endStatPeriod"
                        onDatesChange={({ startDate, endDate }) => setDates({ startDate, endDate })}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => setfocused(focusedInput)}
                        startDatePlaceholderText={t('Початок')}
                        endDatePlaceholderText={t('Кінець')}
                        minimumNights={0}
                        enableOutsideDays={true}
                        isOutsideRange={() => false}
                    />
                </Col>
                <Col xs={12}>
                    <div className="chart-legend" />
                </Col>
            </Row>
        );
    };

    return (
        <Widget bodyClass="mt" className="mb-xlg" fetchingData={isReceiving} title={_renderTitle()}>
            <HighchartsReact options={chartData()} />
        </Widget>
    );
};

function mapStateToProps(state) {
    return {
        isReceiving: state.statistics.isReceiving,
        mainChart: state.statistics.mainChart,
    };
}

export default connect(mapStateToProps)(MainChart);
