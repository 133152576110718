import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Container } from 'reactstrap';
import Loader from '../../components/Loader/Loader';
import Widget from '../../components/Widget/Widget';
import { useForm } from '../../utils/useForm';
import { resetPassword, validateOtp } from './OnboardService';

const OnboardPage = () => {
    const { t } = useTranslation('registration');

    const validate = (field, value, form) => {
        switch (field) {
            case 'confirmPassword':
                if (form.password !== value) {
                    return { [field]: t('Паролі не співпадають') };
                }
                return { confirmPassword: false, password: false }
            case 'password':
                if (form.confirmPassword !== '' && form.confirmPassword !== value) {
                    return { [field]: t('Паролі не співпадають') };
                }
                return { confirmPassword: false, password: false }
            default:
                break;
        }
        return { [field]: false };
    };

    const formFields = useMemo(() => [
        {
            name: 'password',
            lable: t('Пароль'),
            value: '',
            type: 'text',
        },
        {
            name: 'confirmPassword',
            lable: t('Підтвердження паролю'),
            value: '',
            type: 'text',
        },
    ], [t]);

    const { formState, renderField } = useForm({
        formFields,
        validate,
    });

    let { otp } = useParams();
    const history = useHistory();

    const { error, isFetching } = useQuery(['validateOtp', otp], () => validateOtp(otp), { refetchOnWindowFocus: false });


    /** Fn */

    const reset = useCallback(
        async () => {
            try {
                const { values, errors } = formState;
                if (Object.values(errors).filter(i => i).length > 0) return;
                if (Object.values(values).filter(i => i !== '').length === 0) return;

                await resetPassword(otp, values);
                return history.push('/');
            } catch (error) {
                console.log(error.message);
                toast.error(t('Помилка встановлення паролю'))
            }
        },
        [otp, t, formState, history],
    );


    /** Renders */

    const _renderContent = () => {
        if (isFetching) return <Loader />;

        if (error) return (
            <>
                <div>{t('Посилання застаріло. Почніть процедуру встановлення паролю спочатку.')}</div>
                <Link className="d-block text-center" to="/reset">
                    {t('Скидання паролю')}
                </Link>
            </>
        )

        return (
            <>
                <div>
                    <div>
                        {formFields.map(renderField)}
                    </div>
                    <Button block color="success" className='btn' onClick={reset}>{t('Встановити новий пароль')}</Button>
                </div>
            </>
        )
    }

    return (
        <div className="auth-page">
            <Container>
                <h5 className="auth-logo">
                    <img src="/images/shark-chat.svg" alt="" width="180" />
                </h5>
                <Widget className="widget-auth mx-auto" title={<h3 className="mt-0">{t('Встановлення паролю')}</h3>}>
                    {_renderContent()}
                </Widget>
            </Container>
        </div>
    );
};

export default OnboardPage;
