import Axios from 'axios';
import React, { useMemo, useState } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

import LoginLayout from '../../components/Layout/LoginLayout';
import Loader from '../../components/Loader/Loader';
import Widget from '../../components/Widget/Widget';
import { useForm } from '../../utils/useForm';

const ResetPage = () => {
    const { t } = useTranslation('registration');

    const validate = (field, value, form) => {
        switch (field) {
            case 'email':
                if (!value || value.length === 0) {
                    return { [field]: t('Введіть корректну email адресу') };
                }
                if (!String(value)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )) {
                    return { [field]: t('Введіть корректну email адресу') };
                }
                break;
            default:
                break;
        }
        return { [field]: false };
    };

    const formFields = useMemo(() => [
        {
            name: 'email',
            lable: t('Електронна пошта'),
            value: '',
            type: 'email',
        },
    ], [t]);

    const { formState, renderField, isValid } = useForm({
        formFields,
        validate,
    });

    /** State */

    const [opState, setOpState] = useState({sending: false, sended: false});

    /** Fn */

    const initReset = useCallback(
        async () => {
            try {
                const { values } = formState;
                setOpState(state => ({...state, sending: true}));
                if (!isValid()) {
                    return toast.error(t('Заповніть форму'))
                }

                await Axios.post('/user/reset', values);
                setOpState(state => ({...state, sending: false, sended: true}));
            } catch (error) {
                setOpState(state => ({...state, sending: false}));
                if (error.response) {
                    const { data: { msg } } = error.response;
                    if (msg === 'User not found') { 
                        return toast.error(t('Користувача не знайдено'))
                    }
                }
                toast.error(t('Помилка при скиданні паролю'));                
            }
        },
        [formState, t, isValid],
    )

    /** Renders */

    const _renderContent = () => {
        if (opState.sending) return <Loader />
        if (opState.sended) return t('Повідомлення з посиланням на скидання паролю відправлено на пошту');

        return (<>
            {formFields.map(renderField)}
            <Button color="success" onClick={initReset}>{t('Скинути пароль')}</Button>
        </>)
    }

    return (
        <LoginLayout>
            <Widget className="widget-auth mx-auto" title={<h3 className="mt-0">{t('Скидання паролю')}</h3>}>
                {_renderContent()}
            </Widget>
        </LoginLayout>
    )
}

export default ResetPage
