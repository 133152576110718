import React from 'react';
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';

const TopTableRow = ({ showAdd, showFilter, filter, add, title }) => {
    const { t } = useTranslation('common');

    return (
        <Row>
            {title && (<Col xs="auto"><h3>{title}</h3></Col>)}
           
            <Col xs="auto">
                {showAdd && <Button color="success" onClick={add}
                    className="mb-3">{t('Додати')}</Button>}</Col>
            <Col align="right">
                {showFilter && <Button
                    className="mb-3"
                    size="small"
                    outline
                    onClick={filter}
                ><i className="la la-filter la-lg"></i>&nbsp;{t('Фільтрувати')}</Button>}
            </Col>
        </Row>
    )
};

export default TopTableRow;
