import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonToolbar, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const PopoverQuestion = ({ isOpen, onClose, onOk, text, target }) => {
    const { t } = useTranslation('common');
    return (
        <Popover
            className="popover-danger"
            target={target}
            isOpen={isOpen}
            placement="top"
            trigger="legacy"
        >
            <PopoverHeader className="px-5">{text}</PopoverHeader>
            <PopoverBody className="px-5 d-flex justify-content-center">
                <ButtonToolbar>
                    <Button color="success" size="xs" onClick={() => onOk(true)}>
                        {t('Так')}
                    </Button>
                    <Button
                        color="danger"
                        size="xs"
                        onClick={() => {
                            onClose(false);
                        }}
                    >
                        {t('Ні')}
                    </Button>
                </ButtonToolbar>
            </PopoverBody>
        </Popover>
    );
};

PopoverQuestion.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
};

export default PopoverQuestion;
