// import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import axios from 'axios';
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import React, { Suspense } from 'react';
import 'react-dates/initialize';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import {
    QueryClient, QueryClientProvider
} from 'react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './components/App';
import config from './config';
import { persistor, store } from './redux/store';

import './i18n';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://e97d62d1b3c14084beb0a4f1f5f4a75c@o1070483.ingest.sentry.io/6066454"
    });

    console.log(`Build version: ${process.env.REACT_APP_CI_COMMIT_TAG}`);
    console.log("If you found some vulnerabilities or bugs please contact support team via email support@shark.chat.");
}

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = "application/json";
const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

setChonkyDefaults({ iconComponent: ChonkyIconFA });

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

export function initFacebookSdk() {
    return new Promise(resolve => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v8.0'
            });
            resolve('initialized');
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    });
}

initFacebookSdk().then(
    res => { }, rej => { }
).catch(err => Sentry.captureException(err));

// Create a client
const queryClient = new QueryClient();

ReactDOM.render(
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <DndProvider backend={HTML5Backend}>
                    <QueryClientProvider client={queryClient}>
                        <Suspense fallback="...is loading"><App /></Suspense>
                    </QueryClientProvider>
                </DndProvider>
            </PersistGate>
        </Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
