import Axios from 'axios';
import socketIOClient from 'socket.io-client';
import config from '../../config';
import {
    addChat,
    addNotification, ADD_OP_GROUP, ADD_USER_TO_CHAT, changeMessage, clearChatMessages, DELETE_MESSAGE, disableChat, DONE_OFFLINE, fetchChatFiles, fetchChatList,
    FETCH_CHAT_LIST,
    FETCH_MESSAGES, FETCH_OPS_GROUPS, incrementNotificationCount, newMessageSuccess,
    NEW_MESSAGE_REQUEST,
    OPEN_SOCKET,
    PULL_CHAT,
    receiveMessage, RECONNECT_SOCKET, removeMessage, SEND_INVITE, SEND_POLL, setActiveChat, setActiveOperators, setChatList,
    setChatMessages, setChatUser, setLastSeen, setLastSeenOp, setSocketConnected, setUsers, SET_ACTIVE_CHAT, TOGGLE_SOCKET_CONNECTION, updateChat, UPDATE_LAST_SEEN_OP, UPDATE_MESSAGE, UPDATE_PERSON
} from '../actions/chat';
import { clearRoomTyping, receiveRoomMessage, removeRoomMessage, setLastSeenTime, updateRoom, updateRoomMessage, updateRoomMessages, updateRoomTyping } from '../active-room/actions';
import { FETCH_INFO, FETCH_ROOM_MESSAGES, LEAVE_ROOM, SET_ACTIVE_ROOM } from '../active-room/types';
import { setSearchResults } from '../chat-search/actions';
import { SEARCH_ACTION } from '../chat-search/types';



export const DISCONNECT_CHAT_SOCKET = 'disconnect_chat_socket';

let socket = null;

export const connectSocket = async (store) => {
    store.dispatch(clearChatMessages());
    await setupSocket(store);
    setTimeout(() => {
        store.dispatch(fetchChatList({ pagination: { skip: 0, limit: 50 } }));
    }, 100);
};

export const reconnectSocket = async (store, user) => {
    socket.disconnect();
    socket = null
    localStorage.setItem('user', JSON.stringify(user));
    await setupSocket(store);
};

const setupSocket = async (store) => {
    if (socket) {
        return;
    }

    let user;

    
    /**
     * !FIXME Get from redux after rehydrate and keep user fresh in chat.uesr
     */
    //  const {
    //     chat: {
    //         user: userFromStore
    //     },
    // } = store.getState();
    // console.log(userFromStore);
    
    try {
        user = await Axios.get(`/user/me`).then(res => res.data);
    } catch (error) {
        return;
    }

    const { clientId, operatorId, domains } = user;
    const nspOperator = `/s/operator-chat/${clientId}`;

    socket = socketIOClient(`${config.chatBackend}${nspOperator}`, {
        query: {
            roomId: operatorId,
            nsp: nspOperator,
            operatorId,
            user: JSON.stringify(user),
        },
        transports: ['websocket'],
    });

    /** Utilites */
    socket.on('connect', () => {
        store.dispatch(fetchChatList({ pagination: { skip: 0, limit: 50 } }));
        store.dispatch(setSocketConnected(true));

        // const {
        //     room: {
        //         roomId: activeRoomId
        //     },
        // } = store.getState();
        // if (activeRoomId) {
        //     store.dispatch(fetchChatMessages({ id: activeRoomId }));
        // }
    });

    socket.on('active_operators', (data) => {
        store.dispatch(setActiveOperators(data));
    });

    socket.on('chat_disconnected', (data) => {
        // store.dispatch(closeChat(data));
    });

    socket.on('invitation', ({ userId, chat }) => {
        chat.type = 'chatInvite';
        store.dispatch(
            addNotification({
                roomId: chat.roomId,
                channelId: chat.channelId,
                title: 'Запрошення до чату',
                tag: 'invitation',
                data: chat,
            })
        );
    });


    /** Chat list functions */
    socket.on('chat_list', (data) => {
        store.dispatch(setChatList(data));
    });

    socket.on('chat_activated', (data) => {
        if (!data) return;

        const { roomData } = data;
        store.dispatch(addChat(data));
        if (!roomData) return;

        if (domains && !domains.includes(roomData.domain)) return;

        store.dispatch(
            addNotification({
                title: `Новий чат (${roomData.domain})`,
                text: 'Натисніть щоб відповісти',
                tag: 'new-chat',
                data,
            })
        );
    });

    socket.on('chat_updated', ({ chat }) => {
        store.dispatch(updateChat(chat));
        store.dispatch(updateRoom(chat));
    });

    /** Chat room messages */
    socket.on('chat_message', (data) => {
        const { message, chat } = data;
        const { people, roomData } = chat;

        store.dispatch(receiveMessage(message));
        store.dispatch(receiveRoomMessage(message));

        const showMessage = () => {
            if (message.responded) return;
            if (message.from === operatorId) return;
            if (message.kind !== 'standart') return;
            if (message.from === 'bot') return;
            // Show notification only if op in room or room empty
            if (people.length > 0 && people.indexOf(operatorId) === -1) return;

            store.dispatch(incrementNotificationCount());

            store.dispatch(
                addNotification({
                    roomId: chat.roomId,
                    text: message.text,
                    tag: 'new-message',
                    title: 'Нове повідомлення',
                    data: message,
                })
            );
        }

        if (chat.operators || chat.operatorsGroup) {
            showMessage();
            return;
        }

        if (!roomData) return;

        // Skip notification if op not subscribed to domain
        if (domains && !domains.includes(roomData.domain)) return;

        showMessage();
    });

    socket.on('operator_chat_message', (message) => {
        store.dispatch(receiveMessage(message));
        store.dispatch(receiveRoomMessage(message));
    });

    socket.on('list', (data) => {
        store.dispatch(
            setChatMessages({
                messages: data.messages,
                roomId: data.roomId,
                gotOlder: data.gotOlder,
                extra: data.extra,
            })
        );
        store.dispatch(updateRoomMessages(data))
    });

    socket.on('message_removed', (payload) => {
        store.dispatch(removeMessage(payload));
        store.dispatch(removeRoomMessage(payload));
    });
    socket.on('update_message', (payload) => {
        store.dispatch(changeMessage(payload));
        store.dispatch(updateRoomMessage(payload));
    });


    /** Typing */
    socket.on('typing', (data) => {
        // store.dispatch(setTyping(data));
        store.dispatch(updateRoomTyping(data));
    });
    socket.on('clear_typing', (data) => {
        // store.dispatch(clearTyping(data));
        store.dispatch(clearRoomTyping(data));
    });


    /** Message seen functional */
    socket.on('last_seen_time_op', (payload) => {
        store.dispatch(setLastSeenOp(payload));
    });
    socket.on('last_seen_time', (payload) => {
        store.dispatch(setLastSeen(payload));
        store.dispatch(setLastSeenTime(payload))
    });


    /** @deprecated ?*/
    socket.on('chats_and_groups', (payload) => {
        store.dispatch(setChatList(payload));
    });


    /** Search */
    socket.on('search_results', (payload) => {
        store.dispatch(setSearchResults(payload));
    });


    /** Open chat by link */
    socket.on('chat_revived', (payload) => {
        store.dispatch(addChat(payload));
        store.dispatch(setActiveChat(payload));
    });

    getClientOps(store);
    store.dispatch(setChatUser(user));
};

const getClientOps = async (store) => {
    const clientId = localStorage.getItem('clientId');
    const { data: { rows } } = await Axios.get(`/user/operators`, {
        params: {
            where: { clientId },
        },
    });
    if (rows) store.dispatch(setUsers(rows));
};

const socketMiddleware = (store) => {
    return (next) => (action) => {
        if (!socket && action.type !== OPEN_SOCKET && action.type !== TOGGLE_SOCKET_CONNECTION) {
            return next(action);
        }
        const {
            chat: {
                user: { operatorId, name, photo, position, color },
            },
        } = store.getState();
        switch (action.type) {
            case FETCH_CHAT_LIST:
                socket.emit('fetch_chat_list', action.payload);
                break;
            case FETCH_OPS_GROUPS:
                socket.emit('fetch_ops_chat_list', action.payload);
                break;
            case FETCH_MESSAGES:                
                socket.emit('fetch', {
                    pagination: action.payload.pagination,
                    id: action.payload.id,
                    extra: action.payload.extra,
                });
                break;
            case FETCH_ROOM_MESSAGES:
                socket.emit('fetch', {
                    pagination: action.payload.pagination,
                    id: action.payload.roomId,
                    extra: action.payload.extra,
                });
                break;
            case NEW_MESSAGE_REQUEST:
                if (!operatorId) return;

                const { domain, dialogId, channelId, attachments, message, mentions } = action.payload;
                socket.emit('message', {
                    text: message,
                    from: operatorId,
                    messageContext: { name, domain, role: 'agent', photo, position: position ?? 'оператор', color },
                    to: dialogId,
                    roomId: dialogId,
                    channelId: channelId,
                    attachments: attachments ? attachments : [],
                    mentions
                });

                if (action.payload.attachments) {
                    setTimeout(() => {
                        store.dispatch(fetchChatFiles(action.payload.dialogId));
                    }, 2000);
                }
                setTimeout(() => {
                    store.dispatch(newMessageSuccess({ dialogId: action.payload.dialogId }));
                }, 1000);
                break;
            case SEND_INVITE:
                socket.emit('invite', { ...action.payload, operatorId });
                break;
            case OPEN_SOCKET:
                setupSocket(store);
                break;
            case RECONNECT_SOCKET:
                reconnectSocket(store, action.payload);
                break;
            case SET_ACTIVE_ROOM:
            case SET_ACTIVE_CHAT:
                socket.emit('join', {
                    roomId: action.payload.roomId,
                    name: action.payload.name,
                    operatorName: name
                });
                break;
            case LEAVE_ROOM:
                socket.emit('leave', {
                    name: name,
                    roomId: action.payload.roomId,
                });
                break;
            case UPDATE_MESSAGE:
                socket.emit('update_message', {
                    message: action.payload,
                });
                break;
            case DELETE_MESSAGE:
                socket.emit('remove_message', action.payload);
                break;
            case SEND_POLL:
                socket.emit('send_poll', action.payload);
                break;
            case TOGGLE_SOCKET_CONNECTION:
                if (socket) {
                    socket.disconnect();
                    socket = null;
                    store.dispatch(disableChat());
                    store.dispatch(setSocketConnected(false));
                } else {
                    connectSocket(store);
                }
                break;
            case UPDATE_LAST_SEEN_OP:
                socket.emit('update_last_seen_time_op', action.payload);
                break;
            case ADD_OP_GROUP:
                socket.emit('create_room', action.payload);
                break;
            case SEARCH_ACTION:
                socket.emit('search', action.payload);
                break;
            case ADD_USER_TO_CHAT:
                socket.emit('add_op', action.payload);
                break;
            case DONE_OFFLINE:
                socket.emit('done_offline', action.payload);
                break;
            case PULL_CHAT:
                socket.emit('fetch_chat', action.payload);
                break;
            case UPDATE_PERSON:
                socket.emit('update_person', action.payload);
                break;
            case FETCH_INFO:
                socket.emit('fetch_info', action.payload);
                break;
            case DISCONNECT_CHAT_SOCKET:
                if (!socket) return;
                socket.disconnect();
                socket = null;
                store.dispatch(disableChat());
                store.dispatch(setSocketConnected(false));
                break;
            default:
                break;
        }

        return next(action);
    };
};

export default socketMiddleware;
