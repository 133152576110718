import Tabs, { TabPane } from 'rc-tabs';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { clearChatList, fetchChatList } from '../../../../redux/actions/chat';
import { clearSearchResults } from '../../../../redux/chat-search/actions';
import ChatSearch from '../ChatSearch';
import s from './ChatList.module.scss';
import ChatListItem from './ChatListItem';
import GroupAdder from './GroupAdder';

const ChatList = (props) => {
    const { t } = useTranslation('chat');
    const {
        activeChatId,
        dispatch,
        users,
        user,
        activeOperators,
        chats: allChats,
        searchRoomResults,
        searchChatResults,
        insearch,
        room,
    } = props;

    const [chats, setChats] = useState([]);
    const [operators, setOperators] = useState([]);
    const [domains, setDomains] = useState(user.domains ?? []);

    const [activeTab, setActiveTab] = useState('1');

    useEffect(() => {
        setActiveTab((activeTab) => {
            if (room) {
                return room.operators ? '2' : '1';
            }
            return activeTab;
        });
    }, [activeChatId, room]);

    useEffect(() => {
        dispatch(fetchChatList());
    }, [dispatch]);

    const findUser = useCallback(
        (id) => {
            return users.find((u) => u.operatorId === id);
        },
        [users]
    );

    const _clear = () => {
        dispatch(clearChatList());
        dispatch(fetchChatList());
        dispatch(clearSearchResults());
    };

    useEffect(() => {
        const getChats = (isOps = false) => {
            return allChats
                .filter((chat) => (isOps ? chat.operators : !chat.operators))
                .filter((chat) => {
                    if (!chat.roomData) return true;
                    if (domains.length === 0) return true;
                    if (!chat.roomData?.domain) return true;
                    if (
                        chat.people
                            .map((uid) => {
                                if (typeof uid === 'string') {
                                    return uid;
                                } else {
                                    return uid._id;
                                }
                            })
                            .indexOf(user.operatorId) !== -1
                    )
                        return true;
                    return domains.indexOf(chat.roomData.domain) !== -1;
                })
                .map((chat) => {
                    if (isOps) {
                        const activePeps = chat.people.filter((value) => {
                            if (typeof value !== 'string') {
                                value = value._id;
                            }
                            if (value === user.operatorId) return false;
                            return activeOperators.findIndex((i) => i._id === value) !== -1;
                        });

                        chat.live = activePeps.length > 0;
                    }

                    return chat;
                });
        };

        setChats(getChats());
        setOperators(getChats(true));
    }, [allChats, findUser, user, activeOperators, domains]);

    const _handleTabChange = useCallback(
        (tab) => {
            setActiveTab(tab);
        },
        [setActiveTab]
    );

    const _renderList = () => {
        if (insearch) {
            return (
                <>
                    <div
                        className={`chat-section mb-0 ${s.chatTabs} ${s.chatsSectionWrap} ${s.personalChats}`}
                    >
                        <div className="text-right border-bottom">
                            <Button size="xs" color="link" onClick={_clear}>
                                {t('Очистити')}
                            </Button>
                        </div>

                        {searchChatResults.length === 0 && searchRoomResults.length === 0 && (
                            <div
                                className="text-center d-flex flex-column justify-content-center align-items-center"
                                style={{ height: 'calc(100vh - 280px)' }}
                            >
                                <div>{t('Результатів немає')}</div>
                            </div>
                        )}

                        <ul className={`${s.chatList}`}>
                            {searchChatResults.map((message, i) => {
                                if (!message.room) return null;
                                
                                const chat = message.room;
                                chat.lastMessage = message;
                                return (
                                    <ChatListItem
                                        showAvatar={true}
                                        key={`${chat.roomId}_${i}`}
                                        isActive={false}
                                        chat={chat}
                                        search={true}
                                    />
                                );
                            })}
                        </ul>
                        {searchRoomResults && (
                            <ul className={`${s.chatList}`}>
                                {searchRoomResults.map((chat, i) => {
                                    return (
                                        <ChatListItem
                                            showAvatar={true}
                                            key={chat.roomId}
                                            isActive={false}
                                            chat={chat}
                                            search={true}
                                        />
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </>
            );
        }

        return (
            <>
                <div
                    className={`chat-section p-0 d-none d-lg-block mb-0 ${s.chatTabs} ${s.chatsSectionWrap} ${s.personalChats}`}
                >
                    <Tabs
                        activeKey={activeTab}
                        moreIcon="..."
                        tabBarGutter="10"
                        onTabClick={_handleTabChange}
                    >
                        <TabPane tab={<div className="chat-jr-step-1">{t('Чати')}</div>} key="1">
                            <ul className={`${s.chatList}`}>
                                {chats.map((chat, i) => (
                                    <ChatListItem
                                        showAvatar={true}
                                        key={chat.roomId}
                                        isActive={chat.roomId === props.activeChatId ? true : false}
                                        chat={chat}
                                    />
                                ))}
                            </ul>
                            {chats.length === 0 && (
                                <div
                                    className="text-center d-flex flex-column justify-content-center align-items-center"
                                    style={{ height: 'calc(100vh - 280px)' }}
                                >
                                    <div>{t('Запитів немає')} ;)</div>
                                </div>
                            )}
                        </TabPane>
                        <TabPane tab={<div className="chat-jr-step-2">{t('Робочі чати')}</div>} key="2">
                            <Row>
                                <Col sm="12">
                                    <GroupAdder />
                                    <ul className={`${s.chatList}`}>
                                        {operators.map((chat, i) => (
                                            <ChatListItem
                                                showAvatar={true}
                                                key={chat.roomId}
                                                isActive={chat.roomId === props.activeChatId ? true : false}
                                                chat={chat}
                                            />
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </div>

                <section className={`chat-section mb-0 d-lg-none ${s.allChats}`}>
                    <h5>{t('Активні чати')}</h5>
                    <ul className={`${s.chatList}`}>
                        {chats.map((chat, i) => (
                            <ChatListItem
                                key={chat.roomId}
                                isActive={chat.roomId === props.activeChatId ? true : false}
                                chat={chat}
                            />
                        ))}
                        {chats.length === 0 && (
                            <div className="text-center d-flex flex-column justify-content-center align-items-center my-5">
                                <div>{t('Запитів немає')} ;)</div>
                            </div>
                        )}
                    </ul>
                    <div className="mt-2">
                        <h5>{t('Робочі чати')}</h5>
                        <ul className={`${s.chatList} mb-3`}>
                            {operators.map((chat, i) => (
                                <ChatListItem
                                    showAvatar={true}
                                    key={chat.roomId}
                                    isActive={chat.roomId === props.activeChatId ? true : false}
                                    chat={chat}
                                />
                            ))}
                            {operators.length === 0 && (
                                <div className="text-center d-flex flex-column justify-content-center align-items-center my-5">
                                    <div>{t('Запитів немає')} ;)</div>
                                </div>
                            )}
                        </ul>
                    </div>
                </section>
            </>
        );
    };

    return (
        <div className={`chat-list-section`}>
            <ChatSearch onDomainsChange={setDomains} defaultDomains={user.domains} />
            {_renderList()}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.chat.user,
        users: state.chat.users,
        activeOperators: state.chat.activeOperators,
        chats: state.chat.chats,
        operatorsChats: state.chat.operatorsChats,
        activeChatId: state.chat.activeChatId,
        sendingMessage: state.chat.sendingMessage,
        searchChatResults: state.chatsearch.searchChatResults,
        searchRoomResults: state.chatsearch.searchRoomResults,
        insearch: state.chatsearch.insearch,
        room: state.room.room,
    };
}

export default connect(mapStateToProps)(ChatList);
