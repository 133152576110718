import React, { useEffect, useState } from 'react';
import SlidingPane from "react-sliding-pane";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import uuid from 'uuid/v4'
import 'react-day-picker/lib/style.css';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

import DateRangeFilter from './DateRangeFilter';

const Filters = ({filtersConfig, filterChange, isOpen, close, formName}) => { //({filtersConfig, filterChange}, ref) => {
    const { t } = useTranslation('common');
    const [filters, setFilters] = useState(null);

    useEffect(() => {
        if (filtersConfig) {
            const newFilters = {};
            for (const filter of filtersConfig) {
                newFilters[filter.name] = filter.defaultValue ? filter.defaultValue : '';
            }
            setFilters(newFilters);
        }
    }, [filtersConfig]);

    useEffect(() => {
        if (filters !== null) {
            const formedFilters = {};
            for (const filter of filtersConfig) {
                const value = filters[filter.name];
                if (value && (typeof value !== 'string' || value.length > 0)) {
                    
                    if (filter.formatter && typeof filter.formatter === 'function') {
                        formedFilters[filter.name] = filter.formatter(value);
                    } else if (filter.formatType === 'regex') {
                        formedFilters[filter.name] = {$regex: value};
                    } else if (filter.formatType === 'like') {
                        formedFilters[filter.name] = {$like: `%${value}%`};
                    } else if (filter.formatType === 'dateRange' && value.from && value.to) {
                        formedFilters[filter.name] = {
                            $gte: moment(value.from).toISOString().slice(0, 10),
                            $lt: moment(value.to).add(1, 'd').toISOString().slice(0, 10)
                        }
                    } else {
                        formedFilters[filter.name] = value;
                    }
                    if (filter.isInclude) {
                        const filterParts = filter.name.split('.');
                        if (filterParts.length > 1) {
                            const includeFilters = formedFilters['_include_where'] ? 
                            formedFilters['_include_where'] : {};
                            const model = filterParts[0];
                            const name = filterParts[1];
                            formedFilters['_include_where'] = {
                                ...includeFilters,
                                [model]: {...includeFilters[model], [name]: formedFilters[filter.name]}
                            }
                        }
                        delete formedFilters[filter.name];
                    }
                }
            }
            filterChange(formedFilters);
        }
    }, [filters, filterChange, filtersConfig]);

    const clearFilters = () => {
        const clearedFilters = {};
        for (const filter of filtersConfig) {
            clearedFilters[filter.name] = filter.defaultValue ? filter.defaultValue : '';
        }
        setFilters(clearedFilters);
    }

    return (
        <div>
            <SlidingPane
                isOpen={isOpen}
                title={t("Фільтри")}
                subtitle={t("Відфільтруйте результати")}
                width="340px"
                overlayClassName="overlayForSlide"
                onRequestClose={() => { close() }}
                closeIcon={<div><i className="la la-close la-lg"></i></div>}
            >
                <Form>
                    {filters && filtersConfig && filtersConfig.map(filter => {
                        return filter.type !== 'dateRange' ? <FormGroup key={formName + '-' + filter.name + '-filter'}>
                            <Label for={filter.name + '_input'}>{filter.title}</Label>
                            <Input className="mr-2" id={filter.name + '_input'}
                                key={formName + '-' + filter.name + '-filter-input'}
                                placeholder={filter.placeholder}
                                value={filters[filter.name]}
                                type={filter.type ? filter.type : 'text'}
                                onChange={(e) => { setFilters({ ...filters, [filter.name]: e.target.value }) }}
                            >
                                {filter.type === 'select' && filter.options ? filter.options.map(option => {
                                    return <option key={uuid()} value={option.value}>{option.label}</option>
                                }) : null}
                            </Input>
                        </FormGroup> :
                        <FormGroup key={formName + '-' + filter.name + '-filter'}>
                            <Label for={filter.name + '_input'}>{filter.title}</Label>
                            <DateRangeFilter name={filter.name} 
                                value={filters[filter.name]} 
                                onChange={(data) => setFilters({...filters, [filter.name]: data})}
                            ></DateRangeFilter>
                        </FormGroup>
                    })}
                    <FormGroup>
                        <Button onClick={clearFilters}>{t('Очистити')}</Button>
                    </FormGroup>
                </Form>
            </SlidingPane>
        </div>
    )
};

export default Filters;
