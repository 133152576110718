import * as Sentry from "@sentry/react";
import Axios from 'axios';
import { get, isEqual } from 'lodash';
import Tabs, { TabPane } from 'rc-tabs';
import 'rc-tabs/assets/index.css';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import uuid from 'uuid/v4';
import Commentator from '../../../../components/Commentator/Commentator';
import TierGuard from '../../../../components/TierGuard/TierGuard';
import config from '../../../../config';
import {
    fetchChatFiles, openUsersList
} from '../../../../redux/actions/chat';
import Avatar from '../Avatar';
import s from './ChatInfo.module.scss';
import GroupList from './GroupList';
import GuestLink from './GuestLink';
import OperatorsList from './OperatorsList';
import PersonaEdit from "./PersonaEdit";

const emptyPerson = { name: '', email: '', phone: '', city: '' };

// const setupImages = (chat) => {
//     return chat && chat.files ? chat.files.filter((file) => file.type === 'image') : [];
// };
// const setupDocuments = (chat) => {
//     return chat && chat.files ? chat.files.filter((file) => file.type !== 'image') : [];
// };

const ChatInfo = () => {
    const { t } = useTranslation('chat');   

    /** Redux */
    const dispatch = useDispatch();

    const user = useSelector((state) => get(state, 'chat.user', {}));

    const { operatorId } = user;

    const chat = useSelector(
        (state) => {
            const room = get(state, 'room.room');
            const {
                roomId,                
                roomData,
                person,                
                personal,
                people,
                name,
                _id
            } = room;
            return {
                roomId,                
                roomData,
                person,                
                personal,
                people,
                name,
                _id
            };
        },
        isEqual
    );    

    const {
        roomData,
        people,
        personal,
        name,
        _id: roomObjectId
    } = chat;


    /** State */

    const [openedGroupList, setOpenedGroupList] = useState(false);
    const [person, setPerson] = useState(emptyPerson);
    const [openedOperatorsList, setOpenedOperatorsList] = useState(false);
    const [openGuestLink, setOpenGuestLink] = useState(false);    
    const [guestLink, setGuestLink] = useState('');

    // !FIXME Fetch files in right time
    // const [images, setImages] = useState(setupImages(chat));
    // const [documents, setDocuments] = useState(setupDocuments(chat));

    /** Fn */

    const shortUsersList = () => {
        if (chatUsers().length <= 3) {
            return [...chatUsers()];
        } else {
            return chatUsers().slice(0, 3);
        }
    };

    const chatUsers = () => {
        return people ? people : [];
    };

    const openModal = () => {
        dispatch(openUsersList());
        setOpenedGroupList(true);
    };

    const showAddOperator = () => {
        setOpenedOperatorsList(true);
    };

    const addGuest = async () => {
        setGuestLink('');
        try {
            const { data } = await Axios.patch(`${config.chatBackend}/entity/Room/${roomObjectId}/addGuest`);
            if (data.person) {
                setGuestLink(`${config.chatAppUrl}/external/${data.person._id}`);
                setOpenGuestLink(true);
            }
        } catch (error) {
            Sentry.captureException(error);
            toast.error(t('Помилка при створенні запрошення'));
        }
    }

    const handleTabChange = (key) => {
        if (chat.roomId && ['2', '3'].includes(key)) {
            dispatch(fetchChatFiles(chat.roomId));
        }
    };


    /** Effects */

    useEffect(() => {
        setPerson(chat.person ? chat.person : emptyPerson);
    }, [chat]);


    /** Renders */

    const _renderName = () => {
        if (personal) {
            /** Select interlocutor name */
            const inter = people.find(p => p._id !== operatorId);
            if (inter) return inter.name;
        }

        return name;
    }

    if (!chat.roomId) {
        return null;
    }

    return (
        <>
            <section className={`${s.chatInfoHeader} chat-section bg-info`}>
                <div className="d-flex align-items-center mb-3">
                    <h4 className="mb-0 fw-semi-bold"><span className='short-string' style={{width: 190}}>{_renderName()}</span></h4>
                    <ul className={`${s.avatarsRow} ml-auto`}>
                        {shortUsersList().map((user) => (
                            <li key={uuid()}>
                                <Avatar showStatus={false} user={user} size={35} stroke={true} />
                            </li>
                        ))}
                    </ul>
                </div>

                <TierGuard allowedTiers={[3, 2]}>
                    <footer className="d-flex align-items-center justify-content-between">
                        <h5 className={`${s.cursorStyle} text-white mb-0`} onClick={openModal}>
                            {chat.people.filter(item => !item.guest).length} {t('оператора')}
                        </h5>
                        <Button color="white" className={`text-info fw-semi-bold`} onClick={showAddOperator}>
                            {t('Додати')}
                        </Button>
                    </footer>
                    {chat.operators && (
                        <footer className="d-flex align-items-center justify-content-between mt-3">
                            <h5 className={`${s.cursorStyle} text-white mb-0`} onClick={openModal}>
                                {chat.people.filter(item => item.guest).length} {t('гостя')}
                            </h5>
                            <Button color="white" className={`text-info fw-semi-bold`} onClick={addGuest}>
                                {t('Додати гостя')}
                            </Button>
                        </footer>
                    )}

                </TierGuard>

                {openedGroupList ? (
                    <GroupList uids={chat ? chat.people : []} close={() => setOpenedGroupList(false)} />
                ) : null}

                {openedOperatorsList ? (
                    <OperatorsList operators={chat.operators} uids={chat ? chat.people : []} roomId={chat.roomId} close={() => setOpenedOperatorsList(false)} />
                ) : null}

                {openGuestLink ? (
                    <GuestLink link={guestLink} close={() => setOpenGuestLink(false)} />
                ) : null}

            </section>

            <div className={`chat-section bg-white ${s.dynamicCard} p-0 mb-0 ${s.chatInfoTabs}`} style={{ position: 'relative' }}>
                <Tabs  moreIcon="..." tabBarGutter="5" onChange={handleTabChange}>
                    {!chat.operators && (
                        <TabPane tab={t('Інформація')} key="1" >
                            <Card.Body>
                                <ul className={s.personalInformation}>
                                    {roomData && (
                                        <>
                                            {roomData.domain && (
                                                <li>
                                                    <label htmlFor="">{t('Домен')}:</label>
                                                    &nbsp;
                                                    <span className="fw-semi-bold">{roomData.domain}</span>
                                                </li>
                                            )}
                                            {roomData.pageTitle && (
                                                <li>
                                                    <label htmlFor="">{t('Сторінка')}:</label>
                                                    &nbsp;
                                                    <small>
                                                        <span className="fw-semi-bold">{roomData.pageTitle}</span>
                                                    </small>
                                                </li>
                                            )}
                                            {roomData.pageUrl && (
                                                <li>
                                                    <label htmlFor="">URL:</label>
                                                    &nbsp;
                                                    <small>
                                                        <span>{roomData.pageUrl}</span>
                                                    </small>
                                                </li>
                                            )}
                                            <TierGuard allowedTiers={[3, 2]}>
                                                <>
                                                    {roomData.browser && (
                                                        <li>
                                                            <label htmlFor="">{t('Браузер')}: </label>&nbsp;
                                                            <span className="fw-semi-bold">{roomData.browser.name}</span>
                                                        </li>
                                                    )}
                                                    {roomData.os && (
                                                        <li>
                                                            <label htmlFor="">{t('ОС')}: </label>&nbsp;
                                                            <span className="fw-semi-bold">{roomData.os.name}</span>
                                                        </li>
                                                    )}
                                                    {person.city && (
                                                        <li>
                                                            <label>{t('Місто')}:</label>
                                                            <span className="ml-2 fw-semi-bold">{person.city}</span>
                                                        </li>
                                                    )}
                                                </>
                                            </TierGuard>                                            
                                        </>
                                    )}
                                </ul>
                                <hr />
                                <PersonaEdit />
                                
                            </Card.Body>
                        </TabPane>
                    )}

                    {/* <TabPane tab={t('Зображення')} key="2">
                        <Card.Body>
                            {!images?.length ? (
                                <p className="text-muted">
                                    <i>{t('Немає зображень')}</i>
                                </p>
                            ) : (
                                <ul className={s.listWithImages}>
                                    {images.map((file) => (
                                        <li key={file.id}>
                                            <img src={file.url} height="30px" alt="Chat file" />
                                            <a
                                                href={file.url}
                                                className={s.imgText}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {file.filename}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </Card.Body>
                    </TabPane>

                    <TabPane tab={t('Файли')} key="3">
                        <Card.Body>
                            {!documents?.length ? (
                                <p className="text-muted">
                                    <i>{t('Немає файлів')}</i>
                                </p>
                            ) : (
                                <ul className={s.listWithImages}>
                                    {documents.map((file) => (
                                        <li key={file.id}>
                                            <img src={download} alt="..." />
                                            <a
                                                href={file.url}
                                                className={s.imgText}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {file.filename}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </Card.Body>
                    </TabPane> */}

                    <TabPane tab={t('Коментарі')} key="4" >
                        <Commentator entityType="chat" entityId={chat.roomId} />
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
};


export default ChatInfo;
