import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListGroup, ListGroupItem, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import uuid from 'uuid/v4';
import { useTranslation } from 'react-i18next';

import Avatar from '../../../chat/components/Avatar/Avatar';
import s from './OperatorsList.module.scss';

const OperatorsList = ({ uids, ticketId, onInvite }) => {
    const { t } = useTranslation('tickets');
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [activeOperators, setActiveOperators] = useState([]);
    const [freeOperators, setFreeOperators] = useState([]);

    const operators = useSelector((state) => get(state, 'chat.users', []));
    const chatOperators = useSelector((state) => get(state, 'chat.activeOperators', []));

    useEffect(() => {
        if (!uids) return;

        if (operators.length === 0) return;

        setActiveOperators(uids.map((uid) => operators.find((u) => String(u.id) === String(uid))));
        setFreeOperators(
            operators
                .filter((user) => {
                    return chatOperators.find((u) => String(u._id) === String(user.operatorId));
             })
                .filter((user) => !uids.includes(String(user.id)))
        );
    }, [operators, setActiveOperators, uids, setFreeOperators, chatOperators]);

    const toggle = () => setPopoverOpen(!popoverOpen);

    const handleAdd = (user) => {
        if (typeof onInvite === 'function') onInvite(user);

        setTimeout(() => setPopoverOpen(false), 200);
    };

    const _renderAvatars = () => {
        if (!uids) return null;
        return activeOperators.map((user) => {
            if (!user) return null;

            return (
                <li key={uuid()} className="text-center d-flex align-items-baseline mr-1">
                    <Avatar showStatus={false} user={user} size={35} stroke={true} />
                    &nbsp;{user.name}
                </li>
            );
        });
    };

    const _renderPopover = () => {
        return (
            <Popover
                trigger="legacy"
                className={s.opsList}
                target={`avatarsList_${ticketId}`}
                isOpen={popoverOpen}
                toggle={toggle}
            >
                <PopoverHeader>{t('Запросити оператора')}</PopoverHeader>
                <PopoverBody style={{ padding: 0, overflowY: 'auto', maxHeight: 400 }}>
                    <ListGroup flush>
                        {freeOperators.map((user, index) => {
                            if (!user) return null;
                            return (
                                <ListGroupItem
                                    action
                                    tag="button"
                                    onClick={() => handleAdd(user)}
                                    key={`avatarsList_${ticketId}_${index}`}
                                >
                                    {user.name}
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </PopoverBody>
            </Popover>
        );
    };

    return (
        <div>
            <ul className={`${s.avatarsRow} ml-auto`}>
                {_renderAvatars()}
                {freeOperators.length > 0 && (
                    <li>
                        <div className={s.addButton} id={`avatarsList_${ticketId}`}>
                            <i className="las la-user-plus la-lg"></i>
                        </div>
                    </li>
                )}
            </ul>
            {freeOperators.length > 0 && _renderPopover()}
        </div>
    );
};

export default OperatorsList;
