import { debounce, get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, CustomInput, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { clearChatList, fetchChatList } from '../../../../redux/actions/chat';
import { clearSearchResults, searchChatAction } from '../../../../redux/chat-search/actions';
import s from './ChatSearch.module.scss';


const ChatSearch = (props) => {
    const { t } = useTranslation('chat');
    const { onDomainsChange, defaultDomains } = props;

    const [showFilters, setShowFilters] = useState(false);
    const ref = useRef();
    const [selectedDomains, setDomains] = useState(defaultDomains ?? []);
    const domains = useSelector((state) => get(state, 'settings.domains', []));
    const inSearch = useSelector((state) => get(state, 'chatsearch.insearch', false));

    const dispatch = useDispatch();

    const handleDomainChange = (e) => {
        let newDomains = [];
        if (selectedDomains.indexOf(e.target.name) === -1) {
            newDomains = [...selectedDomains, e.target.name];
        } else {
            newDomains = selectedDomains.filter((d) => d !== e.target.name);
        }
        setDomains(newDomains);
        if (typeof onDomainsChange === 'function') onDomainsChange(newDomains);
    };

    const debouncedSearch = useMemo(
        () =>
            debounce(query => {
                if (query === '') return dispatch(clearSearchResults());
                dispatch(searchChatAction(query))
            }, 400),
        [dispatch]
    )

    const _handleInputChange = useCallback((e) => {
        if (!e.target) return;
        debouncedSearch(e.target.value);
    }, [debouncedSearch]);

    const _handleClear = (e) => {
        dispatch(clearChatList());
        dispatch(fetchChatList());
        dispatch(clearSearchResults());
        if (ref.current) {
            ref.current.value = "";
        }
    }

    //* Effects */

    useEffect(() => {
        if (!inSearch && ref.current) {
            ref.current.value = "";
        }
    }, [inSearch])

    useEffect(() => {
        return () => {
            dispatch(clearSearchResults())
        }
    }, [dispatch])

    //* Renders */
    const _renderFilters = () => {
        if (!showFilters) return null;

        return (
            <div className="mt-2">
                <hr />
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="domains">{t('Домени')}</Label>
                            <div>
                                {domains.map((domain, idx) => {
                                    return (
                                        <CustomInput
                                            key={`domain_${idx}`}
                                            type="switch"
                                            id={domain.id}
                                            name={domain.name}
                                            label={domain.name}
                                            onChange={handleDomainChange}
                                            checked={selectedDomains.indexOf(domain.name) !== -1}
                                        />
                                    );
                                })}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div className={`${s.searchBox} chat-section bg-white ${props.classProp ? props.classProp : ''}`}>
            <InputGroup className={'input-group-no-border'}>
                <Input innerRef={ref} className={s.chatInput} placeholder={t("Пошук")} onChange={_handleInputChange} />
                <InputGroupAddon addonType="prepend" onClick={_handleClear}>
                    {inSearch ? (<i className="la la-times-circle" />) : (<i className="la la-search" />)}
                </InputGroupAddon>
                <InputGroupAddon style={{ cursor: 'pointer' }} addonType="prepend" onClick={() => setShowFilters(!showFilters)}>
                    <i className="la la-filter" />
                </InputGroupAddon>
            </InputGroup>
            {_renderFilters()}
        </div>
    );
};

export default ChatSearch;
