export const SET_ACTIVE_ROOM = 'AC_set_active_room';
export const SET_ROOM_MESSAGES = 'AC_set_room_messages';
export const UPDATE_ROOM_MESSAGES = 'AC_update_room_messages';
export const UPDATE_TYPING = 'AC_update_typing';
export const CLEAR_TYPING = 'AC_clear_typing';
export const UPDATE_ROOM = 'AC_update_room';
export const UPDATE_ROOM_MESSAGE = 'AC_update_room_message';
export const REMOVE_ROOM_MESSAGE = 'AC_remove_room_message';
export const RESET_PAGINATION = 'AC_reset_pagination';
export const FETCH_ROOM_MESSAGES = 'AC_fetch_messages';
export const RECIVE_ROOM_MESSAGE = 'AC_recive_room_message';
export const READ_NEW = 'AC_read_new';
export const FETCH_INFO = 'AC_fetch_info';
export const LEAVE_ROOM = 'AC_leave_room';
export const SET_LAST_SEEN_TIME = 'AC_set_last_seen_time';
export const RESET_ACTIVE_ROOM = 'AC_reset_active_room';