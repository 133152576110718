import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Button, Input, Label } from 'reactstrap';
import uuid from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import { shortEditorConfiguration } from '../../../utils/ckeditor-confs';
import { useDraft } from '../../../utils/useDraft';
import { ChatDrop } from '../../chat/components/ChatDialog/ChatDrop';
import styles from '../TicketsPage.module.scss';

const CreateMessage = ({ ticket, cancel, save }) => {
    const { t } = useTranslation('tickets');
    const { clientId } = useSelector((state) => get(state, 'chat.user', { clientId: null }));

    const [ticketMessage, setTicketMessage] = useDraft(`ticketMessage_${ticket.id}`);

    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState({
        topic: ticket ? ticket.topic : '',
        message: '',
        from: 'agent',
        ticketId: ticket ? ticket.id : '',
    });

    const onDrop = useCallback(
        async ({ files: dropped }) => {
            if (dropped.length > 0) {
                setFiles([...files, ...dropped]);
            }
        },
        [setFiles, files]
    );

    useEffect(() => {
        setMessage({
            topic: ticket ? ticket.topic : '',
            message: '',
            from: 'agent',
            ticketId: ticket ? ticket.id : '',
        });
    }, [ticket]);

    const setMessageValue = (value, name) => {
        if (name === 'message') {
            return setTicketMessage(value);
        }

        setMessage({
            ...message,
            [name]: value,
        });
    };

    const saveMessage = () => {
        const payload = {
            ...message,
            clientId,
            message: ticketMessage
        };

        if (files && files.length > 0) {
            const formData = new FormData();
            for (const key in payload) {
                formData.append(key, payload[key]);
            }
            for (const file of files) {
                formData.append('ticketFile', file);
            }
            save(formData);
        } else {
            save(payload);
        }
        setMessage({
            ...message,
            message: '',
        });
        setFiles([]);
        setTicketMessage('');
    };

    const fileAdded = async (event) => {
        setFiles([...event.target.files]);
    };

    const removeFile = (index) => {
        setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
    };

    return (
        <ChatDrop onDrop={onDrop}>
            <div className="p-4 tickets-jr-step-3">
                {!ticket && (
                    <>
                        <Label>{t('Тема')}</Label>
                        <Input
                            disabled={ticket && ticket.topic}
                            value={message.topic}
                            className="mb-3"
                            onChange={(e) => {
                                setMessageValue(e.target.value, 'topic');
                            }}
                        />
                    </>
                )}

                {/* <Label>Додати відповідь</Label> */}

                <div className="mb-3" style={{
                    width: '100%'
                }}>
                    <CKEditor
                        editor={Editor}
                        data={ticketMessage}
                        config={shortEditorConfiguration}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setTicketMessage(data);
                        }}
                    />
                </div>

                {/* <Label>From</Label>
                    <Input type="text" value={message.from} className="mb-3" onChange={
                      (e) => { setMessageValue(e.target.value, 'from') }} /> */}
                {files &&
                    files.map((file, index) => {
                        return (
                            <div className={'my-1 ' + styles.fileRow} key={uuid()}>
                                {file.name}
                                <i className="la la-close" onClick={() => removeFile(index)}></i>
                            </div>
                        );
                    })}
                <div className="mt-2 mb-3">
                    <label className="addFileLabel" htmlFor="upload">
                        <div className={styles.addFileBtn}>{t('Прикріпити файл')}</div>
                        <input hidden id="upload" multiple type="file" onChange={fileAdded} />
                    </label>
                </div>
                <div className="text-center">
                    {/* <Button color="" onClick={cancel}>Скасувати</Button>{' '} */}
                    <Button color="success" onClick={saveMessage}>
                        {t('Надіслати відповідь')}
                    </Button>
                </div>
            </div>
        </ChatDrop>
    );
};

export default CreateMessage;
