import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import config from '../../../../config';
import { openUsersList } from '../../../../redux/actions/chat';
import Avatar from '../Avatar';
import OnlineStatus from '../OnlineStatus';
import s from './ChatInfo.module.scss';

const GroupList = (props) => {
  const { t } = useTranslation('chat');   
  const { activeOperators } = props;

  const findUser = (operatorId) => {
    if (operatorId && operatorId.guest) {
      return operatorId;
    }
    if (typeof operatorId !== 'string') {
      operatorId = operatorId._id;
    }
    return props.users.find(u => u.operatorId === operatorId);
  }

  const groupUsers = () => {
    return props.uids.map(uid => findUser(uid)) || [];
  }

  const closeModal = () => {
    props.close()
    props.dispatch(openUsersList())
  }

  const isOpActive = (user) => {
    return activeOperators.findIndex(i => i._id === user.operatorId) !== -1
  }

  const copyLink = (link) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(link);
        toast.success(t('Посилання скопійовано'));
    }
  }

  return (
    <div className={`${s.groupList} animated fadeIn`}>
      <div className={s.groupListModalWrapper}>
        <div className={s.backdrop}></div>
        <section className={`${s.groupListModal} chat-section`}>
          <header className={s.groupListHeader}>
            <h5>{props.uids.length} участників</h5>
            <span className="text-muted" onClick={closeModal}>
              <i className={`la la-times ${s.laLg}`}></i>
            </span>
          </header>
          {/* <ChatSearch classProp="mb-2 p-0" />  */}
          <ul className={s.groupList}>
            {groupUsers().map(user => {
              if (user) {
                return (
                  <li key={user.id ? user.id : user._id}>
                    <Avatar
                      classProp="mr-2"
                      user={user}
                      size={40}
                      showStatus={false}
                    />
                    <div>
                      <p className="mb-0">{user.name} {user.surname}</p>
                      <small>
                        <OnlineStatus user={{
                          isOnline: isOpActive(user),
                          lastSeen: user.lastSeen
                        }} />
                      </small>
                    {user.guest ? <div className='w-100' style={{wordBreak: 'break-all'}}>
                      <small>{`${config.chatAppUrl}/external/${user._id}`}</small> 
                      <i className="la la-copy ml-2" onClick={() => copyLink(`${config.chatAppUrl}/external/${user._id}`)}></i>
                    </div> : null}
                    </div>
                  </li>
                )
              } else {
                return null;
              }
            }
            )}
          </ul>
        </section>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    users: state.chat.users,
    activeOperators: state.chat.activeOperators,
    user: state.chat.user,
  }
}

export default connect(mapStateToProps)(GroupList);