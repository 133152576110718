import { useEffect, useState } from "react";

export const useDraft = (storeKey) => {
    const [text, setText] = useState(() => {
        const currentText = window.sessionStorage.getItem(storeKey);
        return currentText !== null ? JSON.parse(currentText) : null;
    });

    useEffect(() => {
        window.sessionStorage.setItem(storeKey, JSON.stringify(text));
    }, [storeKey, text]);

    return [text, setText];
};
