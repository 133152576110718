import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Alert,
    Button,
    Label,
    Input,
    UncontrolledPopover,
    PopoverBody,
    FormText,
    ButtonGroup,
    FormGroup,
    Col,
    Row,
    FormFeedback,
} from 'reactstrap';
import { SketchPicker } from 'react-color';
import Axios from 'axios';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import s from './RegistrationWizard.module.scss';
import useCountries from '../../utils/useCountries';
import Loader from '../../components/Loader/Loader';
import TierSelector from './components/TierSelector';
import useTiers from '../../utils/useTiers';
import { useEffect } from 'react';

const domainRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;

const ProfileView = ({ next, prev, tier, form, dispatch, errors, onFieldChange, setTierId }) => {
    const { t } = useTranslation('registration');

    const { alias, domain, widgetColor, name, country, promocode } = form;

    const [countries, contriesReady] = useCountries();
    const [tiers, tiersReady, getCost] = useTiers();
    const selectCountry = useMemo(
        () => countries.map((c) => ({ value: c.code, label: c.name })),
        [countries]
    );

    /** State */

    const [error, setError] = useState(null);
    const [ops, setOps] = useState(5);
    const [payPeriod, setPayPeriod] = useState(12);
    const [cost, setCost] = useState();
    const [accept, setAccept] = useState(false);

    /** Redux */

    const isFetching = useSelector((state) => get(state, 'register.isFetching', false));

    /** Fn */

    const validDomain = async () => {
        if (!domainRegex.test(domain)) {
            dispatch({
                type: 'SET_ERRORS',
                payload: { domain: t('Некоректне імя домену') },
            });
            return false;
        }

        try {
            return await Axios.get('/auth/validate', { params: { domain } }).then((res) => res.data);
        } catch (error) {
            dispatch({
                type: 'SET_ERRORS',
                payload: { domain: t('Домен занято') },
            });
        }

        return false;
    };

    const save = async (e) => {
        e.preventDefault();
        if (!accept) {
            return setError(t('Необхідно погодитись з умовами використання'));
        }
        if (!name || name.trim() === '' || !alias || alias.trim() === '' || !domain || domain.trim() === '') {
            return setError(t('Заповніть всі поля'));
        }

        if (!(await validDomain())) return;

        setError(null);
        next({ name, alias, domain, widgetColor, ops, payPeriod });
    };

    /** Effects */

    useEffect(() => {
        if (tier > 1) {
            getCost(tier, { ops, payPeriod, country, promocode })
                .then(setCost)
                .catch(() => setCost());
        } else {
            setCost();
        }
    }, [tier, getCost, ops, payPeriod, country, promocode]);

    /** Render */

    const _renderPrice = () => {
        if (cost) {
            return (
                <div>
                    <div>{t('Вартість')}</div>

                    <div className={s.subscriptionCost}>
                        {cost.cost} <span className={s.currency}>{cost.currency}</span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className={s.subscriptionCost}>
                    <div className={s.next}>
                        {t('Назавжди безкоштовно')}
                    </div>
                    <div className={s.additional}>
                        {t('а також семидневний повнофунціональний пробний період')}
                    </div>
                </div>
            </div>
        )
    };

    if (!contriesReady || !tiersReady) return <Loader />;

    return (
        <div>
            <p className="widget-auth-info">{t('Заповніть всі поля')}</p>
            <form className="mt" onSubmit={save}>
                {error && (
                    <Alert className="alert-sm" color="danger">
                        {error}
                    </Alert>
                )}
                <Row>
                    <Col md={6}>
                        <FormGroup className="position-relative">
                            <Label>{t('Назва')}</Label>
                            <Input
                                invalid={errors['name'] !== false}
                                value={name}
                                type="text"
                                name="name"
                                onChange={onFieldChange}
                            />
                            <FormText color="muted">{t('Вкажіть назву Вашої компанії')}</FormText>
                        </FormGroup>
                        <FormGroup className="position-relative">
                            <Label>{t('Коротка назва (alias, допустимі символи a-z - _)')}</Label>
                            <Input
                                invalid={errors['alias'] !== false}
                                value={alias}
                                type="text"
                                name="alias"
                                onChange={onFieldChange}
                            />
                            <FormText color="muted">
                                {t('Вкажіть коротку назву Вашої компанії у вказаному форматі')}
                            </FormText>
                            <FormFeedback tooltip>{errors['alias']}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="position-relative">
                            <Label>{t('Адреса сайту (домен)')}</Label>
                            <Input
                                invalid={errors['domain'] !== false}
                                value={domain}
                                type="text"
                                name="domain"
                                onChange={onFieldChange}
                            />
                            <FormText color="muted">{t('Вкажіть адресу Вашого сайту')}</FormText>
                            <FormFeedback tooltip>{errors['domain']}</FormFeedback>
                        </FormGroup>

                        <FormGroup className="position-relative">
                            <Label>{t('Кольорова схема')}</Label>
                            <div className={s.colorInput}>
                                <span
                                    id="ColorBox"
                                    className={s.colorBox}
                                    style={{ backgroundColor: widgetColor }}
                                ></span>
                                <UncontrolledPopover trigger="legacy" target="ColorBox">
                                    <PopoverBody>
                                        <div className={s.colorPicker}>
                                            <SketchPicker
                                                color={widgetColor}
                                                onChange={(color) => {
                                                    dispatch({
                                                        type: 'UPDATE_FIELD',
                                                        payload: { widgetColor: color.hex },
                                                    });
                                                }}
                                            />
                                        </div>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <FormText color="muted">
                                {t(
                                    'Оберіть кольорову схему, в якій буде згенеровано віджет чата для Вашого сайту'
                                )}
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label>{t('Країна')}</Label>
                            <Select
                                defaultValue={selectCountry.find((c) => c.value === country)}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'UPDATE_FIELD',
                                        payload: { country: e.value },
                                    })
                                }
                                options={selectCountry}
                            />
                            <FormText>{t('Країна реєстрації компанії')}</FormText>
                        </FormGroup>
                    </Col>

                    <Col md={6} className="d-flex flex-column">
                        {tier && (
                            <>
                                <TierSelector setTierId={setTierId} tierId={tier} tiers={tiers} />
                                <FormGroup row>
                                    <Col>
                                        <FormGroup className="position-relative">
                                            <Label for="ops">{t('Кількість операторів')}</Label>
                                            <Input
                                                type="number"
                                                min={tiers.get(tier ? Number(tier) : 1)?.allowance?.minOps ?? 1}
                                                name="ops"
                                                id="ops"
                                                value={ops}
                                                onChange={(e) => setOps(e.target.value)}
                                                disabled={!cost}
                                            />
                                            <FormText color="muted">
                                                {t('Вкажіть бажану кількість операторів')}
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="position-relative">
                                            <Label for="payPeriod">{t('Період')}</Label>
                                            <Input
                                                type="select"
                                                name="payPeriod"
                                                id="payPeriod"
                                                value={payPeriod}
                                                onChange={(e) => setPayPeriod(e.target.value)}
                                                disabled={!cost}
                                            >
                                                <option value={1}>{t('Один місяць')}</option>
                                                <option value={6}>{t('Пів року')}</option>
                                                <option value={12}>{t('Рік')}</option>
                                            </Input>
                                            <FormText color="muted">
                                                {t('Вкажіть бажаний період оплати')}
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="promocode">{t('Промокод')}</Label>
                                    <Input
                                        placeholder={t('Ваш промокод')}
                                        id="promocode"
                                        invalid={errors['promocode'] !== false}
                                        value={promocode}
                                        type="text"
                                        name="promocode"
                                        onChange={onFieldChange}
                                    />
                                </FormGroup>

                                {_renderPrice()}
                            </>
                        )}
                    </Col>
                </Row>
                <hr />
                <FormGroup check className={`mb-3 position-relative ${s.accept}`}>
                    <Label check>
                        <Input
                            name={'approve'}
                            type={'checkbox'}
                            checked={accept}
                            onChange={() => setAccept(!accept)}
                        />{' '}
                        {t('Реєструючись на сайті, я погоджуюсь з умовами використання')}
                    </Label>                    
                </FormGroup>
                <hr />
                <ButtonGroup className="auth-btn">
                    <Button
                        type="button"
                        color="danger"
                        style={{ flex: 0.5 }}
                        className="mb-3"
                        size="sm"
                        onClick={prev}
                    >
                        {t('Назад')}
                    </Button>
                    <Button type="submit" color="info" className=" mb-3" size="sm">
                        {isFetching ? t('Завантаження...') : t('Зареєструватися')}
                    </Button>
                </ButtonGroup>
            </form>
        </div>
    );
};

export default withRouter(ProfileView);
