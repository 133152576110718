import React from 'react';
import { useDrag } from 'react-dnd';

const style = {
  backgroundColor: 'white',
  cursor: 'move',
};

const DraggableElement = ({ type, item, children }) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { item, type },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [item, type]
  );
  return (
    <div ref={drag} style={{ ...style, opacity }}>
      {children}
    </div>
  );
};

export default DraggableElement;
