export const selectStyles = {
    option: (provided) => provided,
    control: (provided) => ({
        ...provided,
        border: '1px solid #c1ccd3',
        height: 'calc(1.5em + 0.75rem + 2px)',
        minHeight: 'calc(1.5em + 0.75rem + 2px)',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#1A86D030',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#1A86D0',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#1A86D0',
        ':hover': {
            backgroundColor: '#1A86D0',
            color: '#fff',
        },
    }),
    indicatorSeparator: (provided, state) => {
        return {
            ...provided,
        };
    },
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0px 9px',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: '5px',
    }),
    clearIndicator: (provided) => {
        return {
            ...provided,
            padding: '5px',
            color: '#FFF',
        };
    },
};
