import Axios from 'axios';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, FormText } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import PopoverQuestion from '../../../../components/PopoverQuestion/PopoverQuestion';
import TierGuard, { Tiers } from '../../../../components/TierGuard/TierGuard';
import config from '../../../../config';
import { newMessageRequest, showDialog } from '../../../../redux/actions/chat';
import { loadEntities } from '../../../../utils/requests';
import styles from './ChatElements.module.scss';
import DraggableElement from './DraggableElement';

export const dropTypes = {
    ROUTINE: 'routine',
    QUICK: 'quick',
    LINK: 'link',
    File: 'file',
};

const ChatElements = () => {
    const { t } = useTranslation('chat');
    
    const dispatch = useDispatch();
    const dialogId = useSelector((state) => get(state, 'chat.activeChatId'));

    const [dialogs, setDialogs] = useState([]);
    const [quicks, setQuicks] = useState([]);
    const [showPop, setShowPop] = useState(false);
    const [selectedAutomation, setSelectedAutomation] = useState();

    /** Local functions */

    const loadDialogs = useCallback(async () => {
        loadEntities({
            entity: 'ReplyTemplate',
            onResult: ({ data }) => data && setQuicks(data.rows),
        });
    }, [setQuicks]);

    const fetchDialog = useCallback(async () => {
        const { data } = await Axios.get(
            `${config.chatBackend}/entity/Client/${localStorage.getItem('clientId')}`
        );
        if (data?.scenario) {
            const commands = data.scenario
                .map((i) => {
                    if (!i.triggers[0]) {
                        return null;
                    }
                    return { name: i.command, message: i.triggers[0].pattern, domain: i.domain };
                })
                .filter((i) => i);
            setDialogs(commands);
        }
    }, [setDialogs]);

    const _handleTap = (id, item) => {
        setShowPop(!showPop);
        setSelectedAutomation({ ...item, id });
    };

    const _handleOk = () => {
        const {message, domain, text} = selectedAutomation;
        dispatch(
            newMessageRequest({
                dialogId,
                message: message ?? text,
                domain
            })
        );
        setShowPop(false);
        setSelectedAutomation();
        dispatch(showDialog())
    };

    /** Effects */

    useEffect(() => {
        loadDialogs();
        fetchDialog();
    }, [loadDialogs, fetchDialog]);

    return (        
        <div className={`${styles.actionsWrap} ${styles.elementsSectionWrap}`}>
            <TierGuard allowedTiers={[Tiers.swarm, Tiers.tiger]}>
                <div className={`chat-section bg-white p-3`} id="scenarioList">
                    <h3>{t('Сценарії')}</h3>
                    <FormText>
                        {t('Перетягіть сценарії у поле розмови щоб активувати')}
                    </FormText>
                    
                    <div className={styles.pillsHolder}>
                        {dialogs.map((item, i) => (
                            <DraggableElement key={`command_${i}`} item={item} type={dropTypes.ROUTINE}>
                                <div id={`command_${i}`} onTouchEnd={() => _handleTap(`command_${i}`, item)}>
                                    <Badge color="success" pill>
                                        {item.name}
                                    </Badge>
                                </div>
                            </DraggableElement>
                        ))}
                        <DraggableElement key={`command_faq`} item={{ message: '/faq' }} type={dropTypes.ROUTINE}>
                            <Badge color="success" pill>
                                {t('F.A.Q.')}
                            </Badge>
                        </DraggableElement>
                        <DraggableElement
                            key={`command_stop`}
                            item={{ message: '/quit' }}
                            type={dropTypes.ROUTINE}
                        >
                            <Badge color="success" pill>
                                {t('Стоп')}
                            </Badge>
                        </DraggableElement>
                    </div>
                </div>
            </TierGuard>
            <div className={`chat-section bg-white pb-0 mb-0 ${styles.quickReply} ${styles.dynamicCard}`}>
                <h3>{t('Швидкі відповіді')}</h3>

                <FormText>
                    {t('Перетягіть швидку відповідь у поле розмови щоб відправити. Або у поле повідомленя щоб відредагувати його текст.')}
                </FormText>
                
                <div className={styles.quicksHolder}>
                    {quicks.map((item, i) => (
                        <DraggableElement
                            key={`quick_${i}`}
                            item={{ message: item.text }}
                            type={dropTypes.QUICK}
                        >
                            <div
                                id={`quick_${i}`}
                                onTouchEnd={() => _handleTap(`quick_${i}`, item)}
                                className={styles.quickReplyItem}
                            >
                                {item.title}
                            </div>
                        </DraggableElement>
                    ))}
                </div>
            </div>

            {selectedAutomation && (
                <PopoverQuestion
                    target={selectedAutomation.id}
                    onClose={setShowPop}
                    isOpen={showPop}
                    onOk={_handleOk}
                    text={t("Відправити повідомлення в чат?")}
                />
            )}
        </div>
    );
};

export default ChatElements;
