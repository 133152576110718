import Axios from 'axios';
import { get } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Badge, Button, Col, Row } from 'reactstrap';
import uuid from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import DocPreviewModal from '../../../components/DocRenderer/DocPreviewModal';
import PopoverQuestion from '../../../components/PopoverQuestion/PopoverQuestion';
import { readMessage, sendTicketInvitiation } from '../../../redux/tickets/actions';
import { selectStatusStylesInner, Statuses, statusName, ticketStatusOptions } from '../data/TicketStatuses';
import CreateMessage from './CreateMessage';
import OperatorsList from './Operators/OperatorsList';
import styles from './TicketDetails.module.scss';
import TicketMessage from './TicketMessage';
import CCChanger from './CCChanger';

const MESSAGES_LIMIT = 3;

const MessagesModes = {
    LATEST: 'latest',
    FULL: 'full',
};

const TicketDetails = ({ ticket, agents, ticketChange, ticketsPageDisapatch, isJR }) => {
    const { t } = useTranslation('tickets');
    const { messages } = ticket;
    const history = useHistory();
    const messagesListBottom = useRef(null);

    /** Global state */
    const { id: userId } = useSelector((state) =>
        get(state, 'chat.user', { clientId: null, userId: null, operatorId: null })
    );

    const dispatch = useDispatch();

    /** Local state */
    const [statusObj, setStatusObj] = useState(null);
    const [agentsData, setAgentsData] = useState([]);
    const [setShowTicketReply] = useState(false);

    const [shownMessages, setShownMessages] = useState([]);
    const [messagesMode, setMessagesMode] = useState(MessagesModes.LATEST);
    const [showCloseConfirmation, setCloseConfirmation] = useState(false);
    const [inWork, setInWork] = useState(true);
    const [wordPreview, setWordPreview] = useState();

    /** Local functions */
    const scrollToLastMesssage = useCallback(() => {
        if (messagesMode === MessagesModes.FULL && messagesListBottom.current)
            messagesListBottom.current.scrollIntoView({ behavior: 'smooth' });
    }, [messagesMode]);

    const changeStatus = async (selected) => {
        if (isJR) return;
        await Axios.patch(`/entity/Ticket/${ticket.id}`, { status: selected.value });
        setStatusObj(selected);
        if (typeof ticketChange === 'function') ticketChange();
    };

    const addMessage = async (messageData) => {
        if (isJR) return;
        try {
            await Axios.post(`/tickets/message`, messageData);
            // toast.success('Повідомлення відправлено');
        } catch (error) {
            toast.error(t('Помилка підчас відправки'));
        }
    };

    const updateCC = async (cc) => {
        if (isJR) return;
        await Axios.patch(`/entity/Ticket/${ticket.id}`, { cc });
    };

    const showAll = () => {
        setMessagesMode(messagesMode === MessagesModes.FULL ? MessagesModes.LATEST : MessagesModes.FULL);
    };

    const handleInvite = (user) => {
        if (isJR) return;
        dispatch(
            sendTicketInvitiation({
                operatorId: user.operatorId,
                ticketId: ticket.id,
            })
        );

        toast.success(t('Запрошення відправленно'));
    };

    const handleFinalize = async () => {
        if (isJR) return;

        try {
            await Axios.patch(`/entity/Ticket/${ticket.id}/finalize`);
            toast.success(t('Тікет закрито'));
        } catch (error) {
            toast.error(t('Помилка підчас закриття'));
        }
        setCloseConfirmation(false);
    };

    /** Side effects */

    useEffect(() => {
        setShownMessages(messagesMode === MessagesModes.LATEST ? messages.slice(-MESSAGES_LIMIT) : messages);
    }, [messages, messagesMode]);

    useEffect(() => {
        scrollToLastMesssage();
    }, [shownMessages, scrollToLastMesssage]);

    useEffect(() => {
        if (isJR) return;
        messages
            .filter((m) => (m.readedBy ? m.readedBy.indexOf(userId) === -1 : false))
            .forEach((m) => dispatch(readMessage({ messageId: m.id, userId })));
    }, [messages, dispatch, userId, isJR]);

    useEffect(() => {
        if (agents && ticket.agents && ticket.agents.length > 0) {
            // setAgentsData(
            //     ticket.agents.map((item) => {
            //         const agent = agents.find((a) => String(a.id) === String(item));
            //         return agent ? agent : { name: item, id: item };
            //     })
            // );
            setAgentsData(ticket.agents);
        } else {
            setAgentsData([]);
        }
        if (ticket.status) {
            setStatusObj({ value: ticket.status, label: statusName(ticket.status, t) });
            setInWork([Statuses.POSTPONED, Statuses.REJECTED, Statuses.RESOLVED].indexOf(ticket.status) === -1)
        }
    }, [ticket, agents, t]);

    /**
     * Renders
     */
    const _ellipsis = () => {
        if (messages.length <= MESSAGES_LIMIT) return null;

        return (
            <li className="text-center">
                <Badge className="my-2 cursor-pointer" pill color="light" onClick={showAll}>
                    <i className="la la-ellipsis-h la-lg" />
                </Badge>
            </li>
        );
    };


    const _panelToggler = () => {
        return (
            <div
                className={`${styles.roundButton}`}
                onClick={() => {
                    ticketsPageDisapatch({ type: 'TOGGLE_PANEL' });
                }}
            >
                <i className="la la-ellipsis-h la-lg" />
            </div>
        );
    };

    const _renderMore = () => {
        if (messages.length <= MESSAGES_LIMIT) return null;

        if (messagesMode === MessagesModes.FULL)
            return (
                <div id="render-more" className="text-center mt-2">
                    <Button size="sm" color="link" onClick={showAll}>
                        <i className="la-angle-down la" />
                        &nbsp;{t('Показати тільки остані {{messages_limit}} повідомленя', { messages_limit: MESSAGES_LIMIT })}
                    </Button>
                </div>
            );

        return (
            <div id="render-more" className="text-center mt-2">
                <Button size="sm" color="link" onClick={showAll}>
                    <i className="la-angle-down la" />
                    &nbsp;{t('Переглянути всю переписку')}
                </Button>
            </div>
        );
    };

    return (
        <div>
            <div className="tikcketMain">
                <div className={styles.ticketData}>
                    <Row>
                        <Col sm="auto">
                            <Button
                                color="primary"
                                onClick={() => {
                                    history.goBack();
                                }}
                                className="d-block d-sm-inline"
                                block
                            >
                                <i className="la la-arrow-left la-lg fw-bold"></i>&nbsp;{t('До списку')}
                            </Button>
                        </Col>
                        <Col className="mt-2 mt-sm-0 statusField">
                            <Select
                                styles={selectStatusStylesInner}
                                value={statusObj}
                                onChange={changeStatus}
                                options={ticketStatusOptions(t)}
                            />
                        </Col>

                        {inWork && (<Col sm="auto" className="mt-2 mt-sm-0">
                            <PopoverQuestion
                                target="closeTicketBtn"
                                onClose={setCloseConfirmation}
                                isOpen={showCloseConfirmation}
                                onOk={handleFinalize}
                                text="Закрити тікет?"
                            />
                            <Button
                                id="closeTicketBtn"
                                onClick={() => setCloseConfirmation(true)}
                                color="success"
                                className="d-block d-sm-inline"
                                block
                            >
                                <i className="la la-check la-lg fw-bold"></i>
                                <span className="d-none d-lg-inline">&nbsp;{t('Питання вирішено')}</span>
                            </Button>
                        </Col>)}

                        <Col xs={6} sm={'auto'} className="mt-2 mt-xl-0 justify-content-sm-end d-flex">
                            <OperatorsList uids={agentsData} ticketId={ticket.id} onInvite={handleInvite} />
                        </Col>
                        <Col xs={6} sm={'auto'} className="mt-2 mt-xl-0 justify-content-end d-flex ml-auto">
                            {_panelToggler()}
                        </Col>
                    </Row>
                    <hr />
                    <div className="meta" style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                        {ticket.domainName && (
                            <Badge className='mr-2' pill color="success">
                                {ticket.domainName}
                            </Badge>
                        )}
                        <span className='oneliner'>{t('Користувач')}: {ticket.email}</span>
                        <CCChanger ticket={ticket} onSave={updateCC} />
                    </div>
                    <hr />
                </div>
                <h1 className="page-title">{ticket.topic}</h1>
                <ul className={styles.messageList + ' tickets-jr-step-2'} id="messages-list">
                    {_ellipsis()}
                    {shownMessages.map((message) => {
                        return (
                            <li key={uuid()}>
                                <TicketMessage message={message} clientTitle={ticket.email} setWordPreview={setWordPreview}></TicketMessage>
                            </li>
                        );
                    })}
                </ul>
                <div ref={messagesListBottom}></div>

                {_renderMore()}

                <CreateMessage cancel={() => setShowTicketReply(false)} ticket={ticket} save={addMessage} />

                {wordPreview && <DocPreviewModal docUrl={wordPreview} setWordPreview={setWordPreview} />}
            </div>
        </div>
    );
};

export default TicketDetails;
