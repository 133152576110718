import React from 'react';

const ShoppingBag = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 9.16667C12.0392 9.16667 11.6667 9.54 11.6667 10C11.6667 10.9192 10.9192 11.6667 10 11.6667C9.08083 11.6667 8.33333 10.9192 8.33333 10C8.33333 9.54 7.96083 9.16667 7.5 9.16667C7.03917 9.16667 6.66667 9.54 6.66667 10C6.66667 11.8383 8.16167 13.3333 10 13.3333C11.8383 13.3333 13.3333 11.8383 13.3333 10C13.3333 9.54 12.9608 9.16667 12.5 9.16667ZM15 15.8333H5C4.54 15.8333 4.16667 15.4592 4.16667 15V7.5H15.8333V15C15.8333 15.4592 15.46 15.8333 15 15.8333ZM6.7675 4.41083C6.92333 4.25583 7.1375 4.16667 7.35667 4.16667H12.6433C12.8625 4.16667 13.0767 4.25583 13.2325 4.41083L14.655 5.83333H5.345L6.7675 4.41083ZM16.7675 5.58917L14.4108 3.2325C13.9392 2.76 13.3108 2.5 12.6433 2.5H7.35667C6.68917 2.5 6.06083 2.76 5.58917 3.2325L3.2325 5.58917C2.76 6.06083 2.5 6.68917 2.5 7.3575V15C2.5 16.3783 3.62167 17.5 5 17.5H15C16.3783 17.5 17.5 16.3783 17.5 15V7.3575C17.5 6.68917 17.24 6.06083 16.7675 5.58917Z" fill="currentColor"/>
        <mask id="mask118" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 9.16667C12.0392 9.16667 11.6667 9.54 11.6667 10C11.6667 10.9192 10.9192 11.6667 10 11.6667C9.08083 11.6667 8.33333 10.9192 8.33333 10C8.33333 9.54 7.96083 9.16667 7.5 9.16667C7.03917 9.16667 6.66667 9.54 6.66667 10C6.66667 11.8383 8.16167 13.3333 10 13.3333C11.8383 13.3333 13.3333 11.8383 13.3333 10C13.3333 9.54 12.9608 9.16667 12.5 9.16667ZM15 15.8333H5C4.54 15.8333 4.16667 15.4592 4.16667 15V7.5H15.8333V15C15.8333 15.4592 15.46 15.8333 15 15.8333ZM6.7675 4.41083C6.92333 4.25583 7.1375 4.16667 7.35667 4.16667H12.6433C12.8625 4.16667 13.0767 4.25583 13.2325 4.41083L14.655 5.83333H5.345L6.7675 4.41083ZM16.7675 5.58917L14.4108 3.2325C13.9392 2.76 13.3108 2.5 12.6433 2.5H7.35667C6.68917 2.5 6.06083 2.76 5.58917 3.2325L3.2325 5.58917C2.76 6.06083 2.5 6.68917 2.5 7.3575V15C2.5 16.3783 3.62167 17.5 5 17.5H15C16.3783 17.5 17.5 16.3783 17.5 15V7.3575C17.5 6.68917 17.24 6.06083 16.7675 5.58917Z" fill="white"/>
        </mask>
        <g mask="url(#mask118)">
        <rect width="20" height="20" fill="currentColor"/>
        </g>
    </svg>    
)

export default ShoppingBag