import axios from 'axios';
import * as Sentry from '@sentry/react';

export const SET_CN_LIST = 'SET_CN_LIST';

export function getClientNotifications() {
    return (dispatch) => {
        axios
            .get('/entity/ClientNotification', {
                params: {
                    where: {
                        disabled: false,
                    },
                    includes: ['LocalFile'],
                    limit: 1,
                    order: JSON.stringify([['createdAt', 'DESC']])
                },
            })
            .then((res) => {
                if (res.data.rows) {
                    dispatch(setList(res.data.rows));
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };
}

export function setList(payload) {
    return {
        type: SET_CN_LIST,
        payload,
    };
}
