import { get } from 'lodash';
import { fetchChatMessages } from "../actions/chat";
import { CLEAR_TYPING, FETCH_INFO, FETCH_ROOM_MESSAGES, LEAVE_ROOM, READ_NEW, RECIVE_ROOM_MESSAGE, REMOVE_ROOM_MESSAGE, RESET_ACTIVE_ROOM, RESET_PAGINATION, SET_ACTIVE_ROOM, SET_LAST_SEEN_TIME, SET_ROOM_MESSAGES, UPDATE_ROOM, UPDATE_ROOM_MESSAGE, UPDATE_ROOM_MESSAGES, UPDATE_TYPING } from "./types";

/**
 * Action creators
 */

export function setRoom(payload) {
    return {
        type: SET_ACTIVE_ROOM,
        payload,
    };
}

export function updateRoom(payload) {
    return {
        type: UPDATE_ROOM,
        payload,
    };
}

export function setRoomMessages(payload) {
    return {
        type: SET_ROOM_MESSAGES,
        payload,
    };
}

export function updateRoomMessages(payload) {
    return {
        type: UPDATE_ROOM_MESSAGES,
        payload,
    };
}

export function receiveRoomMessage(payload) {
    return {
        type: RECIVE_ROOM_MESSAGE,
        payload,
    };
}

export function updateRoomMessage(payload) {
    return {
        type: UPDATE_ROOM_MESSAGE,
        payload,
    };
}

export function removeRoomMessage(payload) {
    return {
        type: REMOVE_ROOM_MESSAGE,
        payload,
    };
}


export function updateRoomTyping(payload) {
    return {
        type: UPDATE_TYPING,
        payload,
    };
}

export function clearRoomTyping(payload) {
    return {
        type: CLEAR_TYPING,
        payload,
    };
}

export function resetPagination(payload) {
    return {
        type: RESET_PAGINATION,
        payload
    };
}

export function fetchMessages(payload) {
    return {
        type: FETCH_ROOM_MESSAGES,
        payload
    };
}

export function readNew() {
    return {
        type: READ_NEW
    };
}

export function fetchRoomInfo(payload) {
    return {
        type: FETCH_INFO,
        payload
    };
}

export function leaveActiveRoo(payload) {
    return {
        type: LEAVE_ROOM,
        payload
    };
}

export function setLastSeenTime(payload) {
    return {
        type: SET_LAST_SEEN_TIME,
        payload
    };
}


export function resetActiveRoom(payload) {
    return {
        type: RESET_ACTIVE_ROOM
    };
}

/** Thunk */

export function enterRoom(roomId) {
    return async (dispatch, getState) => {
        const chats = get(getState(), 'chat.chats', []);
        const roomData = chats.find(c => c.roomId === roomId);
        /** Set data from state */
        if (roomData) {
            dispatch(setRoom(roomData));
        }
        /** and fetch fresh data*/
        dispatch(fetchRoomInfo(roomId));

        dispatch(resetPagination({ skip: 0, limit: 50 }));

        dispatch(fetchChatMessages({ id: roomId }));
    };
}