import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { addOpGroup } from '../../../../redux/actions/chat';

const GroupAdder = () => {
    const { t } = useTranslation('chat');
    const dispatch = useDispatch();
    const [group, setGroup] = useState('');
    const [show, setShow] = useState(false);

    const _handleAdd = () => {
        if (group.length === 0) return;
        dispatch(addOpGroup({ name: group }));
        setGroup('');
        setShow(false);
    };

    const _showField = () => setShow(true);

    return (
        <div style={{ padding: '20px 0' }}>
            {show ? (
                <InputGroup>
                    <Input value={group} onChange={(e) => setGroup(e.target.value)}></Input>
                    <InputGroupAddon addonType="append">
                        <Button color="success" onClick={_handleAdd}>
                            {t('Створити')}
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            ) : (
                <Button color="link" onClick={_showField}>+ {t('Додати групу')}</Button>
            )}
        </div>
    );
};

export default GroupAdder;
