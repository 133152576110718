import React from 'react';
import { Container } from 'reactstrap';

const LoginLayout = ({ children }) => {
    return (
        <div className="auth-page">
            <Container>
                <h5 className="auth-logo">
                    <img src="/images/shark-chat.svg" alt="" width="180" />
                </h5>
                {children}
            </Container>
        </div>
    );
};

export default LoginLayout;
