import Axios from 'axios';
import { throttle } from 'lodash';
import config from '../../config';
import { enterRoom } from '../active-room/actions';
import { ChatSideMenuStates, MobileChatStates } from '../reducers/chat';

export const CHANGE_MOBILE_STATE = 'CHANGE_MOBILE_STATE';
export const NEW_MESSAGE_SUCCESS = 'NEW_MESSAGE_SUCCESS';
export const NEW_MESSAGE_REQUEST = 'NEW_MESSAGE_REQUEST';
export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';
export const OPEN_USERS_LIST = 'OPEN_USERS_LIST';
export const SET_CHAT_LIST = 'SET_CHAT_LIST';
export const FETCH_CHAT_LIST = 'FETCH_CHAT_LIST';
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';
export const SET_CHAT_PAGINATION = 'SET_CHAT_PAGINATION';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const ADD_CHAT = 'ADD_CHAT';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const SET_CHAT_FILES = 'SET_CHAT_FILES';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const SET_CHAT_INFO = 'SET_CHAT_INFO';
export const SET_HISTORY_CHAT = 'SET_HISTORY_CHAT';
export const SET_HISTORY_MESSAGES = 'SET_HISTORY_MESSAGES';
export const ADD_HISTORY_MESSAGES = 'ADD_HISTORY_MESSAGES';
export const SET_HISTORY_FILES = 'SET_HISTORY_FILES';
export const SET_HISTORY_DATE = 'SET_HISTORY_DATE';
export const SET_CHAT_HIST_PAGINATION = 'SET_CHAT_HIST_PAGINATION';
export const CLOSE_CHAT = 'CLOSE_CHAT';
export const SEND_INVITE = 'SEND_INVITE';
export const SET_ACTIVE_OPERATORS = 'SET_ACTIVE_OPERATORS';
export const OPEN_SOCKET = 'OPEN_SOCKET';
export const SET_OPERATORS_CHATS = 'SET_OPERATORS_CHATS';
export const SET_TYPING = 'SET_TYPING';
export const CLEAR_TYPING = 'CLEAR_TYPING';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export const INCREMENT_NOTIFICATION_COUNT = 'INCREMENT_NOTIFICATION_COUNT';
export const SET_CHAT_USER = 'set_chat_user';
export const LEAVE_ROOM = 'leave_room';
export const SET_USERS = 'set_users';
export const DEACTIVATE_CHAT = 'deactivate_chat';
export const SET_PUSH_STATE = 'set_push_state';
export const DELETE_MESSAGE = 'delete_message';
export const UPDATE_MESSAGE = 'update_message';
export const REMOVE_MESSAGE = 'remove_message';
export const TOGGLE_MUTE = 'toggle_mute';
export const SET_CHAT_SIDEBAR = 'set_chat_sidebar';
export const CHANGE_MESSAGE = 'change_message';
export const RECONNECT_SOCKET = 'reconnect_socket';
export const SEND_POLL = 'send_poll';
export const SET_AUDIO_PERMISSION = 'set_audio_permission';
export const TOGGLE_SOCKET_CONNECTION = 'toggle_socket_connection';
export const SET_SOCKET_CONNECTED = 'set_socket_connected';
export const CLEAR_CHAT_MESSAGES = 'clear_chat_messages';
export const SET_LAST_SEEN_OP = 'set_last_seen_op';
export const SET_LAST_SEEN = 'set_last_seen';
export const UPDATE_LAST_SEEN_OP = 'update_last_seen_op';
export const CHANGE_CHAT_WINDOW = 'change_chat_window';
export const FETCH_OPS_GROUPS = 'fetch_ops_groups';
export const SET_OPS_GROUPS = 'set_ops_groups';
export const ADD_OP_GROUP = 'add_op_group';
export const ADD_USER_TO_CHAT = 'add_user_to_chat';
export const CLEAR_CHAT_LIST = 'clear_chat_list';
export const DONE_OFFLINE = 'done_offline';
export const PULL_CHAT = 'pull_chat';
export const UPDATE_PERSON = 'update_person';
export const RESET_CHAT = 'reset_chat';

export function changeMobileState(payload) {
    return {
        type: CHANGE_MOBILE_STATE,
        payload,
    };
}

export function openUsersList() {
    return {
        type: OPEN_USERS_LIST,
    };
}

export function sendInvitation(payload) {
    return {
        type: SEND_INVITE,
        payload,
    };
}

export function addUser(payload) {
    return {
        type: ADD_USER_TO_CHAT,
        payload,
    };
}

export function newMessageRequest(payload) {
    return (dispatch) => {
        if (!payload.message && !payload.attachments) return;
        dispatch({ type: NEW_MESSAGE_REQUEST, payload });
    };
}

export function newMessageSuccess(payload) {
    return {
        type: NEW_MESSAGE_SUCCESS,
        payload,
    };
}

export function updateChat(payload) {
    return {
        type: UPDATE_CHAT,
        payload,
    };
}

export function closeChat(payload) {
    return {
        type: CLOSE_CHAT,
        payload,
    };
}

export function resetChat(payload) {
    return {
        type: RESET_CHAT
    };
}

export function disableChat() {
    return (dispatch) => {
        dispatch({
            type: DEACTIVATE_CHAT,
            payload: null,
        });
    };
}

export function setActiveChat(payload) {
    return async (dispatch) => {
        /** migrate to room redux  */
        dispatch(enterRoom(payload.roomId))

        dispatch({
            type: SET_ACTIVE_CHAT,
            payload,
        });
        dispatch(changeMobileState(MobileChatStates.CHAT));
        dispatch(setChatPagination({ skip: 0, limit: 50 }));
        dispatch(fetchChatInfo(payload.roomId));
        dispatch(fetchChatFiles(payload.roomId));
        dispatch(updateLastSeenOp({ roomId: payload.roomId, time: new Date() }));
        dispatch(setNotificationCount(0));        
    };
}

export function fetchChatList(payload) {
    return {
        type: FETCH_CHAT_LIST,
        payload,
    };
}

export function clearChatList() {
    return {
        type: CLEAR_CHAT_LIST
    };
}

export function fetchOpsChatList(payload) {
    return {
        type: FETCH_OPS_GROUPS,
        payload,
    };
}

export function setOpsChatList(payload) {
    return {
        type: SET_OPS_GROUPS,
        payload,
    };
}

export function setChatList(payload) {
    return {
        type: SET_CHAT_LIST,
        payload,
    };
}

export function setChatMessages(payload) {
    return {
        type: SET_CHAT_MESSAGES,
        payload,
    };
}

export function fetchChatMessages(payload) {
    return {
        type: FETCH_MESSAGES,
        payload,
    };
}

export function fetchChatFiles(payload) {
    return (dispatch) => {
        Axios.get(`/file/chat/${payload}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then((res) => {
                dispatch(setChatFiles({ roomId: payload, files: res.data.rows }));
            })
            .catch((err) => {
                // dispatch(registerError(err.response.data));
            });
    };
}

export function fetchChatInfo(payload) {
    return (dispatch) => {
        Axios.get(`${config.chatBackend}/chat/info/${payload}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then((res) => {
                dispatch(setChatInfo({ roomId: payload, person: res.data.person }));
                dispatch(setChatInfo({ roomId: payload, person: res.data.person }));
            })
            .catch((err) => {
                // dispatch(registerError(err.response.data));
            });
    };
}

export function setChatInfo(payload) {
    return {
        type: SET_CHAT_INFO,
        payload,
    };
}

export function setChatFiles(payload) {
    return {
        type: SET_CHAT_FILES,
        payload,
    };
}

export function setChatPagination(payload) {
    return {
        type: SET_CHAT_PAGINATION,
        payload,
    };
}

export function addChat(payload) {
    return {
        type: ADD_CHAT,
        payload,
    };
}

export function receiveMessage(payload) {
    return {
        type: RECEIVE_MESSAGE,
        payload,
    };
}

// export function openChat(payload) {
//   return {
//     type: RECEIVE_MESSAGE,
//     payload
//   }
// }

export function addNotification(payload) {
    return {
        type: ADD_NOTIFICATION,
        payload,
    };
}

const notificationThrottle = throttle((dispatch, payload) => dispatch(addNotification(payload)), 5000);

export const addNotificationThrottled = (payload) => (dispatch) => notificationThrottle(dispatch, payload);

export function removeNotification(payload) {
    return {
        type: REMOVE_NOTIFICATION,
        payload,
    };
}

export function getHistoryChat(roomId) {
    return (dispatch) => {
        Axios.get(`${process.env.REACT_APP_CHAT_BACKEND}/chat/history/info/${roomId}`, {
            params: { clientId: localStorage.getItem('clientId') },
        })
            .then((res) => {
                dispatch(setHistoryChatInfo({ chat: res.data.chat }));
            })
            .catch((err) => {
                // dispatch(registerError(err.response.data));
            });
    };
}

export function setHistoryChatInfo(payload) {
    return {
        type: SET_HISTORY_CHAT,
        payload,
    };
}

export function getHistoryChatMessages({ id, pagination = {}, date = null, word = null, replace = false }) {
    return (dispatch) => {
        Axios.get(`${process.env.REACT_APP_CHAT_BACKEND}/chat/history/messages/${id}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            params: { clientId: localStorage.getItem('clientId'), pagination, date, word },
        })
            .then((res) => {
                if (replace) {
                    dispatch(
                        setHistoryChatMessages({
                            messages: res.data.messages,
                            histGotOlder: res.data.gotOlder,
                        })
                    );
                } else {
                    dispatch(
                        addHistoryChatMessages({
                            messages: res.data.messages,
                            histGotOlder: res.data.gotOlder,
                        })
                    );
                }
            })
            .catch((err) => {
                // dispatch(registerError(err.response.data));
            });
    };
}

export function setHistoryChatMessages(payload) {
    return {
        type: SET_HISTORY_MESSAGES,
        payload,
    };
}

export function addHistoryChatMessages(payload) {
    return {
        type: ADD_HISTORY_MESSAGES,
        payload,
    };
}

export function getHistoryChatFiles(roomId) {
    return (dispatch) => {
        Axios.get(`/file/chat/${roomId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then((res) => {
                dispatch(setHistoryChatFiles({ files: res.data.rows }));
            })
            .catch((err) => {
                // dispatch(registerError(err.response.data));
            });
    };
}

export function setHistoryChatFiles(payload) {
    return {
        type: SET_HISTORY_FILES,
        payload,
    };
}

export function setHistoryDate(payload) {
    return {
        type: SET_HISTORY_DATE,
        payload,
    };
}

export function setChatHistPagination(payload) {
    return {
        type: SET_CHAT_HIST_PAGINATION,
        payload,
    };
}

export function setActiveOperators(payload) {
    return {
        type: SET_ACTIVE_OPERATORS,
        payload,
    };
}

export function openSocket() {
    return {
        type: OPEN_SOCKET,
    };
}

export function setOperatorsChats(payload) {
    return {
        type: SET_OPERATORS_CHATS,
        payload,
    };
}

export function setTyping(payload) {
    return {
        type: SET_TYPING,
        payload,
    };
}

export function clearTyping(payload) {
    return {
        type: CLEAR_TYPING,
        payload,
    };
}

export function incrementNotificationCount() {
    return {
        type: INCREMENT_NOTIFICATION_COUNT,
    };
}

export function setNotificationCount(payload) {
    return {
        type: SET_NOTIFICATION_COUNT,
        payload,
    };
}

export function setChatUser(payload) {
    return {
        type: SET_CHAT_USER,
        payload,
    };
}

export function leaveRoom(payload) {
    return {
        type: LEAVE_ROOM,
        payload,
    };
}

export function sendPoll(payload) {
    return {
        type: SEND_POLL,
        payload,
    };
}

export function setUsers(payload) {
    return {
        type: SET_USERS,
        payload,
    };
}

export function setPushState(payload) {
    return {
        type: SET_PUSH_STATE,
        payload,
    };
}

export function deleteMessage(payload) {
    return {
        type: DELETE_MESSAGE,
        payload,
    };
}

export function updateMessage(payload) {
    return {
        type: UPDATE_MESSAGE,
        payload,
    };
}

export function removeMessage(payload) {
    return {
        type: REMOVE_MESSAGE,
        payload,
    };
}

export function toggleMute() {
    return {
        type: TOGGLE_MUTE,
    };
}

export function setChatSidebar(payload) {
    return {
        type: SET_CHAT_SIDEBAR,
        payload,
    };
}

// NOT for socket middleware, just passive reaction to message changed by client
export function changeMessage(payload) {
    return {
        type: CHANGE_MESSAGE,
        payload,
    };
}

export function reconnectUserToSocket(payload) {
    return {
        type: RECONNECT_SOCKET,
        payload,
    };
}

export function setAudioPermission(payload) {
    return {
        type: SET_AUDIO_PERMISSION,
        payload,
    };
}

export function toggleSocketConnection() {
    return {
        type: TOGGLE_SOCKET_CONNECTION,
    };
}

export function setSocketConnected(payload) {
    return {
        type: SET_SOCKET_CONNECTED,
        payload,
    };
}

export function clearChatMessages() {
    return {
        type: CLEAR_CHAT_MESSAGES,
    };
}

export function setLastSeenOp(payload) {
    return {
        type: SET_LAST_SEEN_OP,
        payload,
    };
}

export function setLastSeen(payload) {
    return {
        type: SET_LAST_SEEN,
        payload,
    };
}

export function updateLastSeenOp(payload) {
    return {
        type: UPDATE_LAST_SEEN_OP,
        payload,
    };
}

export function showAutomations() {
    return {
        type: CHANGE_CHAT_WINDOW,
        payload: {
            mobileState: MobileChatStates.INFO,
            chatSidebar: ChatSideMenuStates.ELEMENTS,
        },
    };
}
export function showDialog() {
    return {
        type: CHANGE_CHAT_WINDOW,
        payload: {
            mobileState: MobileChatStates.CHAT,
        },
    };
}
export function addOpGroup(payload) {
    return {
        type: ADD_OP_GROUP,
        payload,
    };
}

export function doneOffline(payload) {
    return {
        type: DONE_OFFLINE,
        payload,
    };
}

export function pullChat(payload) {
    return {
        type: PULL_CHAT,
        payload,
    };
}

export function updatePerson(payload) {
    return {
        type: UPDATE_PERSON,
        payload,
    };
}