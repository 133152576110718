import React, { useState } from 'react';
import { Badge, Button, Input, Label, InputGroup, InputGroupAddon, Row, Col } from 'reactstrap';
import uuid from 'uuid/v4';
import { useTranslation } from 'react-i18next';

import styles from '../TicketsPage.module.scss';

const LabelsView = ({ labels, showTitle, editable, labelsChange }) => {
    const { t } = useTranslation('tickets');
    const [newLabel, setNewLabel] = useState('');

    const _renderLabels = () => {
        if (!labels || labels.length === 0) return null;

        return <div className="mb-2">
          {labels.map((label) => (
            <span key={uuid()} className={'mr-2 ' + styles.labels}>
                <Badge key={uuid()} color="primary">
                    <span>{label}</span>
                    {editable && (
                        <i
                            onClick={(e) => {
                                labelsChange(labels.filter((item) => item !== label));
                            }}
                            className="la la-close ml-2"
                        ></i>
                    )}
                </Badge>
            </span>
        ))}
        </div>
    };

    return (
        <Row>
            <Col sm={12}>
                {showTitle && <Label>{t('Теги')}</Label>}
                <div>
                    {_renderLabels()}

                    {editable && (
                        <InputGroup>
                            <Input
                                placeholder="Введіть тег"
                                value={newLabel}
                                onChange={(e) => {
                                    setNewLabel(e.target.value);
                                }}
                            />
                            <InputGroupAddon addonType="append" className={styles.addBtn}>
                                <Button
                                    color="success"
                                    onClick={(e) => {
                                        if (
                                            newLabel &&
                                            newLabel !== '' &&
                                            (!labels || labels.indexOf(newLabel) === -1)
                                        ) {
                                            if (labelsChange) {
                                                labelsChange(labels ? [...labels, newLabel] : [newLabel]);
                                            }
                                            setNewLabel('');
                                        }
                                    }}
                                >
                                    <i className="la la-plus"></i>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default LabelsView;
