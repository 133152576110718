import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session'

import auth from './auth';
import navigation from './navigation';
import alerts from './alerts';
import layout from './layout';
import register from './register';
import chat from './chat';
import statistics from './statistics';
import settings from './settings';
import tickets from '../tickets/reducers';
import payments from '../payments/reducers';
import chatsearch from '../chat-search/reducers';
import personalNotifications from './personal-notifications';
import clientNotifications from './client-notifications';
import joyride from './joyride';
import room from '../active-room/reducers';
import sms from '../sms/reducers';

const chatPersistConfig = {
  key: 'chat',
  storage: storage,
  whitelist: ['user', 'users', 'activeChatId', 'pushEnabled']
}
const chatPersist = persistReducer(chatPersistConfig, chat);

const settingsPersistConfig = {
  key: 'settings',
  storage: storage,
  blacklist: ['loaded']
}
const settingsPersist = persistReducer(settingsPersistConfig, settings);

const roomPersistConfig = {
  key: 'room',
  storage: sessionStorage,
  whitelist: ['roomId']
}
const roomPersist = persistReducer(roomPersistConfig, room);

export default combineReducers({
  alerts,
  auth,
  navigation,
  layout,
  register,
  chat: chatPersist,
  statistics,
  settings: settingsPersist,
  tickets,
  joyride,
  payments, 
  chatsearch,
  personalNotifications,
  clientNotifications,
  room: roomPersist,
  sms
});
