const hostApi = process.env.NODE_ENV === "development" ? "http://localhost" : process.env.REACT_APP_BACKEND;
const chatApi = process.env.NODE_ENV === "development" ? "http://localhost:8080" : process.env.REACT_APP_CHAT_BACKEND;


const portApi = process.env.NODE_ENV === "development" ? 9090 : "";
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}`;

const chatAppUrl = process.env.NODE_ENV === "development" ? "http://localhost:3030" : process.env.REACT_APP_CHAT_WIDGET

const config = {
  hostApi,
  portApi,
  baseURLApi,
  chatAppUrl,
  chatBackend: chatApi,
  appVersion: process.env.REACT_APP_VERSION,
  app: {
    colors: {
      dark: "#002B49",
      light: "#FFFFFF",
      sea: "#004472",
      sky: "#E9EBEF",
      wave: "#D1E7F6",
      rain: "#CCDDE9",
      middle: "#D7DFE6",
      black: "#13191D",
      salat: "#21AE8C",
    },
  }
};

export default config;