import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import s from './Commentator.module.scss';
import * as moment from 'moment';
import {toast} from 'react-toastify';
import { useTranslation } from 'react-i18next';


const Commentator = ({ entityId, entityType, isJR }) => {
    const { t } = useTranslation('common');

    const [text, setText] = useState('');
    const [comments, setComments] = useState([]);
    const [total, setTotal] = useState(0);

    const [showModal, setShowModal] = useState(false);

    const getComments = useCallback(
        async (scope = 'latest') => {
            if (isJR) return;
            try {
                const { data } = await Axios.get('/entity/EntityComment', {
                    params: {
                        scope,
                        where: { entityId: String(entityId), entityType },
                        limit: 1000
                    },
                });
                setComments(data.rows);
                setTotal(data.count)
            } catch (error) { }
        },
        [entityId, entityType, isJR]
    );

    const saveComment = useCallback(async () => {
        if (isJR) return;
        try {
            if (!text || text === '') 
                toast.error(t('Введіть текст повідомлення'))

            await Axios.post('/entity/EntityComment', {
                text,
                entityId,
                entityType,
            });
            setText('');
            toast.success(t('Повідомлення надіслано'));
            getComments()
        } catch (error) { 
            toast.error(t('Повідомлення не збережено'))
        }
    }, [text, entityId, entityType, getComments, setText, isJR, t]);

    const showAll = useCallback(
        async () => {
            await getComments('all');
            setShowModal(true);
        },
        [getComments, setShowModal],
    )

    const closeModal = useCallback(
        async () => {
            await getComments();
            setShowModal(false);
        },
        [getComments, setShowModal],
    )

    useEffect(() => {
        getComments();
    }, [entityId, entityType, getComments]);

    /** Renders */
    const _oneComment = (comment, idx) => (
        <div className={s.comment} key={`${entityType}_${entityId}_${idx}`}>
            <div className={s.text}>{comment.text}</div>
            <div className={s.authorTime}>
                <div className={s.author}>{comment.user.name}</div>
                <div className={s.time}>{moment(comment.createdAt).format('DD-MM-YY')}</div>
            </div>
        </div>
    );

    const _showMore = () => {
        if (total <= 3) 
            return null;

        return (
            <div id="render-more" className="text-center mt-2">
                <Button size="sm" color="link" onClick={showAll}>
                    <i className="la-angle-down la" />
                    &nbsp;{t('всі коментарі')}
                </Button>
            </div>
        );
    }

    return (
        <div>
            <Modal isOpen={showModal} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>{t('Коментарі')}</ModalHeader>
                <ModalBody style={{backgroundColor: '#FFF'}}>
                    {comments.map(_oneComment)}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={closeModal}>
                        {t('OK')}
                    </Button>
                </ModalFooter>
            </Modal>
            <div className="commentsList">
                {comments.slice(0, 3).map(_oneComment)}
            </div>
            {_showMore()}
            <hr />
            <div>
                <FormGroup>
                    <Input type="textarea" value={text} onChange={(e) => setText(e.target.value)} />
                </FormGroup>
                <FormGroup className="text-center">
                    <Button color="info" onClick={saveComment}>{t('Коментувати')}</Button>
                </FormGroup>
            </div>
        </div>
    );
};

Commentator.propTypes = {
    entityId: PropTypes.any.isRequired,
    entityType: PropTypes.string.isRequired,
};

export default Commentator;
