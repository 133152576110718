import { APPEND_TICKET_MESSAGE, INCREMENT_TICKET_COUNTER, NEW_TICKET, RESET_TICKET_COUNTER, SET_TICKETS_PAGE, SET_TICKETS_SIZE, SET_TICKETS_TOTAL_PAGES, SET_TICKET_LIST, TICKET_UPDATE, } from "./types";

const initialState = {
    tickets: [],
    newTicketsCount: 0,
    totalTickets: 0,
    totalTicketPages: 0,
    ticketPage: 0
};

export default function runtime(state = initialState, action) {
    switch (action.type) {
        case SET_TICKET_LIST:
            return {
                ...state,
                tickets: action.payload,
            };
        case SET_TICKETS_SIZE:
            return {
                ...state,
                totalTickets: action.payload,
            };
        case SET_TICKETS_TOTAL_PAGES:
            return {
                ...state,
                totalTicketPages: action.payload,
            };
        case SET_TICKETS_PAGE:
            return {
                ...state,
                ticketPage: action.payload,
            };
        case NEW_TICKET:
            return Object.assign({}, state, {
                tickets: [action.payload, ...state.tickets]
            });
        case INCREMENT_TICKET_COUNTER:
            const { newTicketsCount } = state;
            return Object.assign({}, state, {
                newTicketsCount: newTicketsCount + 1
            });
        case RESET_TICKET_COUNTER:
            return Object.assign({}, state, {
                newTicketsCount: 0
            });
        case APPEND_TICKET_MESSAGE:
            const ticketId = action.payload.ticketId;
            const tickets = state.tickets.map(ticket => {
                if (ticket.id !== ticketId)
                    return ticket
                
                let messages = ticket.messages.length === 0 ? [] : [...ticket.messages];
                const messageId = action.payload.id;
                const pos = ticket.messages.findIndex(m => m.id === messageId);
                if (pos === -1) {
                    messages.push(action.payload);
                } else {
                    messages[pos] = action.payload;
                }

                return {...ticket, messages};
            })
            return Object.assign({}, state, {
                tickets
            });
        case TICKET_UPDATE:
            return Object.assign({}, state, {
                tickets: state.tickets.map(ticket => {
                    if (ticket.id !== action.payload.id)
                        return ticket
                    
                    delete action.payload.messages;
    
                    return {...ticket, ...action.payload};
                })
            });
        default:
            return state;
    }
}
