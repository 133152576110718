import Axios from 'axios';
import React, { Fragment, useCallback, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
    Button, FormText,
    Label, Modal, ModalBody,
    ModalFooter, ModalHeader
} from 'reactstrap';

const TicketMerger = ({ ticket, block = false, isJR }) => {
    const { t } = useTranslation('tickets');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const filterBy = () => true;
    const fetchTickets = useCallback(
        async (query) => {
            try {
                setIsLoading(true);
                const { data } = await Axios.get('/entity/Ticket', {
                    params: {
                        where: { topic: { $like: `%${query}%` }, id: { $ne: ticket.id } },
                    },
                });
                setOptions(data.rows);
            } catch (error) {
                console.error(error);
            }
            setIsLoading(false);
        },
        [setIsLoading, setOptions, ticket]
    );

    const mergeTickets = useCallback(async () => {
        try {
            await Axios.patch(`/entity/Ticket/${ticket.id}/mergeWith`, {
                ticketId: selected[0].id
            })
            toast.success(t('Тікети обєднано'));
            setShowModal(false);
        } catch (error) {
            console.error(error);
            toast.error(t('Помилка підчас обєднання'))
        }
    }, [ticket, selected, t]);

    return (
        <div>
            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                <ModalHeader toggle={() => setShowModal(false)}>{t('Об\'єднання тікетів')}</ModalHeader>
                <ModalBody style={{ backgroundColor: '#FFF' }}>
                    <Label>{t('Тікет для об\'єднання')}</Label>
                    <AsyncTypeahead
                        filterBy={filterBy}
                        id="async-example"
                        isLoading={isLoading}
                        labelKey="topic"
                        minLength={3}
                        onSearch={fetchTickets}
                        options={options}
                        placeholder={t('Пошук тікетів...')}
                        searchText={t('Шукаємо тікети...')}
                        promptText={t('Почніть вводити назву')}
                        emptyLabel={t('За вашим запитом тікетів не знайдено')}
                        onChange={(selected) => {
                            setSelected(selected);
                        }}
                        selected={selected}
                        renderMenuItemChildren={(option, props) => (
                            <Fragment>
                                <span>{option.topic}</span>
                            </Fragment>
                        )}
                    />
                    <FormText>{t('Вкажіть тікет з яким бажаєте об\'єднати.')}</FormText>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setShowModal(false)}>
                        {t('Відміна')}
                    </Button>
                    <Button color="primary" onClick={mergeTickets}>
                        {t('Об\'єднати')}
                    </Button>
                </ModalFooter>
            </Modal>
            <Button color="primary" onClick={() => {if (isJR) return; setShowModal(true)}} block={block}>
                {t('Об\'єднати тікети')}
            </Button>
        </div>
    );
};

export default TicketMerger;
