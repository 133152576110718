import React, { useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, Modal, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Sharer = ({ trigger, link = '', open, setOpen }) => {
    const { t } = useTranslation('common');
    const [_open, _setOpen] = useState(false);
    const [_link, setLink] = useState(link);

    const _handleClick = () => {
        setLink(window.location.href);
        setOpen(true);
    };

    const handleCopyLink = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(_link);
        }
    };

    const toggle = () => typeof setOpen !== 'undefined' ? setOpen(!open) : _setOpen(!_open)

    useEffect(() => {
        setLink(link);
    }, [link]);
    
    return (
        <>
            <Modal
                onClosed={() => (typeof setOpen !== 'undefined' ? setOpen(false) : _setOpen(false))}
                toggle={toggle}
                isOpen={typeof open !== 'undefined' ? open : _open}
                centered={true}
            >
                <ModalBody>
                    <InputGroup>                        
                        <Input
                            readOnly
                            value={_link}
                            action={{
                                color: 'green',
                                labelPosition: 'right',
                                icon: 'copy',
                                content: t('Скопіювати'),
                                onClick: handleCopyLink,
                            }}
                        ></Input>
                        <InputGroupAddon addonType="append">
                            <Button color="success" onClick={handleCopyLink}>{t('Копіювати')}</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </ModalBody>
            </Modal>
            {trigger && React.cloneElement(trigger, { onClick: _handleClick })}
        </>
    );
};

Sharer.propTypes = {};

export default Sharer;
