import { useCallback, useMemo, useReducer, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';

import { shortEditorConfiguration } from '../utils/ckeditor-confs';

export const useForm = ({ fields, validate, formFields }) => {
    const flatFields = useCallback(
        (formFields, defaultValues = {}) =>
            formFields.reduce(
                (s, item) => ({
                    ...s,
                    [item.name]: defaultValues[item.name] ? defaultValues[item.name] : item.default ?? '',
                }),
                {}
            ),
        []
    );

    const [fieldsObject] = useState(() => {
        if (fields) return fields;
        if (formFields) {
            return flatFields(formFields);
        }
        return {};
    });

    const initial = useMemo(() => ({
        values: {
            ...fieldsObject,
        },
        errors: Object.keys(fieldsObject).reduce((payload, field) => ({ ...payload, [field]: false }), {}),
        ready: false,
        pristine: true
    }), [fieldsObject]);

    function formReducer(state, action) {
        switch (action.type) {
            case 'READY':
                return { ...state, ready: true };
            case 'UPDATE_FIELD':
                return { ...state, values: { ...state.values, ...action.payload } };
            case 'SET_ERRORS':
                return { ...state, errors: { ...state.errors, ...action.payload } };
            case 'CLEAN_FORM':
                return initial;
            default:
                throw new Error();
        }
    }

    const [formState, dispatch] = useReducer(formReducer, initial);
    const { values, errors } = formState;

    const handleFieldChange = useCallback(
        (e) => {
            const { name, value } = e.target;
            dispatch({
                type: 'UPDATE_FIELD',
                payload: { [name]: value },
            });
            if (validate) {
                dispatch({
                    type: 'SET_ERRORS',
                    payload: { ...validate(name, value, values) },
                });
            }
        },
        [dispatch, validate, values]
    );

    const handleChangeQuill = useCallback(
        (value, name) => {
            dispatch({
                type: 'UPDATE_FIELD',
                payload: { [name]: value },
            });
            if (validate) {
                dispatch({
                    type: 'SET_ERRORS',
                    payload: { [name]: validate(name, value) },
                });
            }
        },
        [dispatch, validate]
    );

    const isValid = useCallback(
        () => {
            if (Object.values(errors).filter(i => i).length > 0) return false;
            if (Object.values(values).filter(i => i !== '').length === 0) return false;
            return true
        },
        [errors, values],
    )

    const renderField = (field, index) => {
        switch (field.type) {
            case 'checkbox':
                return ( 
                    <FormGroup check key={index} className="mb-3 position-relative">
                        <Label check>
                            <Input
                                name={field.name}
                                checked={values[field.name]}
                                type={field.type}
                                onChange={handleFieldChange}
                                invalid={errors[field.name] !== false}
                            />{' '}
                            {field.lable}
                        </Label>
                        {field.info && <FormText>{field.info}</FormText>}
                        {typeof errors[field.name] === 'string' && (
                            <FormFeedback tooltip>{errors[field.name]}</FormFeedback>
                        )}
                    </FormGroup>
                );
            case 'richtext':
                return (
                    <FormGroup key={index} className="position-relative">
                        <Label>{field.lable}</Label>
                        <CKEditor
                            editor={Editor}
                            data={values[field.name]}
                            config={shortEditorConfiguration}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleChangeQuill(data, field.name);
                            }}
                        />
                        {field.info && <FormText>{field.info}</FormText>}
                        {typeof errors[field.name] === 'string' && <FormFeedback tooltip>{errors[field.name]}</FormFeedback>}
                    </FormGroup>
                );

            default:
                return (
                    <FormGroup key={index} className="mb-3 position-relative">
                        <Label>{field.lable}</Label>
                        <Input
                            name={field.name}
                            value={values[field.name]}
                            type={field.type}
                            onChange={handleFieldChange}
                            invalid={errors[field.name] !== false}
                        />
                        {field.info && <FormText>{field.info}</FormText>}
                        {typeof errors[field.name] === 'string' && <FormFeedback tooltip>{errors[field.name]}</FormFeedback>}
                    </FormGroup>
                );
        }
    };

    return { formState, dispatch, handleFieldChange, renderField, flatFields, isValid };
};
