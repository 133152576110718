import { SET_IS_UPDATING, SET_PN_LIST, SET_PN_ARCHIVE, SET_PN_NOT_SEEN } from '../actions/personal-notifications';

const defaultState = {
  list: [],
  archive: [],
  lastUpdated: null,
  isUpdating: false,
  notSeen: 0
};

export default function personalNotificationsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_IS_UPDATING:
      return Object.assign({}, state, {
        isUpdating: action.payload
      });
    case SET_PN_LIST:
      return Object.assign({}, state, {
        list: action.payload,
        lastUpdated: new Date()
      });
    case SET_PN_ARCHIVE:
      return Object.assign({}, state, {
        archive: action.payload
      });
    case SET_PN_NOT_SEEN:
      return Object.assign({}, state, {
        notSeen: action.payload
      });
    default:
      return state;
  }
}
