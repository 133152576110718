import * as Sentry from "@sentry/react";
import axios from 'axios';

export const SET_IS_UPDATING = 'SET_IS_UPDATING';
export const SET_PN_LIST = 'SET_PN_LIST';
export const SET_PN_ARCHIVE = 'SET_PN_ARCHIVE';
export const SET_PN_NOT_SEEN = 'SET_PN_NOT_SEEN';

export function getPersonalNotifications() {
  return (dispatch) => {
    dispatch(setIsUpdating(true));
    axios.get('/entity/PersonalNotification',
      {
        params: {
          where: {
            userId: Number(localStorage.getItem('uid')),
            disabled: false,
            seen: false
          },
          includes: ['LocalFile']
        }
      }).then(res => {
        if (res.data.rows) {
          dispatch(setList(res.data.rows));
          dispatch(setNotSeen(res.data.rows.length));
        }
        dispatch(setIsUpdating(false));
      }).catch(error => {
        Sentry.captureException(error);
      })
  };
}

export function getArchiveNotifications() {
  return (dispatch) => {
    axios.get('/entity/PersonalNotification',
      {
        params: {
          where: {
            userId: Number(localStorage.getItem('uid')),
            seen: true
          },
          limit: 10,
          includes: ['LocalFile']
        }
      }).then(res => {
        if (res.data.rows) {
          dispatch(setArchive(res.data.rows));
        }
      }).catch(error => {
        Sentry.captureException(error);
      })
  };
}

export function refreshNotificationsAsync() {
  return async (dispatch) => {
    const newNotifications = await axios.get('/entity/PersonalNotification',
      {
        params: {
          where: { userId: Number(localStorage.getItem('uid')), disabled: false, seen: false }, limit: 10, includes: ['LocalFile']
        }
      });
    const oldNotifications = await axios.get('/entity/PersonalNotification',
      {
        params: {
          where: { userId: Number(localStorage.getItem('uid')), seen: true }, limit: 10, includes: ['LocalFile']
        }
      });
    return Promise.all([
      dispatch(setList(newNotifications.data.rows)),
      dispatch(setNotSeen(newNotifications.data.rows.length)),
      dispatch(setArchive(oldNotifications.data.rows))
    ])
  }
}

export function setList(payload) {
  return {
    type: SET_PN_LIST,
    payload
  }
}

export function setArchive(payload) {
  return {
    type: SET_PN_ARCHIVE,
    payload
  }
}

export function setIsUpdating(payload) {
  return {
    type: SET_IS_UPDATING,
    payload
  }
}

export function setNotSeen(payload) {
  return {
    type: SET_PN_NOT_SEEN,
    payload
  }
}