import React from 'react';
import { Redirect } from 'react-router';

import ChatHistoryBreadcrumb from './components/Layout/BreadcrumbGetters/ChatBreadcrumb'
import ChatPage from './pages/chat/Chat';
import StatisticsPage from './pages/statistics/StatisticsPage';
import TicketsPage from './pages/tickets/TicketsPage';
import TicketViewPage from './pages/tickets/TicketViewPage';
import SubmenuRoute from './components/Layout/SubmenuRoute';

/** Lazy routes */
const FaqPage = React.lazy(() => import('./pages/faq/FaqPage'));
const FilesPage = React.lazy(() => import('./pages/files/FilesPage'));
const KbPage = React.lazy(() => import('./pages/kb/KbPage'));

const HistoryChat = React.lazy(() => import('./pages/statistics/history/components/historyChat/HistoryChat'));
const HistoryPage = React.lazy(() => import('./pages/statistics/history/HistoryPage'));
const PollReviewsPage = React.lazy(() => import('./pages/statistics/poll-reviews/PollReviewsPage'));

const BotEditor = React.lazy(() => import('./pages/bot/BotEditor'));
const BotPage = React.lazy(() => import('./pages/bot/BotPage'));
const ClientProfilePage = React.lazy(() => import('./pages/settings/client-profile/ClientProfilePage'));
const DomainsPage = React.lazy(() => import('./pages/domains/DomainsPage'));
const DomainView = React.lazy(() => import('./pages/domains/DomainView'));
const KbSettings = React.lazy(() => import('./pages/kb/KbSettings'));
const KbViewPage = React.lazy(() => import('./pages/kb/KbViewPage'));
const OperatorsPage = React.lazy(() => import('./pages/settings/operators/OperatorsPage'));
const ProfilePage = React.lazy(() => import('./pages/profile/ProfilePage'));
const ReplySettingsPage = React.lazy(() => import('./pages/reply-settings/ReplySettingsPage'));
const ClientsStatsPage = React.lazy(() => import('./pages/statistics/ClientsStatsPage'));
const OperatorsStatsPage = React.lazy(() => import('./pages/statistics/OperatorsStatsPage'));
const WidgetPage = React.lazy(() => import('./pages/widget/WidgetPage'));
const WidgetTestPage = React.lazy(() => import('./pages/domains/widget-test/WidgetTest'));
const InvoicesPage = React.lazy(() => import('./pages/payments/InvoicesPage'));
const ServicesPage = React.lazy(() => import('./pages/payments/ServicesPage'));
const OrderPage = React.lazy(() => import('./pages/payments/OrderPage'));
const InvoicesPayPage = React.lazy(() => import('./pages/payments/InvoicesPayPage'));
// const SubmenuRoute = React.lazy(() => import('./components/Layout/SubmenuRoute'));
const ProactiveDialogPage = React.lazy(() => import('./pages/settings/proactive/ProactiveDialogPage'));
const ProactiveDialogView = React.lazy(() => import('./pages/settings/proactive/ProactiveDialogView'));
const ApiTokenPage = React.lazy(() => import('./pages/settings/api-token/ApiTokenPage'));
const ContactsPage = React.lazy(() => import('./pages/contacts/ContactsPage'));
const SmsServicesPage = React.lazy(() => import('./pages/settings/sms-services/SmsServicesPage'));

const WidgetSettingsPage = React.lazy(() => import('./pages/domains/widget/WidgetView'));
const MailSettingsPage = React.lazy(() => import('./pages/domains/mail-settings/MailSettingsView'));
const OfflineSettingsPage = React.lazy(() => import('./pages/domains/offline-settings/OfflineSettings'));
const DomainSourcesPage = React.lazy(() => import('./pages/domains/sources/SouresView'));
const TranslationsPage = React.lazy(() => import('./pages/translations/TranslationsPage'));
const TranslationViewPage = React.lazy(() => import('./pages/translations/TranslationViewPage'));

/** /Lazy routes */

export const routes = [
    {
        path: '/',
        exact: true,
        breadcrumb: 'Головна',
        render: () => <Redirect to="/statistics" />,
        tier: [1,2,3]
    },
    {
        path: '/statistics/operators',
        exact: true,
        component: OperatorsStatsPage,
        breadcrumb: 'Статистика',
        tier: [1,2,3]
    },
    {
        path: '/statistics/clients',
        exact: true,
        component: ClientsStatsPage,
        breadcrumb: 'Статистика чатів',
        tier: [1,2,3]
    },
    {
        path: '/statistics',
        exact: true,
        component: StatisticsPage,
        breadcrumb: 'Статистика',
        tier: [1,2,3]
    },
    {
        path: '/statistics/all',
        exact: true,
        component: StatisticsPage,
        breadcrumb: 'Всі виклики',
        tier: [1,2,3]
    },
    {
        path: '/history',
        exact: true,
        component: HistoryPage,
        breadcrumb: 'Історія',
        tier: [2,3]
    },
    {
        path: '/history/view',
        exact: true,
        breadcrumb: 'Перегляд',
        render: () => <Redirect to="/history" />,
        tier: [2,3]
    },
    {
        path: '/history/view/:id',
        exact: true,
        component: HistoryChat,
        breadcrumb: ChatHistoryBreadcrumb,
        tier: [2,3]
    },
    {
        path: '/reviews',
        exact: true,
        component: PollReviewsPage,
        breadcrumb: 'Відгуки',
        tier: [2,3]
    },
    {
        path: '/chat',
        exact: true,
        component: ChatPage,
        breadcrumb: 'Чати',
        tier: [1,2,3]
    },
    {
        path: '/files',
        exact: true,
        component: FilesPage,
        breadcrumb: 'Файли',
        tier: [1,2,3]
    },
    {
        path: '/bot',
        exact: true,
        component: BotPage,
        breadcrumb: 'Сценарії бота',
        tier: [2,3]
    },
    {
        path: '/bot/faq',
        exact: true,
        component: FaqPage,
        breadcrumb: 'Налаштування FAQ',
        tier: [2,3]
    },
    {
        path: '/bot/:id',
        exact: true,
        component: BotEditor,
        breadcrumb: 'Налаштування бота',
        tier: [2,3]
    },
    {
        path: '/profile',
        exact: true,
        component: ProfilePage,
        breadcrumb: 'Профіль',
        tier: [1,2,3]
    },
    {
        path: '/tickets/:id',
        exact: true,
        component: TicketViewPage,
        breadcrumb: ({ match }) => <span>Тікет # {match.params.id}</span>,
        tier: [2,3]
    },
    {
        path: '/tickets',
        exact: true,
        component: TicketsPage,
        breadcrumb: 'Тікети',
        tier: [2,3]
    },
    {
        path: '/settings',
        exact: true,
        breadcrumb: 'Налаштування',
        render: () => <Redirect to="/settings/domains" />,
        tier: [1,2,3]
    },
    {
        path: '/settings/widget',
        exact: true,
        component: WidgetPage,
        breadcrumb: 'Віджети',
        tier: [1,2,3]
    },
    {
        path: '/settings/replies',
        exact: true,
        component: ReplySettingsPage,
        breadcrumb: 'Швидкі відповіді',
        tier: [1,2,3]
    },
    {
        path: '/settings/operators/:id',
        exact: true,
        component: ProfilePage,
        breadcrumb: ({ match }) => {
            if (match.params.id === 'new') return 'Новий';

            return <span>Оператор # {match.params.id}</span>
        },
        tier: [1,2,3]
    },
    {
        path: '/settings/operators',
        exact: true,
        component: OperatorsPage,
        breadcrumb: 'Оператори',
        tier: [1,2,3]
    },
    {
        path: '/settings/domains',
        exact: true,
        component: DomainsPage,
        breadcrumb: 'Домени',
        tier: [1,2,3]
    },
    {
        path: '/settings/domains3/:id',
        exact: true,
        component: DomainView,
        breadcrumb: ({ match }) => <span>Домен # {match.params.id}</span>,
        tier: [1,2,3]
    },
    {
        path: '/settings/domains/:id',
        component: SubmenuRoute,
        breadcrumb: 'Налаштування домену',
        tier: [1,2,3],
        routes: [
            {
                path: "/settings/domains/:id/widget",
                component: WidgetSettingsPage,
                breadcrumb: 'Віджет',
            },
            {
                path: "/settings/domains/:id/sources",
                component: DomainSourcesPage,
                breadcrumb: 'Налаштування джерел'
            },
            {
                path: "/settings/domains/:id/ticket",
                component: MailSettingsPage,
                breadcrumb: 'Тікет система'
            },
            {
                path: "/settings/domains/:id/offline",
                component: OfflineSettingsPage,
                breadcrumb: 'Офлайн налаштування'
            }
        ]
    },
    {
        path: '/settings/sms-services',
        exact: true,
        component: SmsServicesPage,
        breadcrumb: 'Налаштування смс',
        tier: [1,2,3]
    },
    {
        path: '/kb/settings',
        exact: true,
        component: KbSettings,
        breadcrumb: 'Налаштування',
        tier: [2,3]
    },
    {
        path: '/kb',
        exact: true,
        component: KbPage,
        breadcrumb: 'Сторінки справки',
        tier: [2,3]
    },
    {
        path: '/kb/:id',
        exact: true,
        component: KbViewPage,
        breadcrumb: ({ match }) => <span>Сторінка # {match.params.id}</span>,
        tier: [2,3]
    },
    {
        path: '/settings/account',
        exact: true,
        component: ClientProfilePage,
        breadcrumb: 'Профіль',
        tier: [1,2,3]
    },
    
    {
        path: '/settings/widget/view/',
        exact: true,
        breadcrumb: 'Ваш віджет',
        render: () => <Redirect to="/settings/domains" />,
        tier: [1,2,3]
    },
    {
        path: '/settings/widget/view/:widgetId',
        exact: true,
        component: WidgetTestPage,
        breadcrumb: 'Перегляд',
        tier: [1,2,3]
    },
    {
        path: '/payments/order',
        component: OrderPage,
        breadcrumb: 'Замовити',
        tier: [1,2,3]
    },
    {
        path: "/payments/invoices/:invoiceId",
        component: InvoicesPayPage,
        breadcrumb: 'Рахунок',
        exact: true,
        tier: [1,2,3]
    },
    {
        path: '/payments',
        component: SubmenuRoute,
        breadcrumb: 'Підписка',
        tier: [1,2,3],
        routes: [
            {
                path: "/payments/services",
                component: ServicesPage,
                breadcrumb: 'Замовлені послуги',
            },
            {
                path: "/payments/invoices",
                component: InvoicesPage,
                breadcrumb: 'Рахунки',
                exact: true,
            }
        ]
    },
    {
        path: '/settings/proactive',
        component: ProactiveDialogPage,
        exact: true,
        breadcrumb: 'Стартові діалоги',
        tier: [2,3]
    },
    {
        path: '/settings/proactive/:id',
        component: ProactiveDialogView,
        breadcrumb: 'Перегляд діaлогу',
        tier: [2,3]
    },    
    {
        path: '/settings/api-token',
        component: ApiTokenPage,
        exact: true,
        breadcrumb: 'API токени',
        tier: [2,3]
    },
    {
        path: '/settings/translations',
        exact: true,
        component: TranslationsPage,
        breadcrumb: 'Переклади',
        tier: [1,2,3]
    },
    {
        path: '/settings/translations/new',
        exact: true,
        component: TranslationViewPage,
        breadcrumb: 'Новий переклад',
        tier: [1,2,3]
    },
    {
        path: '/settings/translations/:id',
        exact: true,
        component: TranslationViewPage,
        breadcrumb: 'Редагувати переклад',
        tier: [1,2,3]
    },
    {
        path: '/contacts',
        component: ContactsPage,
        exact: true,
        breadcrumb: 'Контакти',
        tier: [1,2,3]
    },
];

export const tierRoutes = {
    '1': routes.filter(r => r.tier.indexOf(1) !== -1),
    '2': routes.filter(r => r.tier.indexOf(2) !== -1),
    '3': routes.filter(r => r.tier.indexOf(3) !== -1)
}