import isScreen from '../../core/screenHelper';
import { 
  TOGGLE_SIDEBAR, 
  OPEN_SIDEBAR, 
  CLOSE_SIDEBAR, 
  CHANGE_ACTIVE_SIDEBAR_ITEM, SET_DYNAMIC, SET_STATIC
} from '../actions/navigation';

const initialState = {
  sidebarOpened: false,
  sidebarStatic: isScreen('xl') ?? false,
  activeItem: String(window.location.hash).substr(1) || '/',
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarStatic: !state.sidebarStatic,
      };
    case SET_STATIC:
      return {
        ...state,
        sidebarStatic: true,
      };
    case SET_DYNAMIC:
      return {
        ...state,
        sidebarStatic: false,
      };
    case OPEN_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: true,
      });
    case CLOSE_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: false,
      });
    case CHANGE_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeItem: action.activeItem,
      };
    default:
      return state;
  }
}
