import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import jwt from 'jsonwebtoken';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useLocation, useHistory } from 'react-router-dom';
import { Alert, Button, Container } from 'reactstrap';
import Widget from '../../components/Widget';
import { loginUser, receiveToken } from '../../redux/actions/user';
import SocialLoginButton from './components/SocialLoginButton';

const Login = () => {
    const { t } = useTranslation('registration');
    const location = useLocation();
    const history = useHistory();

    /** Redux */
    const dispatch = useDispatch();
    const { isFetching, isAuthenticated, errorMessage } = useSelector((state) => {
        return {
            isFetching: state.auth.isFetching,
            isAuthenticated: state.auth.isAuthenticated,
            errorMessage: state.auth.errorMessage,
        };
    });

    /** State */
    const [form, setForm] = useState({
        email: '',
        password: '',
    });

    /** Fn */
    // const isAuthenticated = (token) => {
    //     if (!token) return;
    //     const date = new Date().getTime() / 1000;
    //     const data = jwt.decode(token);
    //     return date < data.exp;
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const doLogin = (e) => {
        e.preventDefault();
        dispatch(loginUser({ email: form.email, password: form.password }));
    };

    const handleNewUser = ({ email }) => {
        history.push({
            pathname: '/register',
            state: { email }
        });
    };

    /** Effects */

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            dispatch(receiveToken(token));
        }
    }, [dispatch, location]);

    /** Renders */

    const renderError = (errorMessage) => {
        const errors = {
            'Incorrect password': t('Пароль некоректний'),
            'User not found': t('Користувача не знайдено')
        };
        return errors[errorMessage] ?? t('Помилка авторизації');
    }

    const { from } = location.state || { from: { pathname: '/' } }; // eslint-disable-line

    // cant access login page while logged in
    if (isAuthenticated) {
        return <Redirect to={from} />;
    }

    return (
        <div className="auth-page">
            <Container>
                <h5 className="auth-logo">
                    <img src="/images/shark-chat.svg" alt="" width="180" />
                </h5>
                <Widget className="widget-auth mx-auto" title={<h3 className="mt-0">{t('Вхід')}</h3>}>
                    <p className="widget-auth-info">{t('Вкажіть вашу електронну адресу для входу')}</p>
                    <form className="mt" onSubmit={doLogin}>
                        {errorMessage && (
                            <Alert className="alert-sm" color="danger">
                                {renderError(errorMessage)}
                            </Alert>
                        )}
                        <div className="form-group">
                            <input
                                className="form-control no-border"
                                value={form.email}
                                onChange={handleChange}
                                type="email"
                                required
                                name="email"
                                placeholder={t('Електронна пошта')}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                className="form-control no-border"
                                value={form.password}
                                onChange={handleChange}
                                type="password"
                                required
                                name="password"
                                placeholder={t('Пароль')}
                            />
                        </div>

                        <Button type="submit" color="info" className="auth-btn mb-3" size="sm">
                            {isFetching ? t('Завантаження...') : t('Увійти')}
                        </Button>

                        <p className="widget-auth-info">{t('або авторизуйтесь через')}</p>
                        <div className="social-buttons">
                            <SocialLoginButton setError={renderError} type={'google'} next={handleNewUser} />
                            <SocialLoginButton setError={renderError} type={'facebook'} next={handleNewUser} />
                        </div>
                    </form>
                    <Link className="d-block text-center" to="reset">
                        {t('Забули пароль?')}
                    </Link>
                    <p className="widget-auth-info">{t('Ще не маєте акаунта? Зареєструйтеся!')}</p>
                    <Link className="d-block text-center" to="register">
                        {t('Створити акаунт')}
                    </Link>

                </Widget>
            </Container>
        </div>
    );
};

export default Login;
