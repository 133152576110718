import {
    ADD_CHAT, ADD_HISTORY_MESSAGES, ADD_NOTIFICATION, CHANGE_CHAT_WINDOW, CHANGE_MESSAGE, CHANGE_MOBILE_STATE, CLEAR_CHAT_MESSAGES, CLEAR_TYPING, CLOSE_CHAT, DEACTIVATE_CHAT, INCREMENT_NOTIFICATION_COUNT, LEAVE_ROOM, NEW_MESSAGE_REQUEST, NEW_MESSAGE_SUCCESS, OPEN_USERS_LIST, RECEIVE_MESSAGE, REMOVE_MESSAGE, REMOVE_NOTIFICATION, SET_ACTIVE_CHAT, SET_ACTIVE_OPERATORS, SET_AUDIO_PERMISSION, SET_CHAT_FILES, SET_CHAT_HIST_PAGINATION, SET_CHAT_INFO, SET_CHAT_LIST,
    SET_CHAT_MESSAGES, CLEAR_CHAT_LIST, SET_CHAT_PAGINATION, SET_CHAT_SIDEBAR, SET_CHAT_USER, SET_HISTORY_CHAT, SET_HISTORY_DATE, SET_HISTORY_FILES, SET_HISTORY_MESSAGES, SET_LAST_SEEN, SET_LAST_SEEN_OP, SET_NOTIFICATION_COUNT, SET_OPERATORS_CHATS, SET_OPS_GROUPS, SET_PUSH_STATE, SET_SOCKET_CONNECTED, SET_TYPING, SET_USERS, TOGGLE_MUTE, UPDATE_CHAT, UPDATE_LAST_SEEN_OP, UPDATE_MESSAGE, RESET_CHAT
} from '../actions/chat';

const dateComparator = (room, next) => {
    return new Date(next.updated) - new Date(room.updated);
};

export const MobileChatStates = {
    LIST: 'list',
    CHAT: 'chat',
    INFO: 'info',
};

export const ChatSideMenuStates = {
    ELEMENTS: 'elements',
    INFO: 'info',
};

Object.freeze(MobileChatStates);

const defaultState = {
    user: { operatorId: null },
    users: [],
    chats: [],
    pushEnabled: false,
    activeChatId: null,
    sendingMessage: false,
    mobileState: MobileChatStates.LIST,
    openUsersList: false,
    notifications: [],
    history: {
        chat: null,
        messages: {},
        files: [],
        date: null,
        pagination: {
            skip: 0,
            limit: 50,
        },
        histGotOlder: false,
    },
    pagination: {
        skip: 0,
        limit: 50,
    },
    activeOperators: [],
    operatorsChats: [],
    socket: null,
    typing: {},
    notificationCount: 0,
    muted: false,
    chatSidebar: ChatSideMenuStates.INFO,
    audioPermission: false,
    socketConnected: false,

};

export default function chatReducer(state = defaultState, action) {
    switch (action.type) {
        case RESET_CHAT:
            return defaultState;
        case OPEN_USERS_LIST:
            return {
                ...state,
                openUsersList: !state.openUsersList,
            };
        case SET_ACTIVE_CHAT:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId !== action.payload.roomId) {
                        return item;
                    } else {
                        return {
                            ...item,
                            hasUnreaded: false,
                            unreadedCount: 0,
                        };
                    }
                }),
                activeChatId: action.payload.roomId,
            };
        case LEAVE_ROOM:
            return {
                ...state,
                activeChatId: null,
            };
        case NEW_MESSAGE_REQUEST:
            return {
                ...state,
                sendingMessage: true,
            };
        case NEW_MESSAGE_SUCCESS:
            // let chatsCopy = chats;
            // let chat = chatsCopy.find(chat => chat.id === state.activeChatId);
            // chat.messages.push(action.payload.message);
            return {
                ...state,
                sendingMessage: false,
                // chats: chatsCopy
            };
        case CHANGE_MOBILE_STATE:
            let defaultId = state.activeChatId;
            let currentMobileState = state.mobileState;
            return {
                ...state,
                mobileState:
                    currentMobileState === action.payload && currentMobileState === MobileChatStates.INFO
                        ? MobileChatStates.CHAT
                        : action.payload,
                activeChatId: action.payload === MobileChatStates.LIST ? null : defaultId,
            };
        case SET_CHAT_LIST:
            const chats = [...state.chats];

            action.payload.forEach((newChat) => {
                const prevChat = chats.find((old) => old.roomId === newChat.roomId);
                return prevChat ? Object.assign(prevChat, newChat) : chats.push(newChat);
            });

            return {
                ...state,
                chats,
            };
        case CLEAR_CHAT_LIST:            
            return {
                ...state,
                chats: [],
            };
        case SET_CHAT_MESSAGES:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId !== action.payload.roomId) {
                        return item;
                    } else {
                        return {
                            ...item,
                            // messages:
                            //     action.payload.extra && action.payload.extra.append
                            //         ? { ...action.payload.messages, ...item.messages }
                            //         : action.payload.messages,
                            gotOlder: action.payload.gotOlder,
                        };
                    }
                }),
            };
        case ADD_CHAT:
            const newChats = [...state.chats];
            if (newChats.findIndex((item) => item.roomId === action.payload.roomId) === -1) {
                newChats.unshift(action.payload);
            }
            return {
                ...state,
                chats: newChats,
            };
        case CLOSE_CHAT:
            let clearedChats = [...state.chats];
            return {
                ...state,
                chats: clearedChats.filter((item) => item.roomId === action.payload.roomId),
            };
        case UPDATE_CHAT:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    return item.roomId !== action.payload.roomId
                        ? item
                        : {
                            ...item,
                            ...action.payload,
                            unreadedCount: item.unreadedCount,
                        };
                }),
            };
        case RECEIVE_MESSAGE:
            const { operatorId } = state.user;
            return {
                ...state,
                chats: state.chats
                    .map((item) => {
                        if (item.roomId !== action.payload.roomId) {
                            return item;
                        } else {
                            // let messages;
                            // if (item.messages) {
                            //     messages = {
                            //         ...item.messages,
                            //         [action.payload._id]: action.payload,
                            //     };
                            // }
                            const isOwner = action.payload.from === operatorId;
                            let counters = {};
                            if (!isOwner) {
                                counters = {
                                    hasUnreaded: true,
                                    unreadedCount: Number(item.unreadedCount) + 1,
                                };
                            }
                            return {
                                ...item,
                                ...counters,
                                // messages,
                                updated: action.payload.kind !== "action" ?  action.payload.created : item.updated,
                                lastMessage: {
                                    ...action.payload,
                                    key: action.payload._id,
                                },
                            };
                        }
                    })
                    .sort(dateComparator),
            };
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, action.payload],
            };
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter((item) => item.id !== action.payload),
            };
        case SET_CHAT_FILES:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId !== action.payload.roomId) {
                        return item;
                    } else {
                        return {
                            ...item,
                            files: action.payload.files,
                        };
                    }
                }),
            };
        case SET_CHAT_INFO:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId !== action.payload.roomId) {
                        return item;
                    } else {
                        return {
                            ...item,
                            person: action.payload.person,
                        };
                    }
                }),
            };
        case SET_CHAT_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
            };
        case SET_HISTORY_CHAT:
            return {
                ...state,
                history: {
                    ...state.history,
                    chat: action.payload.chat,
                },
            };
        case SET_HISTORY_MESSAGES:
            return {
                ...state,
                history: {
                    ...state.history,
                    messages: action.payload.messages,
                    histGotOlder: action.payload.histGotOlder,
                },
            };
        case ADD_HISTORY_MESSAGES:
            return {
                ...state,
                history: {
                    ...state.history,
                    messages: {
                        ...action.payload.messages,
                        ...state.history.messages,
                    },
                    histGotOlder: action.payload.histGotOlder,
                },
            };
        case SET_HISTORY_FILES:
            return {
                ...state,
                history: {
                    ...state.history,
                    files: action.payload.files,
                },
            };
        case SET_HISTORY_DATE:
            return {
                ...state,
                history: {
                    ...state.history,
                    date: action.payload.date,
                },
            };
        case SET_CHAT_HIST_PAGINATION:
            return {
                ...state,
                history: {
                    ...state.history,
                    pagination: action.payload,
                },
            };
        case SET_ACTIVE_OPERATORS:
            return {
                ...state,
                activeOperators: action.payload,
            };
        case SET_OPERATORS_CHATS:
            return {
                ...state,
                chats: action.payload,
            };
        case SET_TYPING:
            return {
                ...state,
                typing: { ...state.typing, [action.payload.roomId]: action.payload },
            };
        case CLEAR_TYPING:
            return {
                ...state,
                typing: {
                    ...Object.keys(state.typing)
                        .filter((key) => key !== action.payload.roomId)
                        .reduce((obj, key) => {
                            obj[key] = state.typing[key];
                            return obj;
                        }, {}),
                },
            };
        case SET_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: action.payload,
            };
        case INCREMENT_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: state.notificationCount + 1,
            };
        case SET_CHAT_USER:
            return {
                ...state,
                user: action.payload,
            };
        case SET_USERS:
            return {
                ...state,
                users: action.payload,
            };
        case DEACTIVATE_CHAT:
            return {
                ...state,
                activeChatId: undefined,
            };
        case SET_PUSH_STATE:
            return {
                ...state,
                pushEnabled: action.payload,
            };
        case UPDATE_MESSAGE:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId !== action.payload.roomId) {
                        return item;
                    } else {
                        let messages;
                        if (item.messages) {
                            messages = {
                                ...item.messages,
                                [action.payload._id]: action.payload,
                            };
                        }
                        return {
                            ...item,
                            messages,
                        };
                    }
                }),
            };
        case REMOVE_MESSAGE:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId !== action.payload.roomId) {
                        return item;
                    } else {
                        let messages;
                        if (item.messages) {
                            delete item.messages[action.payload.messageId];
                            messages = {
                                ...item.messages,
                            };
                        }
                        return {
                            ...item,
                            messages,
                        };
                    }
                }),
            };
        case TOGGLE_MUTE:
            return {
                ...state,
                muted: !state.muted,
            };
        case SET_CHAT_SIDEBAR:
            return {
                ...state,
                chatSidebar: action.payload,
            };
        case CHANGE_MESSAGE:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId !== action.payload.chat.roomId) {
                        return item;
                    } else {
                        let messages;
                        if (item.messages) {
                            messages = {
                                ...item.messages,
                                [action.payload.message._id]: action.payload.message,
                            };
                        }
                        return {
                            ...item,
                            messages,
                        };
                    }
                }),
            };
        case SET_AUDIO_PERMISSION:
            return {
                ...state,
                audioPermission: action.payload,
            };
        case SET_SOCKET_CONNECTED:
            return {
                ...state,
                socketConnected: action.payload,
            };
        case CLEAR_CHAT_MESSAGES:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    return {
                        ...item,
                        messages: null,
                    };
                }),
            };
        case SET_LAST_SEEN_OP:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId === action.payload.roomId) {
                        return { ...item, lastSeenTimeOp: action.payload.time };
                    } else {
                        return item;
                    }
                }),
            };
        case SET_LAST_SEEN:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId === action.payload.roomId) {
                        return { ...item, lastSeenTimeClient: action.payload.time };
                    } else {
                        return item;
                    }
                }),
            };
        case UPDATE_LAST_SEEN_OP:
            return {
                ...state,
                chats: state.chats.map((item) => {
                    if (item.roomId === action.payload.roomId) {
                        return { ...item, lastSeenTimeOp: action.payload.time };
                    } else {
                        return item;
                    }
                }),
            };
        case CHANGE_CHAT_WINDOW:
            return {
                ...state,
                ...action.payload
            };
        case SET_OPS_GROUPS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
