// import { get } from 'lodash';
import React, { PureComponent } from 'react';
import uuid from 'uuid/v4';
import s from './Avatar.module.scss';

const colors = {
  blue:         '#005792',
  indigo:       '#7f3ddb',
  purple:       '#1A86D0',
  pink:         '#e54e9a',
  red:          '#FD5F00',
  orange:       '#ff7d47',
  yellow:       '#FDA700',
  green:        '#68ca66',
  teal:         '#21AE8C',
  cyan:         '#17a2b8'
};

class Avatar extends PureComponent { 

  initials = (user) => {
    const takeTwo = str => str.split(' ').slice(0,2).map(word => word.charAt(0).toUpperCase()).join('');

    if (!user) {
      return '';
    }

    /**
     * !TODO test this pretty attempt
     */
    // let name = get(user, 'name') || get(user, 'person.name') || get(user, 'messageContext.name');
    // if (name) {
    //   return takeTwo(name);
    // }

    if (user.name) {
      return takeTwo(user.name);
    }
    if (user.person && user.person.name) {
      return takeTwo(user.person.name);
    }
    if (user.messageContext && user.messageContext.name) {
      return takeTwo(user.messageContext.name);
    }
   
    if (typeof user.roomId !== 'undefined' && user.roomId !== null) {
      const userPrefix = user.roomId.slice(0, 3);
      if (userPrefix === 'ops') {
        return 'OP';
      } else if (userPrefix === 'bot') {
        return 'BOT';
      } else {
        return 'User';
      }
    }
    return '';
  }

  render() {
    const { user, size, showStatus, group, stroke, classProp } = this.props;
    return (
      !group ? 
        <div className={`${s.avatar} ${classProp ? classProp : ""}`} style={{
          height: size + 'px',
          width: size + 'px',
          minWidth: size + 'px',
          }}
        >
          <div className={`${s.imageWrapper} ${stroke ? s.stroke : ''}`} style={
            user && user.color ? {
              fontSize: size / 3 + 'px',
              backgroundColor: colors[user.color]
            } :
            { fontSize: size / 3 + 'px' }}>
            {user && user.photo ? 
              <img src={user.photo} alt="user avatar"/>
            : <span>{this.initials(user)}</span>}
            
          </div>
          {(user && user.isOnline && showStatus) ? 
            <span className={`${s.status} bg-success`}></span>
          :null}
          
      </div>      
      
      : (
        <div className={s.sharedDialog}>
          {user && user.avatar.map(img => (
          <div className={s.imgWrap} key={uuid()}>
            <img src={img} alt="alt"/>
          </div>
          ))}
        </div>
      )
    )
  }
}

export default Avatar