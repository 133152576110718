import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import React, { useCallback, useState } from 'react';

import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import uuid from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import { shortEditorConfiguration } from '../../../utils/ckeditor-confs';
import { useDraft } from '../../../utils/useDraft';
import { ChatDrop } from '../../chat/components/ChatDialog/ChatDrop';
import styles from '../TicketsPage.module.scss';
import LabelsView from './LabelsView';


const CreateTicket = (props) => {
    const { t } = useTranslation('tickets');
    const [ticketMessage, setTicketMessage] = useDraft(`ticketMessage_new`);
    const [errors, setErrors] = useState([]);

    /** Local state */
    const [ticketData, setTicketData] = useState({
        topic: props.ticket ? props.ticket.topic : '',
        category: props.ticket ? props.ticket.category : '',
        message: props.ticket ? props.ticket.message : '',
        email: props.ticket ? props.ticket.email : '',
        cc: props.ticket ? props.ticket.cc : '',
        label: props.ticket ? props.ticket.label : [],
        agents: props.ticket ? props.ticket.agents : [],
        domainName: props.ticket ? props.ticket.domainName : '',
    });

    const [files, setFiles] = useState([]);

    /** Local functions */
    const setTicketValue = (value, name) => {
        setTicketData({
            ...ticketData,
            [name]: value,
        });
    };

    const createErrors = () => {
        const newErrors = [];
        if (!ticketData.topic || ticketData.topic === '') newErrors.push('topic');
        if (!ticketData.email || ticketData.email === '') newErrors.push('email');
        if (!ticketData.domainName || ticketData.domainName === '') newErrors.push('domainName');
        setErrors(newErrors);
    }

    const saveDialog = () => {
        createErrors();
        if (
            !ticketData.topic ||
            ticketData.topic === '' ||
            !ticketData.email ||
            ticketData.email === '' ||
            ticketMessage === ''
        ) {
            toast.error(t('Вкажіть тему та електронну адресу!'));
            return;
        }

        if (ticketData.domainName === '') {
            toast.error(t('Вкажіть домен тікету'));
            return;
        }

        props.save({
            ...ticketData,
            domainName: ticketData.domainName.name,
            status: 'init',
            source: 'manual',
            message: ticketMessage,
            files
        });
        setTicketMessage('');
    };

    const onDrop = useCallback(
        async ({ files: dropped }) => {
            if (dropped.length > 0) {
                setFiles([...files, ...dropped]);
            }
        },
        [setFiles, files]
    );

    const fileAdded = async (event) => {
        setFiles([...files, ...event.target.files]);
    };

    const removeFile = (index) => {
        setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
    };

    /** Renders */
    return (
        <div className="p-4">
            <ChatDrop onDrop={onDrop}>
                <Row form>
                    <Col md={4}>
                        <FormGroup className="mb-3">
                            <Label>{t('Адреса користувача')}</Label>
                            <Input
                                invalid={errors.includes('email')}
                                value={ticketData.email}
                                placeholder="Email адрес"
                                onChange={(e) => {
                                    setTicketValue(e.target.value, 'email');
                                }}
                            />
                            <FormText>{t('Email для переписки по тікету')}</FormText>
                        </FormGroup>
                    </Col>
                    <Col md={8}>
                        <FormGroup className="mb-3">
                            <Label>{t('Тема звернення')}</Label>
                            <Input
                                invalid={errors.includes('topic')}
                                value={ticketData.topic}
                                onChange={(e) => {
                                    setTicketValue(e.target.value, 'topic');
                                }}
                            />
                            <FormText>{t('Тема питання за яким створюється тікет')}</FormText>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label>{t('Повідомлення')}</Label>
                    <CKEditor
                        editor={Editor}
                        data={ticketMessage}
                        config={shortEditorConfiguration}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setTicketMessage(data);
                        }}
                    />
                    <FormText>{t('Повідомлення за яким створюється тікет')}</FormText>
                </FormGroup>

                {files &&
                    files.map((file, index) => {
                        return (
                            <div className={'my-1 ' + styles.fileRow} key={uuid()}>
                                {file.name}
                                <i className="la la-close" onClick={() => removeFile(index)}></i>
                            </div>
                        );
                    })}

                <div className="mt-2 mb-3">
                    <label className="addFileLabel" htmlFor="upload">
                        <div >{t('Прикріпити файл')}</div>
                        <input hidden id="upload" multiple type="file" onChange={fileAdded} />
                    </label>
                </div>

                <Row>
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <Label>{t('Домен')}</Label>
                            <Select
                                className={errors.includes('domainName') ? 'selectInvalid' : ''}
                                getOptionValue={(option) => option.name}
                                getOptionLabel={(option) => option.name}
                                value={ticketData.domainName}
                                onChange={(selected) => setTicketValue(selected, 'domainName')}
                                options={props.domains}
                                isMulti={false}
                                placeholder="Оберіть домен"
                            />
                            <FormText>{t('Оберіть домен для якого діє тікет')}</FormText>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <LabelsView
                                labels={ticketData.label}
                                showTitle={true}
                                editable={true}
                                labelsChange={(selected) => {
                                    setTicketData({ ...ticketData, label: selected });
                                }}
                            />
                            <FormText>{t('Додайте теги до тікета')}</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup className="mb-3">
                            <Label>{t('Копия, СС')}</Label>
                            <Input
                                invalid={errors.includes('cc')}
                                value={ticketData.cc}
                                placeholder="Email адрес"
                                onChange={(e) => {
                                    setTicketValue(e.target.value, 'cc');
                                }}
                            />
                            <FormText>{t('Email для отправки копий')}</FormText>
                        </FormGroup>
                    </Col>
                </Row>

                <div className="text-right mt-3">
                    <Button color="danger" outline onClick={props.cancel}>
                        {t('Скасувати')}
                    </Button>{' '}
                    <Button color="success" onClick={saveDialog}>
                        {t('Зберегти')}
                    </Button>
                </div>
            </ChatDrop>

        </div>
    );
};

export default CreateTicket;
