import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import socketMiddleware from './middlewares/ChatSocketMiddleware';
import PushMiddleware from './middlewares/PushMiddleware';
import reducers from './reducers';
import CrmSocketMiddleware from './middlewares/CrmSocketMiddleware';
import CardSocketMiddleware from './middlewares/CardSocketMiddleware';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['layout', 'joyride'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const enhancer = composeWithDevTools(
    applyMiddleware(ReduxThunk, socketMiddleware, CrmSocketMiddleware, CardSocketMiddleware, PushMiddleware)
);

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);
