export const STOP_JR = 'stop_jr';
export const PAUSE_JR = 'pause_jr';
export const START_JR = 'start_jr';
export const SET_STEP = 'set_step';

export function startJR(payload) {
  return {
    type: START_JR,
    payload
  };
}

export function stopJR(payload) {
  return {
    type: STOP_JR,
    payload
  };
}

export function pauseJR(payload) {
  return {
    type: PAUSE_JR,
    payload
  };
}

export function setStep(payload) {
  return {
    type: SET_STEP,
    payload
  };
}