import * as Sentry from "@sentry/react";
import axios from 'axios';
import jwt from "jsonwebtoken";
import config from '../../config';
import { resetActiveRoom } from "../active-room/actions";
import { DISCONNECT_CHAT_SOCKET } from '../middlewares/ChatSocketMiddleware';
import { DISCONNECT_CRM_SOCKET, openCrmSocket } from '../middlewares/CrmSocketMiddleware';

import { openSocket, resetChat, setChatUser } from './chat';
import { fetchSubscribtion, resetSettings } from './settings';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

function requestLogin() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function receiveLogin() {
  return {
    type: LOGIN_SUCCESS
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

// Logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    dispatch({
      type: DISCONNECT_CHAT_SOCKET
    });
    dispatch({
      type: DISCONNECT_CRM_SOCKET
    });

    dispatch(resetActiveRoom());
    dispatch(resetChat());
    dispatch(resetSettings());

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('uid');
    localStorage.removeItem('clientId');
    document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    axios.defaults.headers.common['Authorization'] = "";
    
    dispatch(receiveLogout());
  };
}

export function receiveToken(token) {
  return (dispatch) => {
    let user;

    const decodedToken = jwt.decode(token);
    if (decodedToken) {
      user = decodedToken.user;
    }
    
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('uid', JSON.stringify(user.id));
    localStorage.setItem('clientId', user.clientId);
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;

    dispatch(setChatUser(user))
    dispatch(receiveLogin());
    
    dispatch(openSocket());
    dispatch(openCrmSocket());

    dispatch(fetchSubscribtion());
  }
}

export function loginUser(creds) {
  return (dispatch) => {
    dispatch(requestLogin());
    if (creds.social) {
      window.location.href = config.baseURLApi + "/user/signin/" + creds.social + (process.env.NODE_ENV === "production" ? "?app=shark-chat" : "");
    } else if (creds.email.length > 0 && creds.password.length > 0) {
      axios.post("/auth", creds).then(res => {
        const token = res.data.token;
       
        dispatch(receiveToken(token));
        
      }).catch(err => {
        Sentry.captureException(err);
        if (err.response) {
          dispatch(loginError(err.response.data.msg));
        }
      })
    } else {
      dispatch(loginError('Something was wrong. Try again'));
    }
  };
}
