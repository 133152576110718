import React, { useEffect, useState } from 'react';
import s from './StatisticsPage.module.scss';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import StatsChart from './StatsChart';
import EntityView from '../../components/EntityView/EntityView';
import { Tiers } from '../../components/TierGuard/TierGuard';


const StatisticsPage = ({ dispatch }) => {
    const tier = useSelector((state) => get(state, 'settings.tierId', null));
    const { t } = useTranslation('statistics');

    const filtersConfig = [
        {
            name: 'User.name',
            isInclude: true,
            title: t('Оператор'),
            placeholder: t('Оператор'),
            defaultValue: '',
            formatType: 'like',
        },
    ];

    const [columns, setColumns] = useState([
        {
            dataField: 'user.name',
            text: t('Оператор'),
            formatter: (cell) => {
                if (!cell) {
                    return t('Не прийнято');
                }
                return cell;
            },
        },
        {
            dataField: 'acceptedAt',
            text: t('Прийнято'),
            formatter: (cell) => {
                if (moment(cell).isValid()) {
                    return moment(cell).format('HH:mm DD.MM.YYYY');
                }
                return t('Не прийнято');
            },
        },
        {
            dataField: 'receivedAt',
            text: t('Час запиту'),
            formatter: (cell) => {
                if (moment(cell).isValid()) {
                    return moment(cell).format('HH:mm DD.MM.YYYY');
                }
                return '';
            },
        },
    ]);

    useEffect(() => {
        if (tier && (tier === Tiers.swarm || tier === Tiers.tiger)) {
            setColumns((c) => [
                ...c,
                {
                    colType: 'action',
                    dataField: '',
                    text: t('Дії'),
                    formatter: (cell, row) => {
                        return (
                            <Link to={'/history/view/' + row.roomId}>
                                <Button color="info" size="xs">
                                    {t('Переглянути історію')}
                                </Button>
                            </Link>
                        );
                    },
                },
            ]);
        }
    }, [tier, t]);

    return (
        <div className={s.root}>
            {/* <h1 className="page-title">Статистика</h1> */}
            <StatsChart dispatch={dispatch} />
            <EntityView
                showHelp={false}
                title={t('Всі виклики')}
                viewName={'received-chats'}
                filtersConfig={filtersConfig}
                entity={'ReceivedChat'}
                customFetchParams={{
                    shallowInclude: true,
                    includes: ['User'],
                    // includeOptions: JSON.stringify(includeFilters['User'] ? {} : {User: {required: false}});
                    order: JSON.stringify([['receivedAt', 'DESC']]),
                }}
                columns={columns}
                keyField={'id'}
                showAdd={false}
            ></EntityView>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        date: state.chat.history.date,
    };
}

export default connect(mapStateToProps)(StatisticsPage);
