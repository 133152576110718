export const editorConfiguration = {
    toolbar: {
        items: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'link',
            'bulletedList',
            'numberedList',
            'alignment',
            '|',
            'outdent',
            'indent',
            '|',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'codeBlock',      
            '|',      
            'removeFormat',
            'sourceEditing',
            '-',
            'fontFamily',
            'fontSize',
            'fontBackgroundColor',
            'fontColor'
        ],
        shouldNotGroupWhenFull: true
    },
    language: 'uk',
    image: {
        toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:alignLeft',
            'imageStyle:alignRight'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
        ]
    }
};

export const shortEditorConfiguration = {
    toolbar: {
        items: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'link',
            'bulletedList',
            'numberedList',            
            'removeFormat'            
        ]
    },
    language: 'uk'    
};