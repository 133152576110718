import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { routes } from '../../routes';
import HelpPopover from '../../components/HelpPopover/HelpPopover';
import JoyrideLink from '../JoyrideLink/JoyrideLink';

const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs(routes);
    const location = useLocation();

    return (
        <React.Fragment>
            <div className="sc-breadcrumb d-flex">
                <Breadcrumb>
                    {breadcrumbs.map(({ match, breadcrumb }, idx, array) => {
                        if (array.length - 1 === idx)
                            return (
                                <BreadcrumbItem key={`page_b_${idx}`} active>
                                    {breadcrumb}
                                </BreadcrumbItem>
                            );
                        return (
                            <BreadcrumbItem key={`page_b_${idx}`}>
                                <NavLink to={match.url}>{breadcrumb}</NavLink>
                            </BreadcrumbItem>
                        );
                    })}
                </Breadcrumb>&nbsp;
                <HelpPopover helpIndex={location.pathname}></HelpPopover>
                <JoyrideLink name={location.pathname.slice(1)}></JoyrideLink>
            </div>
        </React.Fragment>
    );
};

export default Breadcrumbs;
