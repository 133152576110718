import React, { useRef, useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';


const DateRangeFilter = ({ name, value, onChange }) => {
    const { t } = useTranslation('breadcrumbs');
    const MONTHS = [
        t('Січень'),
        t('Лютий'),
        t('Березень'),
        t('Квітень'),
        t('Травень'),
        t('Червень'),
        t('Липень'),
        t('Серпень'),
        t('Вересень'),
        t('Жовтень'),
        t('Листопад'),
        t('Грудень'),
    ];
    const WEEKDAYS_LONG = [
        t('Неділя'),
        t('Понеділок'),
        t('Вівторок'),
        t('Середа'),
        t('Четвер'),
        t('П\'ятниця'),
        t('Субота'),
    ];
    const WEEKDAYS_SHORT = [t('Нд'), t('Пн'), t('Вт'), t('Ср'), t('Чт'), t('Пт'), t('Сб')];
    
    const [range, setRange] = useState({
        from: undefined,
        to: undefined
    });

    const datePickerRef = useRef();

    useEffect(() => {
        setRange({
            from: value && value.from ? value.from : undefined,
            to: value && value.to ? value.to : undefined
        })
    }, [value]);

    const handleDayClick = (day, modifiers, e) => {
        if (range.from && range.to) {
            setRange({
                from: day,
                to: undefined
            })
        } else if (range.from) {
            let newRange;
            if (moment(day).isSameOrAfter(moment(range.from))) {
                newRange = {
                    from: range.from,
                    to: day
                };
            } else {
                newRange = {
                    from: day,
                    to: range.from
                };
            }
            onChange(newRange);
            setRange(newRange);
            datePickerRef.current.hideDayPicker();
        } else {
            setRange({
                from: day,
                to: undefined
            });
        }
    }

    const modifiers = { start: range.from, end: range.to };
    const modifiersStyles = { 
        selected: { borderRadius: 0 }
    }

    return (
        <DayPickerInput
            style={{width: '100%'}}
            ref={datePickerRef}
            value={`${range.from ? moment(range.from).format('DD.MM.YYYY') : ' '}-${range.to ? moment(range.to).format('DD.MM.YYYY') : ' '}`}
            inputProps={{
                value: `${range.from}-${range.to}`,
                className: 'dateRangeInput'
            }}
            hideOnDayClick={false}
            dayPickerProps={
                {
                    className: "Selectable",
                    selectedDays: [(range.from), { from: (range.from), to: (range.to) }],
                    modifiers: modifiers,
                    onDayClick: handleDayClick,
                    locale: "uk",
                    months: MONTHS,
                    weekdaysLong: WEEKDAYS_LONG,
                    weekdaysShort: WEEKDAYS_SHORT,
                    firstDayOfWeek: 1,
                    modifiersStyles: modifiersStyles
                }
            }
        />
    )
};

export default DateRangeFilter;
