import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { changeMobileState } from '../../../../redux/actions/chat';
import { ChatSideMenuStates, MobileChatStates } from '../../../../redux/reducers/chat';
import ChatElements from '../ChatElements/ChatElements';
import ChatInfo from '../ChatInfo/ChatInfo';
import ChatSideMenuToggler from '../ChatSideMenuToggler';

const ChatSidePanel = () => {
    const { t } = useTranslation('chat');
    const chatSidebar = useSelector((state) => state.chat.chatSidebar);
    const dispatch = useDispatch();
    const roomId = useSelector((state) => get(state, 'room.room.roomId'));
    const { personal, isGroup } = useSelector((state) => get(state, 'room.room', {}));

    if (personal || isGroup) return (
        <div className={`chat-info-section`}><ChatElements /></div>
    );

    if (!roomId) return (
        <div className={`chat-info-section`}></div>
    );

    return (
        <div className={`chat-info-section`}>
            <div
                className="d-lg-none chat-mobile-navigation"
                onClick={() => dispatch(changeMobileState(MobileChatStates.CHAT))}
            >
                <i className="la la-angle-left la-lg"></i> {t('До розмови')}
            </div>
            <ChatSideMenuToggler />
            {chatSidebar === ChatSideMenuStates.INFO ? <ChatInfo /> : <ChatElements />}
        </div>
    );
};

export default ChatSidePanel;
