import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { setChatSidebar } from '../../../redux/actions/chat';
import { ChatSideMenuStates } from '../../../redux/reducers/chat';

const ChatSideMenuToggler = () => {
  const { t } = useTranslation('chat');
  const dispatch = useDispatch();
  const chatSidebar = useSelector((state) => state.chat.chatSidebar);

  return (
    <Nav tabs justified style={{ borderRadius: 4, marginBottom: 16 }}>
      <NavItem>
        <NavLink 
          className="chat-jr-step-4"
          style={chatSidebar === ChatSideMenuStates.INFO ? { borderRadius: 4, marginTop: -2 }: {}} 
          onClick={() => dispatch(setChatSidebar(ChatSideMenuStates.INFO))} 
          active={chatSidebar === ChatSideMenuStates.INFO}
        >
          {t('Інфо')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink 
          className="chat-jr-step-5"
          style={chatSidebar === ChatSideMenuStates.ELEMENTS ? { borderRadius: 4, marginTop: -2 }: {}} 
          onClick={() => dispatch(setChatSidebar(ChatSideMenuStates.ELEMENTS))}
          active={chatSidebar === ChatSideMenuStates.ELEMENTS}
        >
          {t('Автоматизація')}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default ChatSideMenuToggler;
