import { SET_CN_LIST } from '../actions/client-notifications';

const defaultState = {
  list: []
};

export default function clientNotificationsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_CN_LIST:
      return Object.assign({}, state, {
        list: action.payload
      });
    default:
      return state;
  }
}
