import { scenarios } from '../../data/joyRideScenarios';
import { 
  STOP_JR, PAUSE_JR, SET_STEP, START_JR
} from '../actions/joyride';

const initialState = {
  activeJR: null,
  activeStep: [],
  scenarios: scenarios,
  isRunning: false
};

export default function jrReduser(state = initialState, action) {
  switch (action.type) {
    case STOP_JR:
      return {
        ...state,
        scenarios: {
          ...state.scenarios,
          [action.payload]: {
            ...state.scenarios[action.payload],
            state: 'stopped'
          }
        }
      };
    case START_JR:
      return {
        ...state,
        scenarios: {
          ...state.scenarios,
          [action.payload]: {
            ...state.scenarios[action.payload],
            state: 'running'
          }
        }
      };
    case PAUSE_JR:
      return {
        ...state,
        scenarios: {
          ...state.scenarios,
          [action.payload.name]: {
            ...state.scenarios[action.payload.name],
            state: 'paused',
            step: action.payload.step
          }
        }
      };
    case SET_STEP:
      return {
        ...state,
        scenarios: {
          ...state.scenarios,
          [action.payload.name]: {
            ...state.scenarios[action.payload.name],
            step: action.payload.step
          }
        }
      }
    default:
      return state;
  }
}
