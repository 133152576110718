import React from 'react';

const Email = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.8334 15H4.16669C3.70752 15 3.33335 14.6267 3.33335 14.1667V6.04167L9.50002 10.6667C9.64835 10.7783 9.82419 10.8333 10 10.8333C10.1759 10.8333 10.3517 10.7783 10.5 10.6667L16.6667 6.04167V14.1667C16.6667 14.6267 16.2925 15 15.8334 15ZM15.2775 5L10 8.95833L4.72252 5H15.2775ZM15.8334 3.33333H4.16669C2.78835 3.33333 1.66669 4.455 1.66669 5.83333V14.1667C1.66669 15.545 2.78835 16.6667 4.16669 16.6667H15.8334C17.2117 16.6667 18.3334 15.545 18.3334 14.1667V5.83333C18.3334 4.455 17.2117 3.33333 15.8334 3.33333Z" fill="currentColor"/>
        <mask id="mask102" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="3" width="18" height="14">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.8334 15H4.16669C3.70752 15 3.33335 14.6267 3.33335 14.1667V6.04167L9.50002 10.6667C9.64835 10.7783 9.82419 10.8333 10 10.8333C10.1759 10.8333 10.3517 10.7783 10.5 10.6667L16.6667 6.04167V14.1667C16.6667 14.6267 16.2925 15 15.8334 15ZM15.2775 5L10 8.95833L4.72252 5H15.2775ZM15.8334 3.33333H4.16669C2.78835 3.33333 1.66669 4.455 1.66669 5.83333V14.1667C1.66669 15.545 2.78835 16.6667 4.16669 16.6667H15.8334C17.2117 16.6667 18.3334 15.545 18.3334 14.1667V5.83333C18.3334 4.455 17.2117 3.33333 15.8334 3.33333Z" fill="white"/>
        </mask>
        <g mask="url(#mask102)">
        <rect width="20" height="20" fill="currentColor"/>
        </g>
    </svg>
)

export default Email