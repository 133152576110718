import { CHANGE_TYPE } from "./types";

/**
 * Action creators
 */
export function setPaymentStatus(payload) {
    return {
        type: CHANGE_TYPE,
        payload
    }
}