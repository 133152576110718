import React from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import styles from './ChatDrop.module.scss';
import { dropTypes } from '../ChatElements/ChatElements';

export const ChatDrop = (props) => {
  const { t } = useTranslation('chat');
  const { onDrop, children, onDropMessage } = props;
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE, dropTypes.ROUTINE, dropTypes.QUICK],
      drop(item) {
        switch (item.type) {
          case dropTypes.ROUTINE:
          case dropTypes.QUICK:
            if (onDropMessage) {
              onDropMessage(item.item);
            }
            break;
          default:
            if (onDrop) {
              onDrop(item);
            }
            break;
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [props]
  );
  const isActive = canDrop && isOver;
  return (
    <div ref={drop} className={styles.dropZoneWrapper} style={props.style ?? {}}>
      {isActive && (
        <div className={styles.dropZone}>
          <i className="la la-download la-lg"></i>
          <div>{t('Перетягніть сюди')}</div>
        </div>
      )}
      {children}
    </div>
  );
};
