import { DISCONNECT_CARD_SOCKET } from "../middlewares/CardSocketMiddleware";
import { CHANGE_TYPE } from "./types";

export default function payments(state = {
    paymentStatus: false
}, action) {
    switch (action.type) {
        case CHANGE_TYPE:
            return Object.assign({}, state, {
                paymentStatus: true,
            });
        case DISCONNECT_CARD_SOCKET:
            return Object.assign({}, state, {
                paymentStatus: false,
            });
        default:
            return state;
    }
}
