import Axios from 'axios';
import { get } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { disconnectCardSocket, initCardSocket } from '../../redux/middlewares/CardSocketMiddleware';

const CardView = ({ next, prev, tier }) => {
    const { t } = useTranslation('registration');
    const dispatch = useDispatch();

    const { accountId, paymentStatus } = useSelector(state => {
        return {
            accountId: get(state, 'chat.user.accountId'),
            paymentStatus: get(state, 'payments.paymentStatus'),
        }
    });
    
    console.log({ paymentStatus });

    const initPayment = useCallback(
        async () => {
            const options = {
                options: {
                    methods: ['card'],
                    card_icons: ['mastercard', 'visa', 'maestro'],
                    active_tab: 'card',
                    fields: false,
                    // title: 'Верифікація карти',
                    // link: 'https://shark.chat',
                    full_screen: false,
                    button: true,
                    email: true
                },
                params: {
                    token: await Axios.patch(`/entity/clientAccount/${accountId}/getLatestInvoiceToken`)
                        .then(res => {
                            const { token, transactionId } = res.data;
                            dispatch(initCardSocket(transactionId));
                            return token;
                        })
                        .catch(() => {
                            // next();
                        }),
                    // currency: 'UAH',
                    
                }
            }

            window.fondy("#checkout-container", options);
        },
        [dispatch, accountId],
    )

    const goBack = () => {
        if (typeof prev === 'function') {
            prev();
        }
    }

    /** Effects */

    useEffect(() => {
        initPayment();

        return () => {
            dispatch(disconnectCardSocket());
        }
    }, [initPayment, dispatch]);

    useEffect(() => {
        if (paymentStatus) {
            next();
        }
    }, [paymentStatus, next])

    return (
        <div>
            <Button onClick={goBack} size="sm" color="info">{t('Оплатити пізніше')}</Button>
            <div id="checkout-container"></div>            
        </div>
    )
}

export default CardView
