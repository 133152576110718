import React from 'react';

const List = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.5 5.83333C2.5 5.37333 2.87333 5 3.33333 5C3.79333 5 4.16667 5.37333 4.16667 5.83333C4.16667 6.29333 3.79333 6.66667 3.33333 6.66667C2.87333 6.66667 2.5 6.29333 2.5 5.83333ZM2.5 10C2.5 9.54 2.87333 9.16667 3.33333 9.16667C3.79333 9.16667 4.16667 9.54 4.16667 10C4.16667 10.46 3.79333 10.8333 3.33333 10.8333C2.87333 10.8333 2.5 10.46 2.5 10ZM3.33333 13.3333C2.87333 13.3333 2.5 13.7067 2.5 14.1667C2.5 14.6267 2.87333 15 3.33333 15C3.79333 15 4.16667 14.6267 4.16667 14.1667C4.16667 13.7067 3.79333 13.3333 3.33333 13.3333ZM6.61292 9.16667H16.7204C17.1496 9.16667 17.5004 9.5175 17.5004 9.94667V10.0533C17.5004 10.4825 17.1496 10.8333 16.7204 10.8333H6.61292C6.18375 10.8333 5.83375 10.4825 5.83375 10.0533V9.94667C5.83375 9.5175 6.18375 9.16667 6.61292 9.16667ZM16.7204 13.3333H6.61292C6.18375 13.3333 5.83375 13.6842 5.83375 14.1133V14.22C5.83375 14.6492 6.18375 15 6.61292 15H16.7204C17.1496 15 17.5004 14.6492 17.5004 14.22V14.1133C17.5004 13.6842 17.1496 13.3333 16.7204 13.3333ZM6.61292 5H16.7204C17.1496 5 17.5004 5.35083 17.5004 5.78V5.88667C17.5004 6.31583 17.1496 6.66667 16.7204 6.66667H6.61292C6.18375 6.66667 5.83375 6.31583 5.83375 5.88667V5.78C5.83375 5.35083 6.18375 5 6.61292 5Z" fill="currentColor"/>
        <mask id="mask111" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="5" width="16" height="10">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.5 5.83333C2.5 5.37333 2.87333 5 3.33333 5C3.79333 5 4.16667 5.37333 4.16667 5.83333C4.16667 6.29333 3.79333 6.66667 3.33333 6.66667C2.87333 6.66667 2.5 6.29333 2.5 5.83333ZM2.5 10C2.5 9.54 2.87333 9.16667 3.33333 9.16667C3.79333 9.16667 4.16667 9.54 4.16667 10C4.16667 10.46 3.79333 10.8333 3.33333 10.8333C2.87333 10.8333 2.5 10.46 2.5 10ZM3.33333 13.3333C2.87333 13.3333 2.5 13.7067 2.5 14.1667C2.5 14.6267 2.87333 15 3.33333 15C3.79333 15 4.16667 14.6267 4.16667 14.1667C4.16667 13.7067 3.79333 13.3333 3.33333 13.3333ZM6.61292 9.16667H16.7204C17.1496 9.16667 17.5004 9.5175 17.5004 9.94667V10.0533C17.5004 10.4825 17.1496 10.8333 16.7204 10.8333H6.61292C6.18375 10.8333 5.83375 10.4825 5.83375 10.0533V9.94667C5.83375 9.5175 6.18375 9.16667 6.61292 9.16667ZM16.7204 13.3333H6.61292C6.18375 13.3333 5.83375 13.6842 5.83375 14.1133V14.22C5.83375 14.6492 6.18375 15 6.61292 15H16.7204C17.1496 15 17.5004 14.6492 17.5004 14.22V14.1133C17.5004 13.6842 17.1496 13.3333 16.7204 13.3333ZM6.61292 5H16.7204C17.1496 5 17.5004 5.35083 17.5004 5.78V5.88667C17.5004 6.31583 17.1496 6.66667 16.7204 6.66667H6.61292C6.18375 6.66667 5.83375 6.31583 5.83375 5.88667V5.78C5.83375 5.35083 6.18375 5 6.61292 5Z" fill="white"/>
        </mask>
        <g mask="url(#mask111)">
        <rect width="20" height="20" fill="currentColor"/>
        </g>
    </svg>    
)

export default List