import {
  SET_DOMAINS, SET_LOADED, SET_HELP, SET_FOCUS, SET_TIER, SET_SMS_PROVIDERS, RESET_SETTINGS, SET_LANGUAGES
} from '../actions/settings';

const initialState = {
  loaded: false,
  domains: [],
  lang: 'uk',
  helpTexts: {},
  inFocus: true,
  tierId: null,
  currentMaxOps: null,
  smsProviders: [],
  languages: []
};

export default function settingsReduser(state = initialState, action) {
  switch (action.type) {
    case RESET_SETTINGS:
      return initialState;
    case SET_DOMAINS:
      return {
        ...state,
        domains: action.payload,
      };
    case SET_LOADED:
      return {
        ...state,
        loaded: action.payload
      }
    case SET_HELP:
      return {
        ...state,
        helpTexts: action.payload
      }
    case SET_FOCUS:
      return {
        ...state,
        inFocus: action.payload
      }
    case SET_TIER:
      return {
        ...state,
        ...action.payload
      }
    case SET_SMS_PROVIDERS:
      return {
        ...state,
        ...action.payload
      }
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload
      }
    default:
      return state;
  }
}
