import { CLEAR_SEARCH_RESULT, SEARCH_ACTION, SET_SEARCH_RESULT } from "./types";

export default function chatsearch(state = {
    searchChatResults: [],
    searchRoomResults: [],
    insearch: false
}, action) {
    switch (action.type) {
        case SEARCH_ACTION:
            return {
                ...state,
                insearch: true
            };
        case SET_SEARCH_RESULT:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_SEARCH_RESULT:
            return {
                ...state,
                searchChatResults: [],
                searchRoomResults: [],
                insearch: false
            };
        default:
            return state;
    }
}
