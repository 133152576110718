import {
  SHOW_SEND_SMS_DIALOG, HIDE_SEND_SMS_DIALOG, SET_SMS_SERVICES
} from './actions';

const initialState = {
  showDialog: false,
  canSendSms: false,
  smsServices: []
};

export default function settingsReduser(state = initialState, action) {
  switch (action.type) {
    case SHOW_SEND_SMS_DIALOG:
      return {
        ...state,
        showDialog: true,
      };
    case HIDE_SEND_SMS_DIALOG:
      return {
        ...state,
        showDialog: false,
      };
    case SET_SMS_SERVICES:
      return {
        ...state,
        canSendSms: action.payload.length > 0 ? true : false,
        smsServices: action.payload,
      };
    default:
      return state;
  }
}
