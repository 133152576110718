import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Input } from 'reactstrap';

const CCChanger = ({ ticket, onSave }) => {
    const { t } = useTranslation('tickets');

    const [cc, setcc] = useState(ticket.cc ?? '');
    const [inEdit, setinEdit] = useState(false);

    const save = () => {
        if (typeof onSave === 'function') onSave(cc);
        setinEdit(false);
    }

    return <>
        {(ticket.cc && !inEdit) && (<><span className="short-string-inline d-none d-lg-inline">&nbsp;&bull;&nbsp;{t('Копія')}: <span className=''>{ticket.cc}</span></span></>)}
        {inEdit && (<FormGroup style={{ width: 250 }} className='p-0 m-0'><Input
            value={cc}

            placeholder="Email адрес"
            onChange={(e) => {
                setcc(e.target.value);
            }}
        /></FormGroup>)}
        {inEdit ?
            <Button color="success" onClick={save} className='ml-md-auto mt-2 mt-md-0'>{t('Зберегти адресатів')}</Button> :
            <Button onClick={() => setinEdit(true)} color="info" className='ml-md-auto mt-md-0 d-none d-lg-inline'>{t('Додати адресата')}</Button>
        }
    </>;
};

export default CCChanger;
