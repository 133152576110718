import React, { useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, Modal, Label, Input, FormText, ModalHeader } from 'reactstrap';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { hideSendSmsDialog } from '../../redux/sms/actions';
import Axios from 'axios';
import { toast } from 'react-toastify';

const SendSmsModal = () => {
    const { t } = useTranslation('sms');
    const dispatch = useDispatch();
    const showModal = useSelector((state) => get(state, 'sms.showDialog', false));
    const accountId = useSelector((state) => get(state, 'chat.user.accountId', null));

    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    if (!showModal) {
        return null;
    }

    const hideModal = () => {
        setPhone('');
        setMessage('');
        dispatch(hideSendSmsDialog(false));
    }

    const sendMessage = () => {
        if (accountId) {
            Axios.patch(`/entity/ClientAccount/${accountId}/sendSms`,
                { to: phone, message }
            )
                .then((res) => {
                    toast.success(t('Повідомлення надіслано'));
                    hideModal();
                })
                .catch(Sentry.captureException);
        }
    }

    return (
        <Modal isOpen={showModal} centered={true} footer={null}
            toggle={() => hideModal()} onCancel={() => hideModal()}>
            <ModalHeader toggle={() => hideModal()}>
                {t('Надіслати SMS')}
                {/* <Button className="float-right" close onClick={() => hideModal()} /> */}
            </ModalHeader>
            <div className="p-4 text-center">
                <FormGroup key="sms_phone_group" className="mb-3">
                    <Label>{t('Телефон')}</Label>
                    <Input
                        name="sms_phone"
                        value={phone}
                        type="text"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <FormText>{t('Вкажіть номер телефона отримувача')}</FormText>
                </FormGroup>
                <FormGroup key="sms_message_group" className="mb-3">
                    <Label>{t('Повідомлення')}</Label>
                    <Input
                        name="sms_phone"
                        value={message}
                        type="textarea"
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <FormText>{t('Напишіть повідомлення')}</FormText>
                </FormGroup>
                <Button color="success" onClick={() => sendMessage()} >{t('Надіслати')}</Button>
                <Button color="danger" onClick={() => hideModal()} >{t('Скасувати')}</Button>
            </div>
        </Modal>
    );
}

export default SendSmsModal;