import * as Sentry from "@sentry/react";
import Axios from 'axios';
import { get } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Joyride, { ACTIONS } from 'react-joyride';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Select from 'react-select';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalHeader, Row } from 'reactstrap';
import uuid from 'uuid/v4';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Widget from '../../components/Widget/Widget';
import { stopJR } from '../../redux/actions/joyride';
import { fetchTickets, resetTicketCounter, setTicketPage } from '../../redux/tickets/actions';
import CreateTicket from './components/CreateTicket';
import TicketItem from './components/TicketItem';
import { selectStyles } from './data/AgentStyles';
import { selectStatusStyles, ticketStatusOptions } from './data/TicketStatuses';
import styles from './TicketsPage.module.scss';


const TicketsPage = () => {
    const { t, i18n } = useTranslation('tickets');
    const statusOptions = ticketStatusOptions(t);
    const sizePerPage = 10;
    const location = useLocation();

    const [showAddModal, setShowAddModal] = useState(false);
    const [filterPanelOpen, setFilterPanelOpen] = useState(false);
    const [email] = useState(() => {
        const params = new URLSearchParams(location.search);
        return params.get('email');
    })

    const [fetching, setFetching] = useState(false);

    const history = useHistory();
    const { id: userId } = useSelector((state) =>
        get(state, 'chat.user', { accountId: null, clientId: null, userId: null })
    );
    
    const [filters, setFilters] = useState({
        topic: '',
        email: email ? email : '',
        status: [statusOptions[0], statusOptions[1]],
        agents: '',
        domainName: '',
        message: '',
        label: '',
    });
    const [defaultFilter] = useState({});

    const [showJR, setShowJR] = useState(false);
    const jr = useSelector((state) => get(state, 'joyride.scenarios', {}));

    const agents = useSelector((state) => get(state, 'chat.users', { accountId: null }));
    const domains = useSelector((state) => get(state, 'settings.domains', []));

    const { tickets, totalTicketPages, ticketPage, newTicketsCount } = useSelector((state) =>
        get(state, 'tickets', { tickets: [], totalTicketPages: 0, ticketPage: 0 })
    );

    const dispatch = useDispatch();

    const scrollRef = useRef();

    useEffect(() => {
        setShowJR(jr && jr['tickets'] && jr['tickets'].state !== 'stopped');
    }, [jr]);

    const loadTickets = useCallback(async () => {
        const clearedFilters = {};
        for (const key in filters) {
            if (filters[key] && filters[key] !== '') {
                if (key === 'topic' || key === 'email') {
                    clearedFilters[key] = { $like: `%${filters[key]}%` };
                } else if (key === 'label') {
                    clearedFilters[key] = { $contains: [filters[key]] };
                } else if (key === 'agents') {
                    clearedFilters[key] = { $contains: [String(filters[key].id)] };
                } else if (key === 'domainName') {
                    clearedFilters[key] = filters[key].name;
                } else if (key === 'status') {
                    clearedFilters[key] = filters[key].map((i) => i.value);
                } else {
                    clearedFilters[key] = filters[key].value;
                }
            }
        }

        try {
            setFetching(true);
            await dispatch(
                fetchTickets({
                    where: { ...defaultFilter, ...clearedFilters },
                    page: ticketPage,
                    per_page: sizePerPage,
                    order: JSON.stringify([['updatedAt', 'desc']]),
                    scope: 'withMessagesList',
                })
            );
        } catch (error) {
            Sentry.captureException(error);
        }
    }, [filters, ticketPage, sizePerPage, defaultFilter, dispatch, setFetching]);

    useEffect(() => {
        dispatch(resetTicketCounter());
    }, [dispatch]);

    useEffect(() => {
        loadTickets();
    }, [ticketPage, filters, sizePerPage, loadTickets]);

    useEffect(() => {
        setFetching(false);
    }, [tickets]);

    const pageChange = (page) => {
        if (Number(ticketPage) !== Number(page.selected)) {
            dispatch(setTicketPage(page.selected));
            scrollToTableTop();
        }
    };

    const addTicket = async (data) => {
        setShowAddModal(false);
        try {
            const { data: newTicket } = await Axios.post(`/entity/Ticket`, data);

            const { files } = data;
            let payload = {
                message: data.message,
                from: 'agent',
                ticketId: newTicket.id
            };
    
            if (files && files.length > 0) {
                const formData = new FormData();
                for (const key in payload) {
                    formData.append(key, payload[key]);
                }
                for (const file of files) {
                    formData.append('ticketFile', file);
                }
                await Axios.post(`/tickets/message`, formData);
            } else {
                await Axios.post(`/tickets/message`, payload);
            }
    
            if (ticketPage === 0) {
                loadTickets();
            } else {
                dispatch(setTicketPage(0));
            }
        } catch(err) {
            toast.error(t('Помилка при створенні тікету'));
        }
    };

    const changeAgents = async (selected, ticket) => {
        await Axios.patch(`/entity/Ticket/${ticket.id}`, { agents: selected.map((agent) => agent.id) });
        loadTickets();
    };

    const filterChange = (value, name) => {
        setFilters({ ...filters, [name]: value });
    };

    const openTicket = (ticket) => {
        history.push(`/tickets/${ticket.id}`);
    };

    const clearFilters = () => {
        setFilters({
            topic: '',
            email: '',
            status: [statusOptions[0], statusOptions[1]],
            agents: '',
            domainName: '',
            message: '',
            label: ''
        });
    };

    const handleJREvent = (data) => {
        const { action, index } = data;
        if (action === ACTIONS.CLOSE || action === ACTIONS.SKIP) {
            dispatch(stopJR('tickets'));
            return;
        }
        if (action === ACTIONS.NEXT && index === 0) {
            history.push(`/tickets/guide-ticket`);
        }
    }

    const _renderList = () => {
        if (tickets.length === 0)
            return (
                <div className={`d-flex flex-column chat-dialog-section`}>
                    <div className={styles.noTickets}>{t('Наразі тікетів немає')}</div>
                </div>
            );

        return (
            <>
                {tickets.map((ticket) => {
                    return (
                        <div key={uuid()} className={styles.ticketItem}>
                            <TicketItem
                                userId={userId}
                                ticket={ticket}
                                key={uuid()}
                                agents={agents}
                                changeAgents={(selected) => changeAgents(selected, ticket)}
                                onClick={(e) => {
                                    openTicket(ticket);
                                }}
                            ></TicketItem>
                        </div>
                    );
                })}
                <Row>
                    <Col sm={12} className="text-center">
                        <ReactPaginate
                            previousLabel={<i className="fa fa-angle-left"></i>}
                            nextLabel={<i className="fa fa-angle-right"></i>}
                            forcePage={ticketPage}
                            pageCount={totalTicketPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={pageChange}
                            containerClassName={'custom-react-pagination'}
                            activeClassName={'active'}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    const scrollToTableTop = () => {
        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return (
        <div className={styles.root} ref={scrollRef}>
            <Joyride
                steps={jr && jr['tickets'] ? jr['tickets'].steps[i18n.language ? i18n.language : 'uk'] : []}
                run={showJR}
                callback={handleJREvent}
                showSkipButton={true}
                continuous={true}
                stepIndex={0}
                locale={{ back: 'Назад', close: t('Закрити'), last: t('Завершити'), next: t('Далі'), open: t('Відкрити'), skip: t('Пропустити') }}
                styles={{ options: { primaryColor: '#FD5F00', buttonNext: { outline: "none" } } }}
            />

            <SlidingPane
                isOpen={filterPanelOpen}
                title={t('Фільтри')}
                subtitle={t('Відфільтруйте результати')}
                width="340px"
                overlayClassName="overlayForSlide"
                onRequestClose={() => {
                    setFilterPanelOpen(false);
                }}
                closeIcon={
                    <div>
                        <i className="la la-close la-lg"></i>
                    </div>
                }
            >
                <Form>
                    <FormGroup>
                        <Label for="titleFilter">{t('Заголовок')}</Label>
                        <Input
                            value={filters.topic}
                            placeholder="Тема"
                            className={styles.wideFilter}
                            onChange={(e) => filterChange(e.target.value, 'topic')}
                        ></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="titleFilter">Email</Label>
                        <Input
                            value={filters.email}
                            placeholder="Email"
                            className={styles.wideFilter}
                            onChange={(e) => filterChange(e.target.value, 'email')}
                        ></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="titleFilter">{t('Учасник')}</Label>
                        <Select
                            styles={selectStyles}
                            value={filters.agents}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(selected) => {
                                filterChange(selected, 'agents');
                            }}
                            options={agents}
                            placeholder={t('Учасник')}
                            isClearable={true}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="titleFilter">{t('Статус')}</Label>
                        <Select
                            styles={selectStatusStyles}
                            value={filters.status}
                            onChange={(selected) => {
                                filterChange(selected, 'status');
                            }}
                            placeholder={t('Статус')}
                            options={statusOptions}
                            isClearable
                            isMulti
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="titleFilter">{t('Домен')}</Label>
                        <Select
                            getOptionValue={(option) => option.name}
                            getOptionLabel={(option) => option.name}
                            value={filters.domainName}
                            onChange={(selected) => {
                                filterChange(selected, 'domainName');
                            }}
                            options={domains}
                            isMulti={false}
                            placeholder={t('Домен')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="messageFilter">{t('Повідомлення')}</Label>
                        <Input
                            id="messageFilter"
                            value={filters.topic}
                            placeholder={t('Повідомлення')}
                            className={styles.wideFilter}
                            onChange={(e) => filterChange(e.target.value, 'message')}
                        ></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="labelFilter">{t('Мітки')}</Label>
                        <Input
                            id="labelFilter"
                            value={filters.label}
                            placeholder={t('Мітки')}
                            className={styles.wideFilter}
                            onChange={(e) => filterChange(e.target.value, 'label')}
                        ></Input>
                    </FormGroup>
                    <FormGroup>
                        <Button color="info" onClick={clearFilters}>
                            {t('Очистити')}
                        </Button>
                    </FormGroup>
                </Form>
            </SlidingPane>

            {/* <h1 className="page-title">
                <span>Тікети</span>
            </h1> */}

            <div className={styles.ticketsBlock}>
                <div className={styles.listBlock}>
                    <Widget>
                        <Row className="mb-3">
                            <Col>
                                <Button className="tickets-jr-step-1"
                                    color="success"
                                    onClick={() => {
                                        setShowAddModal(true);
                                    }}
                                >
                                    {t('Додати')}
                                </Button>

                                <Button
                                    outline
                                    className={'ml-1 d-none d-sm-inline'}
                                    onClick={() => {
                                        dispatch(resetTicketCounter());
                                        loadTickets();
                                    }}
                                >
                                    <i
                                        className={'la la-refresh ml-auto' + (fetching ? ' rotating' : '')}
                                    ></i>
                                    {newTicketsCount > 0 ? (
                                        <>
                                            &nbsp;
                                            <i className="la la-exclamation la-lg text-danger" /> {t('Є нові повідомленя')}
                                        </>
                                    ) : null}
                                </Button>
                            </Col>
                            <Col align="right">
                                <Button
                                    size="small"
                                    outline
                                    onClick={() => {
                                        setFilterPanelOpen(true);
                                    }}
                                >
                                    <i className="la la-filter la-lg"></i>
                                    <span className="d-none d-sm-inline">&nbsp;{t('Фільтрувати')}</span>
                                </Button>
                            </Col>
                        </Row>
                        <hr />
                        {_renderList()}
                    </Widget>
                    <Modal isOpen={showAddModal} toggle={() => setShowAddModal(false)} size="lg">
                        <ModalHeader toggle={() => setShowAddModal(false)}>{t('Створити тікет')}</ModalHeader>
                        <CreateTicket
                            agents={agents}
                            domains={domains}
                            cancel={() => setShowAddModal(false)}
                            save={addTicket}
                        />
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default TicketsPage;
