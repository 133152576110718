import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import styles from './EntityTable.module.scss';
import PaginationButton from '../../components/PaginationButton/PaginationButton';
import { loadEntities } from '../../utils/requests';
import Axios from 'axios';
import moment from 'moment';

export function dateFormatter(cell) {
    if (moment(cell).isValid()) {
        return moment(cell).format('DD.MM.YYYY');
    }
    return '';
}

export function boolFormatter(cell) {
    return cell ? 'Так' : 'Ні';
}

const EntityTable = (props, ref) => {
    const { columns, externalFilters, entity, filters, backend, fetchPath, onPageChange, customFetchParams = {}, keyField = 'id' } = props;

    const [templates, setTemplates] = useState([]);

    const [cfp] = useState(customFetchParams);
    const [fp] = useState(fetchPath);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalSize, setTotalSize] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [defaultFilter] = useState(externalFilters || { clientId: localStorage.getItem('clientId') });

    const loadEntity = useCallback(async () => {
        try {
            let data;
            if (fp) {
                const result = await Axios.get(fp, {
                    params: {
                        clientId: localStorage.getItem('clientId'),
                        filters: { ...defaultFilter, ...filters },
                        page: currentPage,
                        per_page: sizePerPage,
                        ...cfp
                    }
                });
                data = result.data;
            } else {
                const result = await loadEntities({
                    entity: entity,
                    page: currentPage,
                    filters: { ...defaultFilter, ...filters },
                    per_page: sizePerPage,
                    // order: JSON.stringify([['id', 'desc']]),
                    backend: backend,
                    additionalParams: cfp
                });
                data = result.data;
            }
            setTemplates(data.rows);
            setTotalSize(data.count);
        } catch (error) {
            setTemplates([]);
            setTotalSize(0);
        }
    }, [entity, filters, currentPage, sizePerPage, defaultFilter, backend, cfp, fp]);

    useEffect(() => {
        loadEntity();
    }, [currentPage, filters, sizePerPage, loadEntity]);

    const onTableChange = (type, newState) => {
        if (type === 'pagination') {
            if (newState.page - 1 !== currentPage) {
                setCurrentPage(newState.page - 1);
                if (onPageChange) {
                    onPageChange(newState.page - 1);
                }
            }
            if (newState.sizePerPage !== sizePerPage) {
                setSizePerPage(newState.sizePerPage);
            }
        }
    };

    useImperativeHandle(ref, () => ({
        fetchData() {
            loadEntity();
        },
    }));

    return (
        <BootstrapTable
            data={templates}
            bootstrap4
            remote={{ pagination: true, filter: true, sort: true }}
            pagination={paginationFactory({
                currentPage: currentPage,
                sizePerPage: sizePerPage,
                totalSize: totalSize,
                alwaysShowAllBtns: true,
                withFirstAndLast: false,
                hidePageListOnlyOnePage: true,
                hideSizePerPage: true,
                pageButtonRenderer: PaginationButton
            })}
            onTableChange={onTableChange}
            columns={columns}
            keyField={keyField}
            striped
            classes={`${styles.historyTable}`}
            bordered={false}
            wrapperClasses="table-responsive"
        ></BootstrapTable>
    );
};

export default forwardRef(EntityTable);
