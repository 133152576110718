import isScreen from "../core/screenHelper";

export const scenarios = {
  chat: {
    steps: {
      uk: [
        {
          target: '.chat-jr-step-1',
          content: 'На цій вкладці Ви можете бачити всі активні чати з клієнтами. Клік по чату зі списка відкриє деталі розмови',
        },
        {
          target: '.chat-jr-step-2',
          content: 'На цій вкладці міститься список чатів зі співробітниками, а також груповий чат',
        },
        {
          target: '.chat-jr-step-3',
          content: 'Якщо обрано один із чатів зі списку, в цьому вікні Ви побачите переписку та зможете відповідати співрозмовнику через поле нового повідомлення внизу',
        },
        {
          target: '.chat-jr-step-4',
          content: 'В цьому розділі, якщо обрано один з чатів, Ви побачите детальну інформацію щодо нього',
        },
        {
          target: '.chat-jr-step-5',
          content: 'Вкладка "Автоматизація" дозволяє Вам спростити спілкування в чаті. Тут Ви можете бачити два розділи - Сценарії та Швидкі відповіді. Перетягніть у вікно чата сценарій, щоб запустити його, або швидку відповідь, щоб наіслати її співрозмовнику',
        }
      ],
      ru: [
        {
          target: '.chat-jr-step-1',
          content: 'На цій вкладці Ви можете бачити всі активні чати з клієнтами. Клік по чату зі списка відкриє деталі розмови',
        },
        {
          target: '.chat-jr-step-2',
          content: 'На цій вкладці міститься список чатів зі співробітниками, а також груповий чат',
        },
        {
          target: '.chat-jr-step-3',
          content: 'Якщо обрано один із чатів зі списку, в цьому вікні Ви побачите переписку та зможете відповідати співрозмовнику через поле нового повідомлення внизу',
        },
        {
          target: '.chat-jr-step-4',
          content: 'В цьому розділі, якщо обрано один з чатів, Ви побачите детальну інформацію щодо нього',
        },
        {
          target: '.chat-jr-step-5',
          content: 'Вкладка "Автоматизація" дозволяє Вам спростити спілкування в чаті. Тут Ви можете бачити два розділи - Сценарії та Швидкі відповіді. Перетягніть у вікно чата сценарій, щоб запустити його, або швидку відповідь, щоб наіслати її співрозмовнику',
        }
      ],
      en: [
        {
          target: '.chat-jr-step-1',
          content: 'На цій вкладці Ви можете бачити всі активні чати з клієнтами. Клік по чату зі списка відкриє деталі розмови',
        },
        {
          target: '.chat-jr-step-2',
          content: 'На цій вкладці міститься список чатів зі співробітниками, а також груповий чат',
        },
        {
          target: '.chat-jr-step-3',
          content: 'Якщо обрано один із чатів зі списку, в цьому вікні Ви побачите переписку та зможете відповідати співрозмовнику через поле нового повідомлення внизу',
        },
        {
          target: '.chat-jr-step-4',
          content: 'В цьому розділі, якщо обрано один з чатів, Ви побачите детальну інформацію щодо нього',
        },
        {
          target: '.chat-jr-step-5',
          content: 'Вкладка "Автоматизація" дозволяє Вам спростити спілкування в чаті. Тут Ви можете бачити два розділи - Сценарії та Швидкі відповіді. Перетягніть у вікно чата сценарій, щоб запустити його, або швидку відповідь, щоб наіслати її співрозмовнику',
        }
      ]
    },
    state: isScreen('sm') || isScreen('xs') ? 'stopped' : 'init'
  },
  tickets: {
    steps: {
      uk: [
        {
          target: '.tickets-jr-step-1',
          content: 'Список тікетів допомагає управляти зверненнями клієнтів. Тікети формуються з вхідної кореспонденції, також Ви можете додати тікет самостійно, натиснувши на кнопку "Додати". Кожен тікет має статус, що відображає на якому етапі знаходиться вирішення питання. Обравши тікет зі списку Ви можете переглянути деталі.',
        },
        {
          target: '.tickets-jr-step-2',
          content: 'На сторінці тікета Ви можете переглянути переписку пов\'язану із ним',
        },
        {
          target: '.tickets-jr-step-3',
          content: 'Для надання відповіді, що буде надіслана клієнту, скористайтесь цим полем. Для прикріплення додатку до листа використовуйте послання "Прикріпити файл"',
        },
        {
          target: '.tickets-jr-step-4',
          content: 'Більше інформації в бічному меню. Тут Ви можете встановити відповідального за вирішення питання.',
        },
        {
          target: '.tickets-jr-step-5',
          content: 'Змінити статус тікета',
        },
        {
          target: '.tickets-jr-step-6',
          content: 'Додати тікету теги',
        },
        {
          target: '.tickets-jr-step-7',
          content: 'Залишити коментар до тікета',
        },
        {
          target: '.tickets-jr-step-8',
          content: 'Також, Ви можете об\'єднати кілька тікетів в один, або заблокувати адресата, наприклад через спам.',
        }
      ],
      ru: [
        {
          target: '.tickets-jr-step-1',
          content: 'Список тікетів допомагає управляти зверненнями клієнтів. Тікети формуються з вхідної кореспонденції, також Ви можете додати тікет самостійно, натиснувши на кнопку "Додати". Кожен тікет має статус, що відображає на якому етапі знаходиться вирішення питання. Обравши тікет зі списку Ви можете переглянути деталі.',
        },
        {
          target: '.tickets-jr-step-2',
          content: 'На сторінці тікета Ви можете переглянути переписку пов\'язану із ним',
        },
        {
          target: '.tickets-jr-step-3',
          content: 'Для надання відповіді, що буде надіслана клієнту, скористайтесь цим полем. Для прикріплення додатку до листа використовуйте послання "Прикріпити файл"',
        },
        {
          target: '.tickets-jr-step-4',
          content: 'Більше інформації в бічному меню. Тут Ви можете встановити відповідального за вирішення питання.',
        },
        {
          target: '.tickets-jr-step-5',
          content: 'Змінити статус тікета',
        },
        {
          target: '.tickets-jr-step-6',
          content: 'Додати тікету теги',
        },
        {
          target: '.tickets-jr-step-7',
          content: 'Залишити коментар до тікета',
        },
        {
          target: '.tickets-jr-step-8',
          content: 'Також, Ви можете об\'єднати кілька тікетів в один, або заблокувати адресата, наприклад через спам.',
        }
      ],
      en: [
        {
          target: '.tickets-jr-step-1',
          content: 'Список тікетів допомагає управляти зверненнями клієнтів. Тікети формуються з вхідної кореспонденції, також Ви можете додати тікет самостійно, натиснувши на кнопку "Додати". Кожен тікет має статус, що відображає на якому етапі знаходиться вирішення питання. Обравши тікет зі списку Ви можете переглянути деталі.',
        },
        {
          target: '.tickets-jr-step-2',
          content: 'На сторінці тікета Ви можете переглянути переписку пов\'язану із ним',
        },
        {
          target: '.tickets-jr-step-3',
          content: 'Для надання відповіді, що буде надіслана клієнту, скористайтесь цим полем. Для прикріплення додатку до листа використовуйте послання "Прикріпити файл"',
        },
        {
          target: '.tickets-jr-step-4',
          content: 'Більше інформації в бічному меню. Тут Ви можете встановити відповідального за вирішення питання.',
        },
        {
          target: '.tickets-jr-step-5',
          content: 'Змінити статус тікета',
        },
        {
          target: '.tickets-jr-step-6',
          content: 'Додати тікету теги',
        },
        {
          target: '.tickets-jr-step-7',
          content: 'Залишити коментар до тікета',
        },
        {
          target: '.tickets-jr-step-8',
          content: 'Також, Ви можете об\'єднати кілька тікетів в один, або заблокувати адресата, наприклад через спам.',
        }
      ]
    },
    state: isScreen('sm') || isScreen('xs') ? 'stopped' : 'init'
  }
}