import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
    Popover,
    PopoverBody
} from "reactstrap";

import s from "./HelpPopover.module.scss";

const HelpPopover = ({ helpIndex }) => {
    const [showPopover, setShowPopover] = useState(false);

    const helpTexts = useSelector((state) => get(state, 'settings.helpTexts', {}));
    const lang = useSelector((state) => get(state, 'settings.lang', {}));

    const togglePopover = () => {
        setShowPopover(!showPopover);
    }

    if (!helpTexts[helpIndex]) 
        return null;

    return (
        <div className={s.helperHolder}>
            <span className={s.helpIcon}><i id={'helpPopup'} className="la la-question-circle-o la-lg"></i></span>
            <Popover className="helpPopover" target={'helpPopup'} trigger={'legacy'} delay={0} placement="left-start" isOpen={showPopover}
                toggle={() => { togglePopover() }}
            >
                <PopoverBody className="px-5 d-flex justify-content-center">
                    <div className={s.helpText}>{helpTexts[helpIndex] ? helpTexts[helpIndex][lang] : ''}</div>
                </PopoverBody>
            </Popover>
        </div>
    )
};

export default HelpPopover;
