import { get } from 'lodash';
import PropTypes from 'prop-types';
import Hammer from 'rc-hammerjs';
import React, { Suspense, useCallback, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';
import { closeSidebar, openSidebar, toggleSidebar } from '../../redux/actions/navigation';
import { DashboardThemes, SidebarTypes } from '../../redux/reducers/layout';
import Header from '../Header';
import Loader from '../Loader/Loader';
import Sidebar from '../Sidebar';
import s from './Layout.module.scss';
import { tierRoutes } from '../../routes';
import ClientNotifications from '../Notifications/notifications/ClientNotifications';


export function RouteWithSubRoutes(route) {
    if (!route.component) return route.render();
    return (
        <Route
            path={route.path}
            render={props => (
                // pass the sub-routes down to keep nesting
                <route.component {...props} routes={route.routes} />
            )}
        />
    );
}

const Layout = (props) => {
    const { sidebarStatic, dispatch } = props;

    const notSeen = useSelector((state) => get(state, 'personalNotifications.notSeen', []));
    const tier = useSelector((state) => get(state, 'settings.tierId', 1));

    const routesForTier = () => {
        if (!tier) {
            return tierRoutes['1'];
        }
        return tierRoutes[String(tier)];
    }

    const handleResize = useCallback(() => {
        if (window.innerWidth <= 1024 && sidebarStatic) {
            dispatch(toggleSidebar());
        }

        if (window.innerWidth > 1024 && !sidebarStatic) {
            dispatch(toggleSidebar());
        }
    }, [sidebarStatic, dispatch]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    useEffect(() => {
          
    }, [])

    const handleSwipe = (e) => {
        if ('ontouchstart' in window) {
            if (e.direction === 4) {
                props.dispatch(openSidebar());
                return;
            }

            if (e.direction === 2 && props.sidebarOpened) {
                props.dispatch(closeSidebar());
                e.srcEvent.stopPropagation();
                return;
            }
        }
    };

    const handleHammerClick = (e) => {
        if (props.sidebarOpened) {
            props.dispatch(closeSidebar());
            return;
        }
    };

    return (
        <div
            className={[
                s.root,
                props.sidebarStatic ? `${s.sidebarStatic}` : '',
                !props.sidebarOpened ? s.sidebarClose : '',
                'sing-dashboard',
                `dashboard-${
                    props.sidebarType === SidebarTypes.TRANSPARENT ? 'light' : props.dashboardTheme
                }`,
            ].join(' ')}
        >
            {/* <Loader /> */}
            <Sidebar />
            <div className={s.wrap}>
                <Header notSeen={notSeen} />
                <ClientNotifications />
                <Hammer onSwipe={handleSwipe} onClick={handleHammerClick}>
                    <main className={s.content}>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                {routesForTier().map((route, i) => (
                                    <RouteWithSubRoutes key={i} {...route} />
                                ))}
                            </Switch>
                        </Suspense>
                    </main>
                </Hammer>
            </div>
        </div>
    );
};

Layout.propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dashboardTheme: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
};
Layout.defaultProps = {
    sidebarStatic: true,
    sidebarOpened: true,
    dashboardTheme: DashboardThemes.DARK,
};

const mapStateToProps = (store) => {
    return {
        sidebarOpened: store.navigation.sidebarOpened,
        sidebarStatic: store.navigation.sidebarStatic,
        dashboardTheme: store.layout.dashboardTheme,
        sidebarType: store.layout.sidebarType,
    };
};

export default withRouter(connect(mapStateToProps)(Layout));
