import React from 'react';
import uuid from 'uuid/v4';

export const PaginationButton = (props) => {
  
  const pageView = (page) => {
    if (page === '<') {
      return (<i className="fa fa-angle-left"></i>);
    } else if (page === '>') {
      return (<i className="fa fa-angle-right"></i>);
    } else {
      return page;
    }
  }

  const pageButtonRenderer = ({
    page,
    active,
    disabled,
    title,
    onPageChange
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    return (
      <li className={"page-item" + (active ? ' active' : '')} key={uuid()}>
        <a className="page-link" href="#a" onClick={ handleClick } >{ pageView(page) }</a>
      </li>
    );
  };

  return pageButtonRenderer(props);
}
export default PaginationButton;
