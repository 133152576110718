import * as Sentry from "@sentry/react";
import Axios from 'axios';
import { get } from 'lodash';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader/Loader';
import isScreen from "../../core/screenHelper";
import { stopJR } from '../../redux/actions/joyride';
import { setTicketList } from '../../redux/tickets/actions';
import TicketDetails from './components/TicketDetails';
import TicketInfo from './components/TicketInfo';
import styles from './TicketsPage.module.scss';


const initial = {
    showSidebar: true,
};

const tickerPageReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_PANEL':
            const showSidebar = !state.showSidebar;
            if (window.sessionStorage) {
                window.sessionStorage.setItem('showSidebar', showSidebar);
            }
            return { ...state, showSidebar };
        case 'CLEAN_STATE':
            return initial;
        default:
            throw new Error();
    }
};

const TicketViewPage = () => {
    const { t, i18n } = useTranslation('tickets');
    const [dummyTicket] = useState({
        id: 0,
        agents: [],
        assignToId: null,
        category: "Questions",
        createdAt: "2021-07-22T09:52:54.204Z",
        domainName: "example.domain.name",
        email: "client@example.com",
        label: ["important"],
        message: t('Дуже важливе питання'),
        messages: [{
            createdAt: "2021-07-22T09:52:54.204Z",
            from: "client",
            message: `<p>${t('Доброго дня, Ви можете мені допомогти?')}</p>`,
            email: "client@example.com"
        }, {
            createdAt: "2021-07-22T10:00:25.739Z",
            from: "agent",
            message: `<p>${t('Доброго дня! Звичайно, яке саме у Вас питання?')}</p>`,
            email: "operator@example.com",
        }],
        source: "auto",
        status: "init",
        topic: t('Питання до менеджера'),
        updatedAt: "2021-07-22T10:00:25.739Z"
    });
    const { id } = useParams();

    /** Global state */
    const agents = useSelector((state) => get(state, 'chat.users', []));
    const tickets = useSelector((state) => get(state, 'tickets.tickets', []));
    const user = useSelector((state) => get(state, 'chat.user', { id: undefined }));
    const dispatch = useDispatch();
    const jr = useSelector((state) => get(state, 'joyride.scenarios', {}));
    const history = useHistory();

    /** Local state */
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [state, ticketsPageDisapatch] = useReducer(tickerPageReducer, initial, () => {
        if (isScreen('sm') || isScreen('xs')) return false;

        let showSidebar = true;
        if (window.sessionStorage) {
            const showSidebarSelection = window.sessionStorage.getItem('showSidebar');
            if (typeof showSidebarSelection === 'string') {
                showSidebar = showSidebarSelection !== 'false';
            }
        }
        return { ...initial, showSidebar };
    });
    const { showSidebar } = state;

    const [runJR, setRunJR] = useState(false);
    
    useEffect(() => {
        setRunJR(id === 'guide-ticket' && jr && jr['tickets'] && jr['tickets'].state !== 'stopped');
    }, [jr, id]);

    /** Local functions */
    const loadTicket = useCallback(async () => {
        const ticketInStore = tickets.find((t) => String(t.id) === id);

        if (!ticketInStore) {
            try {
                dispatch(
                    setTicketList([
                        await Axios.get(`/entity/Ticket/${id}`, {
                            params: { scope: 'withMessagesList' },
                        }).then((res) => res.data),
                    ])
                );
            } catch (error) {
                Sentry.captureException(error);
            }
        } else {
            setSelectedTicket(ticketInStore);
        }
    }, [id, tickets, dispatch]);

    useEffect(() => {
        if (id === 'guide-ticket') {
            setSelectedTicket(dummyTicket);
        } else {
            loadTicket();
        }
    }, [id, loadTicket, tickets, dummyTicket]);

    const handleJREvent = (data) => {
        const { action, index, status, type } = data;
        if (action === ACTIONS.CLOSE || action === ACTIONS.SKIP || 
            (action === ACTIONS.NEXT && status === STATUS.FINISHED)
        ) {
            setRunJR(false);
            dispatch(stopJR('tickets'));
            history.replace(`/tickets`);
        }
        if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 1) {
            setRunJR(false);
            history.replace(`/tickets`);
        }
    }

    if (!selectedTicket) return <Loader />;

    return (
        <div className={styles.root}>
            <Joyride 
                steps={jr && jr['tickets'] ? jr['tickets'].steps[i18n.language ? i18n.language : 'uk'] : []} 
                run={runJR} 
                callback={handleJREvent}
                showSkipButton={true}
                continuous={true}
                locale={{ back: t('Назад'), close: t('Закрити'), last: t('Завершити'), next: t('Далі'), open: t('Відкрити'), skip: t('Пропустити') }}
                styles={{ options: { primaryColor: '#FD5F00', buttonNext: { outline: "none" } }}}
            />
            <div className={`ticketPage ${showSidebar ? 'withSidebar' : ''}`}>
                <div className={`ticketDetails ticket-section`}>
                    <div className={`ticket-section-inner`}>
                        <TicketDetails
                            isJR={id === 'guide-ticket'}
                            ticket={selectedTicket}
                            agents={agents}
                            ticketsPageDisapatch={ticketsPageDisapatch}
                        ></TicketDetails>
                    </div>
                </div>
                <div className={`ticketAside ticket-section`}>
                    <div className={`ticket-section-inner`}>
                        <TicketInfo
                            isJR={id === 'guide-ticket'}
                            ticket={selectedTicket}
                            agents={agents}
                            ticketsPageDisapatch={ticketsPageDisapatch}
                            user={user}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketViewPage;
